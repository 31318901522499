import React, {ReactElement, useEffect, useState} from 'react';
import {Loading, VirtualizedTable} from '../index';
import './Admin.scss';
import {Checkbox} from '@material-ui/core';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {Message, ReblOption, ReblOptions, SelectOption} from "../../types";
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {AlertMessage, Icon, ButtonGroup, Input, Select, Tooltip} from '@vacasa/react-components-lib';
import {useAddReblOptionMutation, useUpdateReblOptionMutation} from "../../store";
import {UiUtils} from "../../utils";


interface ReblOptionsTableProps {
    selectedOptionTable: string;
    setSelectedOptionTable: (o: string) => void;
    searchValue: string;
    setSearchValue: (o: string) => void;
    reblOptionsData: ReblOptions;
    refetchReblOptions: () => void;
    isFetchingReblOptionsData: boolean;
}

type ReblOptionsTableComponent = (props: ReblOptionsTableProps) => ReactElement<any, any> | null;


export const ReblOptionsTable:  ReblOptionsTableComponent = (props) => {
    const {
        selectedOptionTable,
        setSelectedOptionTable,
        searchValue,
        setSearchValue,
        reblOptionsData,
        refetchReblOptions,
        isFetchingReblOptionsData
    } = props;
    const [uiAlert, setUiAlert] = useState<Message | null>({type: "success", content: "Table is in-sync"});
    const [filteredData, setFilteredData] = useState<ReblOption[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [showCompoundable, setShowCompoundable] = useState<boolean>(false);
    const [disableAdd, setDisableAdd] = useState<boolean>(false);
    const reblOptions: SelectOption[] = Object.keys(UiUtils.reblOptionsMap).map(k => UiUtils.getSelectOption(k));

    const [newOption, setNewOption] = useState<string>("");
    const [isCompoundable, setIsCompoundable] = useState<boolean>(false);

    const [addOption] = useAddReblOptionMutation();
    const [updateOption] = useUpdateReblOptionMutation();

    useEffect(() => {
        if (!isFetchingReblOptionsData) {
            let filteredOptions = [...reblOptionsData[UiUtils.reblOptionsMap[selectedOptionTable].plural]];
            if (showActive) filteredOptions = filteredOptions.filter((o) => o.active);
            if (selectedOptionTable === "Action" && showCompoundable) filteredOptions = filteredOptions.filter((o) => o.compoundable);

            searchValue.toLowerCase().split(" ").forEach(sv => {
                filteredOptions = filteredOptions.filter((o: ReblOption) => {
                    return (
                        o.id.toString().includes(sv) ||
                        o[UiUtils.reblOptionsMap[selectedOptionTable].key].toLowerCase().includes(sv)
                    );
                })
            });

            setFilteredData(filteredOptions)
        }
    }, [showActive, showCompoundable, selectedOptionTable, reblOptionsData, searchValue, isFetchingReblOptionsData]);

    const addOptionToTable = async () => {
        setDisableAdd(true);
        await addOption({option_type: selectedOptionTable, value: newOption, compoundable: isCompoundable}).then(() => {
            refetchReblOptions()
            setDisableAdd(false);
        })
    }

    const updateOptionInTable = async (option: ReblOption, column: string) => {
        if (!!option.loading) return;

        let updatedOptionData = [...filteredData];
        const idx = updatedOptionData.findIndex((a => a.id === option.id))
        let updatedAdmin = {...updatedOptionData[idx]}
        updatedAdmin.loading = true;
        updatedOptionData[idx] = updatedAdmin
        setFilteredData(updatedOptionData);

        setDisableAdd(true);

        let active = option.active;
        let compoundable = option.compoundable === undefined ? false : option.compoundable;
        console.log("compoundable", compoundable);

        if (column === "active") active = !option.active;
        else if (column === "compoundable") compoundable = !option.compoundable;

        await updateOption({
            option_type: selectedOptionTable,
            id: option.id,
            active: active,
            compoundable: compoundable}).then(() => {
            refetchReblOptions()
            setDisableAdd(false);
        })
    }


    const saveButton: ButtonProps = {
        onClick: addOptionToTable,
        children: "Add Option",
        variant: "secondary",
        disabled: disableAdd,
        customClass: "button-group"
    }

    const getClassName = (row: ReblOption) => {
        if (row.active) return "action-button action-button-icon success"
        return "action-button action-button-icon warning"
    }

    const resetSearch = (newSearchValue: string) => {
        setShowActive(false);
        setShowCompoundable(false);
        setSearchValue(newSearchValue);
    }

    const builder = new DataSourceBuilder<ReblOption>();

    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1
        },
    });
    builder.addColumn({
        label: 'Active',
        field: 'active',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.5
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.active) return <Icon.MinusCircle className="icon-danger" height={24} width={24}/>;
                return <Icon.CheckCircle className="icon-success" height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: selectedOptionTable,
        field: UiUtils.reblOptionsMap[selectedOptionTable].key,
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 3
        },
    });
    if (selectedOptionTable === "Action") {
        builder.addColumn({
            label: 'Compoundable',
            field: 'compoundable',
            fieldConfiguration: {
                customLeftComponent: (row) => {
                    if (!row.compoundable) return <Icon.MinusCircle height={24} width={24}/>;
                    return <Icon.BarChart height={24} width={24}/>;
                }
            }

        })
    }
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 1
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (
                    <div className={"action-button-list"}>
                        {selectedOptionTable === "Action" &&
                            <Tooltip message={"Toggle 'compoundable'"}>
                                <div className="action-button action-button-icon" onClick={() => {updateOptionInTable(row, "compoundable")}}>
                                    {row.loading
                                        ?
                                        <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                        :
                                        row.compoundable
                                            ?
                                            <Icon.BarChart height={24} width={24}/>
                                            // <Icon.TrendingUp height={24} width={24}/>
                                            :
                                            <Icon.MinusCircle height={24} width={24}/>

                                    }
                                </div>
                            </Tooltip>
                        }
                        <Tooltip message={"Set option to active or inactive"}>
                            <div className={getClassName(row)} onClick={() => {updateOptionInTable(row, "active")}}>
                                {row.loading
                                    ?
                                    <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                    :
                                    row.active
                                        ?
                                        <Icon.CheckCircle height={24} width={24}/>
                                        :
                                        <Icon.MinusCircle height={24} width={24}/>

                                }
                            </div>
                        </Tooltip>
                    </div>
                )
            },
            format: () => <></>
        }
    });

    builder.addPagination({remote: false});

    return (
        <div>
            <div className="admin-utility-row">
                <div className="admin-input-group" style={{width: "320px"}}>
                    <label style={{width: "50px"}}>Table:</label>
                    <Select
                        customClass={"admin-select"}
                        value={selectedOptionTable}
                        options={reblOptions}
                        onChange={(e) => setSelectedOptionTable(e.target.value)}
                    />
                </div>
                <div className="admin-input-group" style={{width: "500px"}}>
                    <label style={{width: "100px"}}>New Option:</label>
                    <Input
                        customClass={selectedOptionTable === "Action" ? "admin-action-input" : "admin-input"}
                        type="text"
                        value={newOption}
                        placeholder={`New ${selectedOptionTable} option...`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewOption(e.target.value)}
                    />
                </div>
                {selectedOptionTable === "Action" &&
                    <div className="admin-input-group" style={{width: "220px"}}>
                        <label  style={{width: "120px"}} htmlFor={"is-compoundable"}>Compoundable:</label>
                        <Checkbox
                            checked={isCompoundable}
                            onChange={() => setIsCompoundable(!isCompoundable)}
                            className="filter-cb"
                            id="is-compoundable"
                        />
                    </div>
                }
                <div className="admin-input-group" style={{width: "160px"}}>
                    <ButtonGroup left={saveButton} right={<></>}/>
                </div>
            </div>
            <hr/>
            <div className="admin-utility-row">
                <div className="admin-alert-container">
                    {uiAlert && (
                        <AlertMessage
                            customClass="alert-message"
                            text={uiAlert?.content}
                            type={uiAlert?.type}
                            height="small"
                        />
                    )}
                </div>
                <div className="admin-search-input">
                    <label htmlFor="admin-search">Search:</label>
                    <Input
                        customClass="admin-search"
                        type="text"
                        value={searchValue}
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                </div>
                <Tooltip message="Inactive options are hidden by default">
                    <div className="admin-icons">
                        <Checkbox
                            checked={showActive}
                            onChange={() => setShowActive(!showActive)}
                            className="filter-cb"
                            id="inactive-cb"
                        />
                        <label htmlFor="inactive-cb">Active</label>
                    </div>
                </Tooltip>
                {selectedOptionTable === "Action" &&
                <Tooltip message="Show only compounding options">
                    <div className="admin-icons">
                        <Checkbox
                            checked={showCompoundable}
                            onChange={() => setShowCompoundable(!showCompoundable)}
                            className="filter-cb"
                            id="show-compoundable-cb"
                        />
                        <label htmlFor="show-compoundable-cb">Compoundable</label>
                    </div>
                </Tooltip>
                }
                <div className="refetch-icon">
                    <Tooltip message="Reset Filters">
                        <Icon.RotateCCW className="pointer" height={24} width={24} onClick={() => {
                            resetSearch("");
                        }}/>
                    </Tooltip>
                    <Tooltip message="Refresh Options">
                        <Icon.RefreshCCW className="pointer" height={24} width={24} onClick={() => {
                            refetchReblOptions();
                            setUiAlert({type: "success", content: "Table is in-sync"});
                        }}/>
                    </Tooltip>
                </div>
            </div>
            <div className="admin-list">
                <div>
                    {isFetchingReblOptionsData
                        ? <Loading className="admin-list-loading"/>
                        : <VirtualizedTable
                            className="admin-option-table"
                            dataSource={builder.build(filteredData)}
                            onRowChange={() => null}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
