import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './UnitAccordion.scss';
import {TableRowProps} from "react-virtualized";
import {UnitExtraData} from "../../types";


interface UnitAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}


export const UnitAccordion: React.FC<UnitAccordionProps> = (props) => {

    const {tableProps, expanded, handleChange} = props;
    const [extraData, setExtraData] = useState<UnitExtraData>();

    const styles = {
        ...tableProps.style
    };

    useEffect(() => {
        const extraDetailsFromData: UnitExtraData = tableProps.rowData.extra_data;
        setExtraData(extraDetailsFromData);
    }, [tableProps.rowData.extra_data]);

    return (
        <div key={tableProps.rowData.id} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.id} expanded={expanded === tableProps.rowData.id}
                          onChange={handleChange(tableProps.rowData.id, tableProps.index)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                    {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th className={"date-col"}>Date Created</th>
                            <th className={"state-col"}>State</th>
                            <th className={"region-col"}>Region</th>
                            <th className={"city-col"}>City</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!extraData ?
                            <tr className={"table-body"}>
                                <td>{extraData.created_at}</td>
                                <td>{extraData.state}</td>
                                <td>{extraData.region}</td>
                                <td>{extraData.city}</td>
                            </tr>

                            :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
