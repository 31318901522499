import {apiRebl} from "./api";
import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "./store";
import {BulkLoadDTO, LocationOptions, ReblOptions, ReblRuleset} from "../types";


const reblAdapter = createEntityAdapter<ReblRuleset>({
    selectId: (rule) => rule.id,
    sortComparer: (a, b) => a.id - b.id
});

const initialState: EntityState<ReblRuleset> = reblAdapter.getInitialState();

export const reblApi = apiRebl.injectEndpoints({
    endpoints: (builder) => ({
        getAllReblRules: builder.query<ReblRuleset[], void>({
            query: () => "rebl_rulesets",
            providesTags: [{type: "ReblRulesets", id: "ReblRulesetList"}]
        }),
        getUnitLocationOptions: builder.query<LocationOptions, void>({
            query: () => "rebl_unit_location_options"
        }),
        getReblOptions: builder.query<ReblOptions, void>({
            query: () => "rebl_options"
        }),
        addReblRule: builder.mutation<void, ReblRuleset>({
            query: (body) => ({
                url: "rebl_rulesets",
                method: "POST",
                body: JSON.stringify(body)
            }),
        }),
        addReblOption: builder.mutation<void, {option_type: string, value: string, compoundable: boolean}>({
           query: (body) => ({
               url: "rebl_option",
               method: "POST",
               body: JSON.stringify(body)
           })
        }),
        updateReblOption: builder.mutation<void, {option_type: string, id: number, active: boolean, compoundable: boolean}>({
            query: (body) => ({
                url: "rebl_option",
                method: "PUT",
                body: JSON.stringify(body)
            })
        }),
        updateReblRule: builder.mutation<ReblRuleset, { id: number; data: Partial<ReblRuleset> }>({
            query: ({id, data}) => ({
                url: `rebl_rulesets/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            }),
            invalidatesTags: []
        }),
        bulkCSVUpload: builder.mutation<void, {data: BulkLoadDTO}>({
            query: ({data}) => ({
                url: "bulk_uploader",
                method: "POST",
                body: JSON.stringify(data)
            })
        }),
        getDagStatus: builder.query({
            query: (dagName: string) => {
                return `rebl_dag_status/${dagName}`;
            }
        }),
        triggerDag: builder.mutation<ReblRuleset, string>({
            query: (dagName: string) => ({
                url: `trigger_dag/${dagName}`,
                method: "POST"
            }),
            invalidatesTags: []
        }),
    }),
    overrideExisting: false,
});

export const reblSlice = createSlice({
    name: "reblRulesets",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<ReblRuleset[]>) => {
            reblAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            reblApi.endpoints.getAllReblRules.matchFulfilled, (state, {payload}) => {
                reblAdapter.upsertMany(state, payload);
            })
    }
});

export const {
    useGetAllReblRulesQuery,
    useGetReblOptionsQuery,
    useAddReblOptionMutation,
    useUpdateReblOptionMutation,
    useGetUnitLocationOptionsQuery,
    useAddReblRuleMutation,
    useUpdateReblRuleMutation,
    useBulkCSVUploadMutation,
    useGetDagStatusQuery,
    useTriggerDagMutation,
} = reblApi;
export const reblSelector = (state: AppState) => state.reblRulesets;

export default reblSlice.reducer;