import React, {ReactElement, useEffect, useState} from 'react';
import {Loading, UnitNavigation, VirtualizedTable} from '../index';
import './UnitList.scss';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {Cohort, CurrentUser, Message, Unit} from "../../types";
import {AlertMessage, Icon, Input, Modal, Tooltip, withTooltip} from '@vacasa/react-components-lib';
import {isEmpty, orderBy} from "lodash";
import {useExcludeUnitMutation, useGetAllUnitsQuery} from "../../store";
import {UnitAssignmentModal} from "./UnitAssignmentModal";
import {Checkbox} from '@material-ui/core';
import {UiUtils} from "../../utils";


interface UnitListProps {
    isFetchingCohorts: boolean;
    cohortData: Cohort[];
    currentUser: CurrentUser;
}

type UnitListComponent = (props: UnitListProps) => ReactElement<any, any> | null;

export const UnitList: UnitListComponent = (props) => {
    const {isFetchingCohorts, cohortData, currentUser} = props;
    const {refetch, data: unmappedUnits, isFetching: isFetchingUnits} = useGetAllUnitsQuery();
    const [units, setUnits] = useState<Unit[]>();
    const [postExcludeUnit] = useExcludeUnitMutation();
    const [clickedPosition, setClickedPosition] = useState({ x: 0, y: 0 });
    const [unitData, setUnitData] = useState<Unit[]>([]);
    const [filteredUnits, setFilteredUnits] = useState<Unit[]>([]);

    const [searchValue, setSearchValue] = useState<string>("");
    const [openAssignmentModal, setOpenAssignmentModal] = useState<boolean>(false);
    const [openNavigationModal, setOpenNavigationModal] = useState<boolean>(false);
    const [selectedUnit, setSelectedUnit] = useState<Unit>();
    const [recentUnits, setRecentUnits] = useState<boolean>(false);
    const [hasSuggestion, setHasSuggestion] = useState<boolean>(false);
    const [uiAlert, setUiAlert] = useState<Message | null>({type: "success", content: "Table is in-sync"});
    const [updatingTable, setUpdatingTable] = useState<boolean>(false);

    const excludeUnit = async (unit_id: number) => {
        if (updatingTable)  return;
        setUpdatingTable(true);

        // Updating read-only data is a chore...
        // 1. Get idx of row you're updating
        // 2. Copy RO data into new array
        // 3. Copy row into a new object by idx
        // 4. Update new object properties
        // 5. Replace idx of copied array with copied/updated object
        // 6. Set RO data to copied/updated array

        // Do that to set "excluded" status
        const idx = filteredUnits.findIndex((u => u.id === unit_id))

        let updatedData = [...filteredUnits];
        let updatedUnit = {...updatedData[idx]}
        updatedUnit.assigned = "excluded";
        updatedData[idx] = updatedUnit
        setFilteredUnits(updatedData);
        setUiAlert({type: "warning", content: "Table is out-of-sync"})

        await postExcludeUnit(unit_id).then(() => {
            setUpdatingTable(false)
            setUiAlert({type: "success", content: "Table is in-sync"})
        })
    }

    const selectUnit = (unit: Unit) => {
        if (unit.super_unit) {
            let subUnits = unit.sub_unit_ids.map(u => {
                return {
                    id: u,
                    strategic_cohort: null,
                    strategic_cohort_id: null,
                    active: false,
                    display: false,
                }
            });
            subUnits.forEach(subUnit => {
                units.forEach(u => {
                    if (u.id === subUnit.id) {
                        subUnit.strategic_cohort = u.strategic_cohort;
                        subUnit.strategic_cohort_id = u.strategic_cohort_id;
                        subUnit.active = true; // We only collect active units
                        subUnit.display = u.display;
                        return;
                    }
                })
            });
            unit.sub_unit_data = subUnits;
        }
        setSelectedUnit(unit);
        setOpenAssignmentModal(true);
        setOpenNavigationModal(false);
    }

    const onUpdateUnit = (updatedUnit: Unit) => {
        const updatedUnits = filteredUnits.map(
            unit => unit.id === updatedUnit.id ? updatedUnit : unit
        )
        setFilteredUnits(updatedUnits)
    }

    useEffect(() => {
        if (!isFetchingUnits && unmappedUnits?.values.length > 0) {
            let mappedUnits = [];

            unmappedUnits.values.forEach(v => {
                mappedUnits.push(
                    Object.fromEntries(unmappedUnits.columns.map((k, i) => [k, v[i]]))
                );
            });

            setUnits(mappedUnits);
        }
    }, [isFetchingUnits, unmappedUnits]);


    useEffect(() => {
        if (!isEmpty(units)) setUnitData(() => {
            let copy: Unit[] = []
            units.forEach((u: Unit) => {
                let rowCopy = {...u};
                if (rowCopy.unit_quality_override) {
                    rowCopy.unit_quality_override = parseFloat(rowCopy.unit_quality_override.toFixed(3));
                }
                rowCopy.extra_data = {
                    created_at: u.created_at,
                    state: u.state,
                    region: u.region,
                    city: u.city_name
                }
                copy.push(rowCopy);
            })
            return copy;
        });
    }, [units])

    useEffect(() => {
        if (!isFetchingUnits) {
            let filteredData = orderBy(unitData, "id");
            if (searchValue !== "") {
                const searchValueLower = searchValue.toLowerCase();
                filteredData = filteredData.filter((unit) => {
                    return (
                        unit.id.toString().indexOf(searchValueLower) >= 0 ||
                        unit.unit_code.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.region.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.city_name.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.assigner.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.state.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.strategic_cohort.toLowerCase().indexOf(searchValueLower) >= 0 ||
                        unit.strategic_cohort_id.toString().indexOf(searchValueLower) >= 0
                    )
                })
            }

            filteredData = !hasSuggestion ? filteredData : filteredData.filter(
                u => u.suggested_strategic_cohort_ids?.length > 0
            )

            filteredData = !recentUnits ? filteredData : filteredData.filter(
                (u) => {
                    if (!u.created_at) return false;
                    let dateDiff = UiUtils.dateDiffDays("", u.created_at)
                    return dateDiff < 60;
                }
            );

            // sort
            filteredData = filteredData.sort((a, b) => {
                if (a.display === b.display)
                    return b.id - a.id
                return Number(b.display) - Number(a.display)
            });

            setFilteredUnits(filteredData)
        }
    }, [unitData, searchValue, hasSuggestion, recentUnits, isFetchingUnits])

    const resetSearch = (newSearchValue: string) => {
        setRecentUnits(false);
        setHasSuggestion(false);
        setSearchValue(newSearchValue);
    }

    const showNavigationModal = (unit: Unit, event: React.MouseEvent<HTMLDivElement>) => {
        setSelectedUnit(unit);
        setClickedPosition({x: event.clientX, y: event.clientY})
        setOpenAssignmentModal(false);
        setOpenNavigationModal(true);

    }

    const changeSelectedUnit = (newId: number) => {
        resetSearch(newId.toString());

        let newUnit: Unit = null;
        units.forEach(u => {
            if (u.id === newId) newUnit = u
        });

        if (!!newUnit) selectUnit(newUnit);
        else alert(`Could not find active unit ${newId}`)
    }

    const builder = new DataSourceBuilder<Unit>();

    builder.addColumn({
        label: 'Unit ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Unit Code',
        field: 'unit_code',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
    });
    builder.addColumn({
        label: 'Strategic Cohort',
        field: 'strategic_cohort',
        displayConfiguration: {
            flexGrow: 1.6
        },
        fieldConfiguration: {
            customLeftComponent: (row: Unit) => {
                if (!!row.strategic_cohort || row.pending_strategic_cohort) {
                    return (
                        <>
                            {!!row.pending_strategic_cohort ?
                                <Tooltip message={"click to search for units in this cohort"}>
                                    <div className="pointer"
                                         onClick={() => setSearchValue(row.pending_strategic_cohort)}>
                                        <Icon.Search height={24} width={24}/>
                                        {row.pending_strategic_cohort ? `(pending) ${row.pending_strategic_cohort}` : ""}
                                    </div>
                                </Tooltip>
                                : !!row.strategic_cohort_id ?
                                <Tooltip message={"click to search for units in this cohort"}>
                                    <div className="pointer" onClick={() => setSearchValue(row.strategic_cohort)}>
                                        <Icon.Search height={24} width={24}/>
                                        {row.strategic_cohort ?? ""}
                                    </div>
                                </Tooltip>
                                : <></>
                            }
                        </>
                    )
                }
                return null;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Live',
        field: 'display',
        displayConfiguration: {
            flexGrow: 0.4,
            justifyContent: 'center',
        },
        fieldConfiguration: {
            customLeftComponent: (row: Unit) => {
                if (!row.display) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Status',
        field: 'assigned',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.5
        },
    });
    builder.addColumn({
        label: 'Quality',
        field: 'unit_quality',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
    });
    builder.addColumn({
        label: 'Override',
        field: 'unit_quality_override',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (
                    row.unit_quality_override ?
                        <Tooltip
                            message={`Unit quality override assigned by ${row.unit_quality_override_assigner}`}>
                            <div>
                                <Icon.AlertCircle height={18} width={18}/>
                            </div>
                        </Tooltip>
                        :
                        <></>
                )
            }
        }
    });
    builder.addColumn({
        label: 'Beds',
        field: 'bedrooms',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        },
    });
    builder.addColumn({
        label: 'Assigner',
        field: 'assigner',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.7
        },
    });
    builder.addColumn({
        label: 'Super',
        field: 'super_unit',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row: Unit) => {
                if (!row.super_unit) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Ski',
        field: 'ski',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row: Unit) => {
                if (!row.ski) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Water',
        field: 'water',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.water) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'ESP',
        field: 'esp',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.p) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        func: (row) => (
            <React.Fragment>
                <div className="action-button-list">
                    {row.assigned === "unassigned" && (
                        <div className="action-button-icon" onClick={() => excludeUnit(row.id)}>
                            <Icon.EyeOff height={20} width={20}/>
                        </div>
                    )}
                    <div className="action-button-icon" onClick={() => selectUnit(row)}>
                        <Icon.Edit3 height={20} width={20}/>
                    </div>
                    <div className="action-button-icon" onClick={((event) => showNavigationModal(row, event))}>
                        <Icon.Navigation height={20} width={20}/>
                    </div>
                </div>
            </React.Fragment>
        )
    });

    builder.setSortable({field: 'id', order: 'desc'});
    builder.setFilterHeader({
        options: [
            {field: "display", values: ['ALL', true, false], type: "select"},
            {field: "assigned", values: ['ALL', 'assigned', 'unassigned', 'pending', 'excluded'], type: "select"},
            {field: "unit_quality", type: "range"},
            {field: "unit_quality_override", type: "range"},
            {field: "bedrooms", type: "range", options: "int"},
            {field: "super_unit", values: ['ALL', 1, 0], type: "select"},
            { field: "ski", values: ['ALL', 1, 0], type: "select"},
            { field: "water", values: ['ALL', 1, 0], type: "select"},
            { field: "esp", values: ['ALL', 1, 0], type: "select"},
        ],
        initialFilters: [
            // { field: "assigned", value: "unassigned", type: "select"},
        ]
    });

    builder.addPagination({remote: false});

    return (
        <div>
            <div className="unit-utility-row">
                <div className="unit-alert-container">
                    {uiAlert && (
                        <AlertMessage
                            customClass="alert-message"
                            text={uiAlert?.content}
                            type={uiAlert?.type}
                            height="small"
                        />
                    )}
                </div>
                <div className="unit-search-input">
                    <label htmlFor="unit-search">Search:</label>
                    <Input
                        customClass="unit-search"
                        type="text"
                        value={searchValue}
                        placeholder="Search Units..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                </div>
                <Tooltip message="Show units that have a suggestion">
                    <div className="unit-input-group">
                        <Checkbox
                            checked={hasSuggestion}
                            onChange={() => setHasSuggestion(!hasSuggestion)}
                            className="filter-cb"
                            id="has-sug-cb"
                        />
                        <label htmlFor="has-sug-cb">Has Suggestion</label>
                    </div>
                </Tooltip>
                <Tooltip message="Units created less than 60 days ago">
                    <div className="unit-input-group">
                        <Checkbox
                            checked={recentUnits}
                            onChange={() => setRecentUnits(!recentUnits)}
                            className="filter-cb"
                            id="show-live-cb"
                        />
                        <label htmlFor="show-live-cb">Recent</label>
                    </div>
                </Tooltip>
                <div className="refetch-icon">
                    {/*<Tooltip message="Reset Filters">*/}
                    {/*    <Icon.RotateCCW className="pointer" height={24} width={24} onClick={() => {*/}
                    {/*        resetSearch("");*/}
                    {/*    }}/>*/}
                    {/*</Tooltip>*/}
                </div>
                <div className="refetch-icon">
                    <Tooltip message="Refresh Units">
                        <Icon.RefreshCCW className="pointer" height={24} width={24} onClick={() => {
                            refetch();
                            setUiAlert({type: "success", content: "Table is in-sync"});
                        }}/>
                    </Tooltip>
                </div>
            </div>
            <div>
                {isFetchingCohorts || isFetchingUnits
                    ? <Loading className="unit-list-loading"/>
                    : <VirtualizedTable
                        className="unit-list-table"
                        dataSource={builder.build(filteredUnits)}
                        onRowChange={() => null}
                        isUnitAccordion={{
                            handleUiAlert: (message: Message) => setUiAlert(message)
                        }}
                        headerOptions={{height: 60}}
                    />
                }
            </div>
            <div className="modal">
                <Modal
                    canExit={true}
                    showModal={openAssignmentModal}
                    setShowModal={setOpenAssignmentModal}
                    size='small'
                >
                    <UnitAssignmentModal
                        closeModal={() => setOpenAssignmentModal(false)}
                        cohortOptionsSameState={UiUtils.getCohortByState(selectedUnit, cohortData)}
                        selectedUnit={selectedUnit}
                        changeSelectedUnit={changeSelectedUnit}
                        onUpdateUnit={onUpdateUnit}
                        currentUser={currentUser}
                    />
                </Modal>
            </div>
            {openNavigationModal &&
                <div className={"nav-panel"} style={{
                    top: clickedPosition.y,
                    left: clickedPosition.x - 150
                }}>
                    <UnitNavigation unitID={selectedUnit?.id} onClose={() => setOpenNavigationModal(false)}/>
                </div>
            }
        </div>
    );
};
