import React, {useEffect, useState} from 'react';
import {Loading} from '../index';
import './UnitAssignmentModal.scss';
import {CurrentUser, SelectOption, StrategicCohort, Unit} from "../../types";
import {useAddCohortAssignmentMutation, usePostUnitQualityScoreMutation, useGetSuggestedCohortsQuery, useRemoveCohortAssignmentMutation, useRemoveQualityScoreMutation} from "../../store";
import {Button, Icon, Input, Select, Tooltip} from '@vacasa/react-components-lib';


interface UnitAssignmentModalProps {
    selectedUnit: Unit;
    cohortOptionsSameState: SelectOption[];
    closeModal: () => void;
    changeSelectedUnit: (id: number) => void;
    onUpdateUnit: (unit: Unit) => void;
    currentUser: CurrentUser;
}

export const UnitAssignmentModal: React.FC<UnitAssignmentModalProps> = (props) => {
    const {selectedUnit, cohortOptionsSameState, closeModal, changeSelectedUnit, onUpdateUnit, currentUser} = props
    const {data: suggestedCohorts, isFetching} = useGetSuggestedCohortsQuery({id: selectedUnit.id, super_unit: selectedUnit.super_unit});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableAssignCohort, setDisableAssignCohort] = useState<boolean>(true);
    const [disableUpdateScore, setDisableUpdateScore] = useState<boolean>(true);
    const [assignUnitToStrategicCohort] = useAddCohortAssignmentMutation();
    const [setUnitQualityScore] = usePostUnitQualityScoreMutation();
    const [unassignUnitFromStrategicCohort] = useRemoveCohortAssignmentMutation();
    const [removeQualityScore] = useRemoveQualityScoreMutation()
    const [selectedCohortID, setSelectedCohortID] = useState<number>(null);
    const [qualityScore, setQualityScore] = useState<string>(
        (selectedUnit.unit_quality_override ? selectedUnit.unit_quality_override : selectedUnit.unit_quality).toString()
    );
    const [cohortOptions, setCohortOptions] = useState<SelectOption[]>();
    const [errorMsg, setErrorMsg] = useState<string>("No cohorts suggested");

    const assignUnit = async () => {
        setIsLoading(true);
        await assignUnitToStrategicCohort({
            unit_id: selectedUnit.id,
            strategic_cohort_id: selectedCohortID,
            is_super_unit: selectedUnit.super_unit
        }).then(() => {
            const idx = cohortOptions.findIndex(o => o.value === selectedCohortID)
            selectedUnit.strategic_cohort_id = selectedCohortID;
            selectedUnit.strategic_cohort = cohortOptions[idx].display;
            selectedUnit.assigner = currentUser.email;
            selectedUnit.assigned = 'pending';
            setIsLoading(false);
            onUpdateUnit(selectedUnit);
        })
    }

    const unassignUnit = async () => {
        setIsLoading(true);
        await unassignUnitFromStrategicCohort({id: selectedUnit.id, super_unit: selectedUnit.super_unit}).then(() => {
            selectedUnit.strategic_cohort_id = null;
            selectedUnit.strategic_cohort = null;
            selectedUnit.assigner = null;
            selectedUnit.assigned = 'unassigned';
            setSelectedCohortID(null);
            setIsLoading(false);
            onUpdateUnit(selectedUnit);
        })
    }

    const updateQualityScore = async () => {
        setIsLoading(true);
        const score = parseFloat(qualityScore)
        await setUnitQualityScore({
            unit_id: selectedUnit.id,
            unit_quality: score
        }).then(() => {
            selectedUnit.unit_quality_override = score
            selectedUnit.unit_quality_override_assigner = currentUser.email;
            setIsLoading(false);
            onUpdateUnit(selectedUnit);
        })
    }

    const unsetQualityScore = async () => {
        setIsLoading(true);
        await removeQualityScore(selectedUnit.id).then(() => {
            selectedUnit.unit_quality_override = null;
            selectedUnit.unit_quality_override_assigner = null;
            setUnitQualityScore(null);
            setIsLoading(false);
            onUpdateUnit(selectedUnit);
        })
    }

    useEffect(() => {
        const disable = (
            selectedUnit.lat === "" ||
            selectedUnit.lng === "" ||
            selectedUnit.lat === 0 ||
            selectedUnit.lng === 0 ||
            selectedCohortID === null ||
            (
                selectedUnit.pending_strategic_cohort_id ?
                    selectedUnit.pending_strategic_cohort_id === selectedCohortID
                    :
                    selectedUnit.strategic_cohort_id === selectedCohortID
            )
        )
        setDisableAssignCohort(disable)
    }, [selectedCohortID, selectedUnit.strategic_cohort_id])

    useEffect(() => {
        const score = parseFloat(qualityScore);
        const disable = (
            !qualityScore ||
            !score ||
            qualityScore !== score.toString() || // Vacasa react-components-lib Input does not handle floats
            (
                !!selectedUnit.unit_quality_override // requires a change
                ?
                selectedUnit.unit_quality_override === score
                :
                selectedUnit.unit_quality === score
            )
        );
        setDisableUpdateScore(disable)
    }, [qualityScore, selectedUnit.unit_quality_override])

    useEffect(() => {
        if (!isFetching && !!suggestedCohorts) {
            let options: SelectOption[] = []

            // Order matters -- Add pending assignment first since that indicates the most recent transaction
            if (selectedUnit.pending_strategic_cohort_id) {
                options.push({value: selectedUnit.pending_strategic_cohort_id, display: selectedUnit.pending_strategic_cohort})
            }
            // Next, add assigned cohort
            if (selectedUnit.strategic_cohort_id) {
                options.push({value: selectedUnit.strategic_cohort_id, display: selectedUnit.strategic_cohort})
            }
            // Next add suggested
            suggestedCohorts.forEach((sc: StrategicCohort) => {
                const idx = options.map(o => o.value).indexOf(sc.strategic_cohort_id)
                if (idx < 0) {
                    options.push({
                        value: sc.strategic_cohort_id,
                        display: sc.strategic_cohort
                    });
                }
            })
            // Only set option if it's suggested. Force user to select something else
            if (options.length > 0) {
                setSelectedCohortID(options[0].value as number);
            }
            // Finally, add all cohorts from the same state (TODO: Same region?)
            // Skip for super units -- should only consider cohorts with a sub unit
            if (!selectedUnit.super_unit) {
                options = options.concat(cohortOptionsSameState);
            }

            setCohortOptions(options);

            if (selectedUnit.super_unit) {
                if (selectedUnit.sub_unit_data.length === 0) {
                    setErrorMsg("This super unit does not have any sub-units")
                    setDisableAssignCohort(true);
                }
                else {
                    const allValid = selectedUnit.sub_unit_data.every(u => !!u.strategic_cohort_id === true)
                    if (!allValid) {
                        setErrorMsg("All sub units need to be assigned before assigning a super unit")
                        setDisableAssignCohort(true);
                    }
                }
            }

            setIsLoading(false);
        }
    }, [suggestedCohorts, isFetching])

    return (
        <div>
            <div className="modal-header">
                <h4>Unit Properties</h4>
            </div>
            <div className="modal-body ua-properties">
                {isLoading
                    ? <Loading className="admin-list-loading"/>
                    : <div>
                        <div className="ua-input-group">
                            <label htmlFor="suggested-sc">Selected Cohort:</label>
                            <Select
                                customClass="number-input"
                                value={selectedCohortID}
                                options={cohortOptions}
                                onChange={(e) => {
                                    setSelectedCohortID(parseInt(e.target.value))
                                }}
                            />
                            <Button
                                onClick={assignUnit}
                                variant="secondary"
                                disabled={isLoading || disableAssignCohort}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={unassignUnit}
                                variant="danger"
                                disabled={isLoading || !selectedUnit.strategic_cohort_id || true} // Disable until we know the right way to clear an assignment
                            >
                               Remove
                            </Button>
                        </div>

                        <div className="ua-input-group">
                            <label htmlFor="suggested-sc">Quality Score:</label>
                            <Input
                                customClass="number-input"
                                type="text"
                                value={qualityScore.toString()}
                                onChange={(e) => {
                                    setQualityScore(e.target.value)
                                }}
                            />
                            <Button
                                onClick={updateQualityScore}
                                variant="secondary"
                                disabled={isLoading || disableUpdateScore}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={unsetQualityScore}
                                variant="danger"
                                disabled={isLoading || !selectedUnit.unit_quality_override}
                            >
                                Remove
                            </Button>
                        </div>

                        <div>
                            {!isFetching && (
                                selectedUnit.lat === "" ||
                                selectedUnit.lng === "" ||
                                selectedUnit.lat === 0 ||
                                selectedUnit.lng === 0
                            ) ?
                                <div className="missing-data-warning">
                                    <Icon.AlertCircle height={24} width={24}/>
                                    <p>Unit does not have valid Lat/Lng and cannot be assigned</p>
                                </div>
                                : !isFetching && suggestedCohorts.length === 0
                                ?
                                <div className="missing-data-warning">
                                    <Icon.AlertCircle height={24} width={24}/>
                                    <p>{errorMsg}</p>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div className="unit-info">
                            <div className="row">
                                <tr>
                                    <td className="unit-key">ID:</td>
                                    <td className="unit-value">{selectedUnit.id}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Code:</td>
                                    <td className="unit-value">{selectedUnit.unit_code}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Unit Quality:</td>
                                    <td className="unit-value">{selectedUnit.unit_quality}</td>
                                </tr>
                                {!!selectedUnit.unit_quality_override && (
                                    <div>
                                        <tr>
                                            <td className="unit-key">Quality Override:</td>
                                            <td className="unit-value">{selectedUnit.unit_quality_override}</td>
                                        </tr>
                                        <tr>
                                            <td className="unit-key">Override Assigner:</td>
                                            <td className="unit-value">{selectedUnit.unit_quality_override_assigner}</td>
                                        </tr>
                                    </div>
                                )}
                                <tr>
                                    <td className="unit-key">State:</td>
                                    <td className="unit-value">{selectedUnit.state}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Region:</td>
                                    <td className="unit-value">{selectedUnit.region}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">City:</td>
                                    <td className="unit-value">{selectedUnit.city_name}</td>
                                </tr>
                                <div className={selectedUnit.matches_oz ? 'cohort-match' : 'cohort-mismatch'}>
                                    <tr>
                                        <td className="unit-key">Strategic Cohort:</td>
                                        <td className="unit-value">
                                            <b>{!!selectedUnit.strategic_cohort ? selectedUnit.strategic_cohort : "UNASSIGNED"}</b>
                                        </td>
                                    </tr>
                                    {!!selectedUnit.pending_strategic_cohort &&
                                        <tr>
                                            <td className="unit-key">Pending Cohort:</td>
                                            <td className="unit-value">
                                                <b>{selectedUnit.pending_strategic_cohort}</b></td>
                                        </tr>
                                    }
                                    {selectedUnit.super_unit ? (
                                        selectedUnit.sub_unit_data?.length > 0
                                        ?
                                        selectedUnit.sub_unit_data.map(sub => (
                                            <tr>
                                                <td className="unit-key">SubUnit ({sub.id}):</td>
                                                <td className="unit-value">{!!sub.strategic_cohort ? sub.strategic_cohort : "UNASSIGNED"}</td>
                                                <td className="select-unit-button">
                                                    {!!sub.strategic_cohort_id ?
                                                        <button
                                                            onClick={() => setSelectedCohortID(sub.strategic_cohort_id)}
                                                            disabled={selectedCohortID === sub.strategic_cohort_id}
                                                        >
                                                            Set Selected
                                                        </button>
                                                        :
                                                        sub.active ?
                                                        <Tooltip message="This sub-unit needs to be assigned before the super-unit can be assigned">
                                                            <div className="ua-input-group">
                                                                <Icon.AlertCircle height={24} width={24}/>
                                                                <button
                                                                    className={"as-link link-button"}
                                                                    onClick={() => {
                                                                        closeModal()
                                                                        changeSelectedUnit(sub.id)
                                                                    }}
                                                                >
                                                                    Jump to sub-unit assigner
                                                                </button>
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip message="This sub unit is not active and the super unit cannot be assigned">
                                                        <>
                                                        <Icon.XSquare height={24} width={24}/>
                                                            Inactive
                                                            </>
                                                        </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td className="unit-key">Sub Units</td>
                                            <td className="unit-value"><strong>NO SUB UNITS FOUND</strong></td>
                                            <td></td>
                                        </tr>
                                    ) :
                                        suggestedCohorts.map((suggested: StrategicCohort, i) => (
                                            <tr>
                                                <td className="unit-key">Suggested Cohort:</td>
                                            <td className="unit-value">{suggested.strategic_cohort}</td>
                                            <td className="select-unit-button">
                                                <button
                                                    onClick={() => setSelectedCohortID(suggested.strategic_cohort_id)}
                                                    disabled={selectedCohortID === suggested.strategic_cohort_id}
                                                >
                                                    Set Selected
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="unit-key">Oz Cohort:</td>
                                        <td className="unit-value">{selectedUnit.oz_cohort_name}</td>
                                    </tr>
                                </div>
                                <tr>
                                    <td className="unit-key">Beds:</td>
                                    <td className="unit-value">{selectedUnit.bedrooms}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Super Unit:</td>
                                    <td className="unit-value">{selectedUnit.super_unit}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">ESP:</td>
                                    <td className="unit-value">{selectedUnit.esp}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Ski:</td>
                                    <td className="unit-value">{selectedUnit.ski}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Water:</td>
                                    <td className="unit-value">{selectedUnit.water}</td>
                                </tr>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
