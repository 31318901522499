import {useGetUnitTetherTableQuery} from "../../store";
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {DemandInfluenceFormOptions, DemandInfluenceFormType, Message, UnitTetherAnchor} from "../../types";
import {Loading} from "../Common/Loading/Loading";
import {AlertMessage, Icon, Input, Modal, Tooltip} from "@vacasa/react-components-lib"
import {VirtualizedTable} from "../Common/VirtualizedTable/VirtualizedTable";
import React, {useEffect, useState} from "react";
import {UnitTetherModal} from "./UnitTetherModal";
import {CompressedCell} from "../Common/CompressedCell/CompressedCell";


export const UnitTetherTable = () => {
    const {refetch, data, isFetching} = useGetUnitTetherTableQuery();
    const [uiAlert, setUiAlert] = useState<Message | null>({type: "success", content: "Table is in-sync"});
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedUnitTether, setSelectedUnitTether] = useState<UnitTetherAnchor>();
    const [formType, setFormType] = useState<DemandInfluenceFormType>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [filteredUnitTethers, setFilteredUnitTethers] = useState<UnitTetherAnchor[]>([]);

    const newUnitTether = () => {
        setSelectedUnitTether(null);
        setFormType(DemandInfluenceFormOptions.ADD);
        setOpenModal(true);
    }

    // const onUpdateUnitTether = (updatedUnitTether: UnitTether) => {
    //     const updatedUnitTethers = filteredUnitTethers.map(
    //         unitTether => unitTether.id === updatedUnitTether.id ? updatedUnitTether : unitTether
    //     );
    //     setFilteredUnitTethers(updatedUnitTethers);
    // }

    useEffect(() => {
        if (!isFetching && data !== undefined) {
            let filteredCDs = [...data];

            searchValue.toLowerCase().split(" ").forEach(sv => {
                filteredCDs = filteredCDs.filter((ut: UnitTetherAnchor) => {
                    return (
                        ut.id.toString().includes(sv) ||
                        ut.unit_id.toString().includes(sv) ||
                        ut.creator_email.toLowerCase().includes(sv) ||
                        ut.tethers.filter(t =>
                            t.unit_id.toString().includes(sv) ||
                            t.note.toLowerCase().includes(sv)
                        ).length > 0
                    );
                })
            });

            setFilteredUnitTethers(filteredCDs);
        }
    }, [data, isFetching, searchValue]);

    const builder = new DataSourceBuilder<UnitTetherAnchor>();
    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.5
        },
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Unit ID',
        field: 'unit_id',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
    });
    builder.addColumn({
        label: 'Unit Code',
        field: 'unit_code',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.6
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return <CompressedCell valueString={row.unit_code} widthPct={8}/>
            }, format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Active',
        field: 'active',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.active) return <Icon.XCircle className="icon-danger" height={24} width={24}/>;
                return <Icon.CheckSquare className="icon-success" height={24} width={24}/>;
            }
    }});
    builder.addColumn({
        label: 'Unit Type',
        field: 'unit_type',
        displayConfiguration: {justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Title',
        field: 'title',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 1.5
        },
    });
    builder.addColumn({
        label: 'Units',
        field: 'unit_count',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.3
        },
    });
    builder.addColumn({
        label: 'Channel',
        field: 'channel',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
    });
    builder.addColumn({
        label: 'User',
        field: 'creator_email',
        displayConfiguration: {
            justifyContent: 'right',
        },
    });
    builder.addColumn({
        label: 'Actions',
        field: 'actions',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        func: (row) => (
            <div className="action-button-list">
                <Tooltip message="Edit dates">
                    <div className="action-button action-button-icon"
                         onClick={() => {
                             setFormType(DemandInfluenceFormOptions.EDIT);
                             setSelectedUnitTether(row);
                             setOpenModal(true);
                         }}>
                        <Icon.Edit3 height={24} width={24}/>
                    </div>
                </Tooltip>
            </div>
        )
    })

    builder.setSortable({field: 'id', order: 'asc'});
    builder.setFilterHeader({
        options: [
            {field: "active", values: ["ALL", true, false], type: "select"},
            {field: "unit_count", type: "range", options: "int"},
            {field: "channel", values: ["ALL", "Vacasa", "VRBO", "AirBNB"], type: "select"}
        ],
        initialFilters: [
            {field: "active", value: "true", type: "boolean"}
        ]
    });

    builder.addPagination({remote: false});

    return (
        <div>
            <div className="table-utility-row">
                <div className="unit-alert-container">
                {uiAlert && (
                        <AlertMessage
                            customClass="alert-message"
                            text={uiAlert?.content}
                            type={uiAlert?.type}
                            height="small"
                        />
                    )}
                </div>
                <div className="unit-search-input">
                    <label htmlFor="unit-search">Search:</label>
                    <Input
                        customClass="unit-search"
                        type="text"
                        value={searchValue}
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                </div>
                <div className="refetch-icon">
                    <Tooltip message="Refresh Unit Tethers">
                        <Icon.RefreshCCW className="pointer" height={24} width={24} onClick={() => {
                            refetch();
                            setUiAlert({type: "success", content: "Table is in-sync"});
                        }}/>
                    </Tooltip>
                    <Tooltip message="Add Unit Tether">
                         <span className="demand-influence-icon" onClick={newUnitTether}>
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className="table-list">
                <div>
                    {isFetching ?
                        <Loading className="table-list-loading"/>
                        :
                        <VirtualizedTable
                            className="generic-table"
                            dataSource={builder.build(filteredUnitTethers)}
                            onRowChange={() => null}
                            headerOptions={{height: 60}}
                            isUnitTetherAccordion={{
                                handleUiAlert: (message: Message) => setUiAlert(message)
                            }}
                        />
                    }
                </div>
            </div>
            <div className="modal">
                <Modal
                    canExit={true}
                    showModal={openModal}
                    setShowModal={setOpenModal}
                    size='small'
                >
                    <UnitTetherModal
                        closeModal={(refresh?: boolean) => {
                            setOpenModal(false);
                            if (refresh === true) refetch();
                        }}
                        formType={formType}
                        selectedUnitTether={selectedUnitTether}
                        allTethers={data}
                        isFetchingTethers={isFetching}
                    />
                </Modal>
            </div>
        </div>
    );
}