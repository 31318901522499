import React, {useEffect, useState} from 'react';
import {Icon, Tooltip} from '@vacasa/react-components-lib';
import './CompressedCell.scss'

interface CompressedCellProps {
    valueString: string;
    widthPct: number;
}
export const CompressedCell: React.FC<CompressedCellProps> = (props) => {
    const {valueString, widthPct} = props;
    const windowWidth = window.innerWidth;
    const [fontSize, setFontSize] = useState<number>(14);
    const [fontWidth, setFontWidth] = useState<number>();
    const [fontWidthPct, setFontWidthPct] = useState<number>();

    // Ugly array of character widths from this SO answer: https://stackoverflow.com/a/48172630/3769076
    const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
    // const widths = [];
    const avg = 0.527927

    const measureText = () => {
        return Array.from(valueString).reduce(
            (acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0
        ) * fontSize * 1.08 // seems like an 8% error in numbers above
    }

    useEffect(() => {
        if (!!valueString && fontSize !== 16 && fontSize > 10) {
            setFontWidth(measureText())
        }
    }, [fontSize]);

    useEffect(() => {
        if(!valueString || !fontWidth) return;
        let resultWidthPct = fontWidth * 100 / windowWidth;
        setFontWidthPct(resultWidthPct)
        if (resultWidthPct > widthPct && fontSize > 11) {
            setFontSize(fontSize - 1);
        }

    }, [fontWidth]);

    useEffect(() => {
        if (!!valueString) {
            setFontWidth(measureText());
        }
    }, [valueString]);


    const getSubstringLength = () => {
        const pctOver = fontWidthPct / widthPct;
        return Math.round(valueString.length / pctOver);
    }

    return (
        <div id="compressed-cell" className={"compressed-cell"} style={{fontSize: fontSize}}>
            {
                fontWidthPct && fontWidthPct > widthPct ?
                    <Tooltip message={valueString}>
                        <div>
                            <Icon.AlertCircle height={16} width={16}/>
                            {valueString.substring(0, getSubstringLength() - 8)}...
                        </div>
                    </Tooltip>
                    :
                    valueString
            }
        </div>
    );
};
