import React, {useEffect, useState} from 'react';
import {Loading, VirtualizedTable} from '../index';
import './Admin.scss';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {AdminUser} from "../../types";
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {Switch} from '@material-ui/core';
import {ButtonGroup, Icon, Input, Tooltip} from '@vacasa/react-components-lib';
import {useAddAdminMutation, useUpdateAdminMutation, useRemoveAdminMutation, useGetAdminsQuery} from "../../store";


export const AdminUsers:  React.FC = () => {
    const {refetch: refetchAdmins, data: adminData, isFetching: isFetchingAdmins} = useGetAdminsQuery();
    const [filteredAdminData, setFilteredAdminData] = useState<AdminUser[]>([]);

    const [disableAdd, setDisableAdd] = useState<boolean>(false)
    const [newAdminEmail, setNewAdminEmail] = useState<string>("");
    const [newAdminApprover, setNewAdminApprover] = useState<boolean>(false);

    const [addAdminUser] = useAddAdminMutation();
    const [updateAdminUser] = useUpdateAdminMutation();
    const [removeAdminUser] = useRemoveAdminMutation();

    const addAdmin = async () => {
        setDisableAdd(true);
        await addAdminUser({email: newAdminEmail, approver: newAdminApprover}).then(() => {
            refetchAdmins();
            setDisableAdd(false);
        })
    }

    const updateAdmin = async (row: AdminUser) => {
        if (!!row.loading) return;

        const idx = adminData.findIndex((a => a.id === row.id))

        let updatedAdminData = [...adminData];
        let updatedAdmin = {...updatedAdminData[idx]}
        updatedAdmin.loading = true;
        updatedAdminData[idx] = updatedAdmin
        setFilteredAdminData(updatedAdminData);

        await updateAdminUser({id: row.id, approver: !row.approver}).then(() => {
            refetchAdmins();
        })
    }

    const removeAdmin = async (admin_id: number) => {
        await removeAdminUser({id: admin_id}).then(() => {
            refetchAdmins()
        })
    }

    const saveButton: ButtonProps = {
        onClick: addAdmin,
        children: "Add Admin",
        variant: "secondary",
        disabled: disableAdd,
        customClass: 'button-group'
    }

    const getClassName = (row: AdminUser) => {
        if (row.approver) return "action-button action-button-icon success"
        return "action-button action-button-icon warning"
    }

    const builder = new DataSourceBuilder<AdminUser>();

    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'right',
        },
    });
    builder.addColumn({
        label: 'Email',
        field: 'email',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 2
        },

    });
    builder.addColumn({
        label: 'Approver',
        field: 'approver',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 2
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.approver) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 1
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (
                    <div className={"action-button-list"}>
                        <Tooltip message={"Set user's ability to approve DI"}>
                            <div className={getClassName(row)} onClick={() => {updateAdmin(row)}}>
                                {row.loading
                                    ?
                                    <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                    :
                                    row.approver
                                        ?
                                        <Icon.CheckCircle height={24} width={24}/>
                                        :
                                        <Icon.MinusCircle height={24} width={24}/>

                                }
                            </div>
                        </Tooltip>
                        <Tooltip message={"Remove user from Admin"}>
                            <div className="action-button action-button-icon danger" onClick={() => {removeAdmin(row.id)}}>
                                <Icon.Trash height={24} width={24}/>
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        }
    });
    
    builder.addPagination({remote: false});

    useEffect(() => {
        if (!isFetchingAdmins) {
            setFilteredAdminData(adminData);
        }
    }, [isFetchingAdmins])

    return (
        <div>
            <div className="admin-utility-row">
                <div className="admin-input-group">
                    <label>Email:</label>
                    <Input
                        customClass="admin-input"
                        type="text"
                        value={newAdminEmail}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAdminEmail(e.target.value)}
                    />
                </div>
                <div className="admin-input-group">
                    <label>Approver</label>
                    <Switch
                        key={'active-' + !!newAdminApprover ? 1 : 2}
                        checked={!!newAdminApprover}
                        onClick={() => setNewAdminApprover(!newAdminApprover)}
                    />
                </div>

                <div className="admin-input-group">
                    <ButtonGroup left={<></>} right={saveButton}/>
                </div>

            </div>
            <div className="admin-list">
                <div>
                    {isFetchingAdmins
                        ? <Loading className="admin-list-loading"/>
                        : <VirtualizedTable
                            className="admin-list-table"
                            dataSource={builder.build(filteredAdminData)}
                            onRowChange={() => null}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
