import { Expose } from 'class-transformer';
import { IsDefined, ValidateNested } from 'class-validator';
import { Type as ClassTransformerType } from 'class-transformer/decorators';
import { FileDTO } from './file.dto';

export class BulkLoadDTO {
    @IsDefined()
    @ValidateNested({ each: true })
    @ClassTransformerType(() => FileDTO)
    @Expose()
    file: FileDTO;
}
