import React, {ReactElement, useEffect, useState} from 'react';
import {Loading, VirtualizedTable} from '../../index';
import {AlertMessage, Icon, Input, Modal, Tooltip} from '@vacasa/react-components-lib';
import './DemandInfluenceList.scss';
import {DataSourceBuilder} from "../../Common/VirtualizedTable/DataSourceBuilder";
import {Cohort, CurrentUser, DemandInfluence, DemandInfluenceFormOptions, Message} from "../../../types";
import {useApproveDIMutation, useGetDemandInfluenceQuery} from "../../../store";
import {isEmpty, orderBy} from "lodash";
import {Checkbox} from '@material-ui/core';
import {DemandInfluenceApprovalForm} from "../DemandInfluenceApprovalForm/DemandInfluenceApprovalForm";
import {DemandInfluenceWizard} from "../DemandInfluenceWizard/DemandInfluenceWizard";


interface DemandInfluenceListProps {
    cohortData: Cohort[];
    isFetchingCohorts: boolean;
    selectedCohortID?: string;
    currentUser: CurrentUser;
}

type DemandInfluenceComponent = (props: DemandInfluenceListProps) => ReactElement<any, any> | null;

export const DemandInfluenceList: DemandInfluenceComponent = (props) => {
    const {
        cohortData,
        isFetchingCohorts,
        selectedCohortID,
        currentUser
    } = props;
    const [selectedCohort, setSelectedCohort] = useState<Cohort>(null);
    const isApprover = currentUser?.approver;

    const {refetch, data: demandInfluenceInfo, isFetching: isFetchingDI} = useGetDemandInfluenceQuery(selectedCohortID);

    const [demandInfluenceData, setDemandInfluenceData] = useState<DemandInfluence[]>([]);
    const [filteredDI, setFilteredDI] = useState<DemandInfluence[]>(null);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [hideInactive, setHideInactive] = useState<boolean>(true);
    const [showRequiresApproval, setShowRequiresApproval] = useState<boolean>(false);
    const [hideExpired, setHideExpired] = useState<boolean>(true);
    // control the modal for adding/editing/copying demand influences
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalDemandInfluence, setModalDemandInfluence] = useState<DemandInfluence>(null);
    const [modalType, setModalType] = useState<DemandInfluenceFormOptions>(null);
    const [searchValue, setSearchValue] = useState("");

    // control the modal for adding/editing/copying demand details
    const [, setOpenDetailModal] = useState<boolean>(false);

    // control the modal for approval
    const [openApprovalModal, setOpenApprovalModal] = useState<boolean>(false);

    const today = new Date(new Date().toISOString().split("T")[0])

    const [updateDIApproval] = useApproveDIMutation();

    const getIsApprover = (row: DemandInfluence) => {
        return isApprover;
        // FIXME: figure out approval workflows that cross regions/managers
        // if (isApprover) return true;
        //
        // // Could be brittle. User names are too free-form. FIXME: add manager email address to CohortData
        //
        // let isManager = false
        // // const strategicCohortIDs = row.strategic_cohorts.flatMap( cohort => cohort.strategic_cohort)
        // const currentCohorts = cohortData.filter(cohort => row.child_cohorts.includes(cohort.id))
        // // for multiple cohorts, require the current user to be a manager of at least one of them
        // isManager = currentCohorts.flatMap(cohort => cohort.manager.toLowerCase()).includes(currentUser?.name?.toLowerCase());
        //
        // // todo replace duplicate fragment
        // return isManager
    }

    const handleApprove = async (row: DemandInfluence, status: string, reason: string) => {
        if (!!row.loading)  return;

        // todo replace with passed closure
        // Updating read-only data is a chore...
        // 1. Get idx of row you're updating
        // 2. Copy RO data into new array
        // 3. Copy row into a new object by idx
        // 4. Update new object properties
        // 5. Replace idx of copied array with copied/updated object
        // 6. Set RO data to copied/updated array

        // Do that to set "approve" icon to loading,
        //   then again when approval is complete to update "approved" and turn off loading
        const idx = demandInfluenceData.findIndex((di => di.id === row.id))

        let updatedDIData = [...demandInfluenceData];
        let updatedDI = {...updatedDIData[idx]}
        updatedDI.loading = true;
        updatedDIData[idx] = updatedDI
        setDemandInfluenceData(updatedDIData);

        await updateDIApproval({id: row.id as number, approval_status: status, reason: reason}).then((response) => {
            let updatedDIData = [...demandInfluenceData];
            let updatedDI = {...updatedDIData[idx]}
            updatedDI.loading = true;
            updatedDIData[idx] = updatedDI

            if (response.hasOwnProperty("error")) {
                const msg = `Status Code ${response["error"]["status"].toString()}`
                alert(msg)
            } else {
                updatedDI.approval = status;

                switch (status) {
                    case 'approved':
                        updatedDI.approver = currentUser.email
                        updatedDI.approval_rejected_reason = ""
                        break
                    case 'rejected':
                        updatedDI.approver = currentUser.email
                        updatedDI.approval_rejected_reason = reason
                        break
                    default:
                        updatedDI.approver = "";
                }
            }

            updatedDI.loading = false;
            updatedDIData[idx] = updatedDI
            setDemandInfluenceData(updatedDIData);
        })
    }

    const getChildCohortInfo = (di: DemandInfluence) => {
        let cohorts: Set<string> = new Set();
        let states: Set<string> = new Set();
        let regions: Set<string> = new Set();
        let locations: Set<string> = new Set();
        let amenities: Set<string> = new Set();
        let sizes: Set<string> = new Set();
        let managers: Set<string> = new Set();
        let analysts: Set<string> = new Set();


        di.child_cohorts.forEach(c => {
            cohorts.add(c.strategic_cohort)
            managers.add(c.manager)
            analysts.add(c.analyst)

            const parts = c.strategic_cohort.split("_")
            states.add(parts[0])
            regions.add(parts[1])
            locations.add(parts[2])
            amenities.add(parts[3])
            sizes.add(parts[4])
        })

        let similarParts: string[] = [];
        if (states.size < 3) similarParts.push(Array.from(states).join('/'))
        if (regions.size < 3) similarParts.push(Array.from(regions).join(', '))
        if (locations.size === 1) similarParts.push(Array.from(locations).join(', '))
        if (amenities.size === 1) similarParts.push(Array.from(amenities).join(', '))
        if (sizes.size === 1) similarParts.push(Array.from(sizes).join(', '))

        // Used for global search
        const allCohorts =  Array.from(cohorts).join(', ');
        const allManagers = Array.from(managers).join('/');
        const allAnalysts = Array.from(analysts).join('/');

        // Displayed in table
        const managerDisplay = managers.size < 3 ? allManagers : `${managers.size} Managers`;
        const analystDisplay = analysts.size < 3 ? allAnalysts : `${analysts.size} Analysts`;
        const cohortDisplay = similarParts.length === 0 ? "No Cohort Similarity" : similarParts.join("_");

        return {
            allCohorts: allCohorts,
            allManagers: allManagers,
            allAnalysts: allAnalysts,
            cohortDisplay: cohortDisplay,
            managerDisplay: managerDisplay,
            analystDisplay: analystDisplay,
        };
    }

    useEffect(() => {
        if(!!cohortData && !!selectedCohortID) {
            cohortData.forEach((cohort) => {
                if (cohort.id === parseInt(selectedCohortID)) {
                    setSelectedCohort(cohort)
                    return;
                }
            })
        }
    }, [cohortData, selectedCohortID])

    useEffect(() => {
        if (!isEmpty(demandInfluenceInfo)) setDemandInfluenceData(() => {
            let copy: DemandInfluence[] = []
            demandInfluenceInfo.forEach((di: DemandInfluence) => {
                let rowCopy = {...di};
                const childCohortInfo = getChildCohortInfo(di);
                rowCopy.child_cohort_root = childCohortInfo.cohortDisplay;
                rowCopy.search_cohorts = childCohortInfo.allCohorts;
                rowCopy.search_managers = childCohortInfo.allManagers;
                rowCopy.search_analysts = childCohortInfo.allAnalysts;

                rowCopy.extra_data = {
                    managers: childCohortInfo.managerDisplay,
                    analysts: childCohortInfo.analystDisplay,
                    email: di.email,
                    approver: di.approver,
                    demand_type: di.demand_type,
                    description: di.description,
                }
                copy.push(rowCopy);
            })
            return orderBy(copy, "priority", "desc");
        });
    }, [demandInfluenceInfo, isFetchingDI])

    useEffect(() => {
        // We enable viewing all demand influences to support search-ability (maybe add a filter on title)?
        //   but users should not be previewing and editing details in that use case.
        if (!isFetchingDI && !selectedCohortID) {
            setUiAlert({"content": "You are viewing all Demand Influences", "type": "success"})
        }
        else if (!isFetchingDI && !!selectedCohortID && !!selectedCohort && !selectedCohort.active) {
            setUiAlert({"content": `${selectedCohort.name} is not active`, "type": "error"})
        }
        else if (!isFetchingDI) {
            setUiAlert({"content": selectedCohort?.name, "type": "warning"})
        }
        else {
            setUiAlert(null)
        }
    }, [isFetchingDI, selectedCohortID, selectedCohort])

    useEffect(() => {
        // Filter Demand Influences for inactive influences and expired influences.
        // Handle each separately for readability.
        // Use ternaries to short-circuit if not hiding.

        // First: Inactive
        let filteredData = !hideInactive ? demandInfluenceData : demandInfluenceData.filter((di) => di.active);

        // Second
        if (showRequiresApproval) {
            filteredData = filteredData.filter((di) => {
                return (di.approval === 'pending')
            })
        }

        // Third: Expired
        // As of 12-26-2023 we have two demand types:
        //  1. Events -> Filter when end_stay_date is past current
        //  2. Adjustments -> Filter when end_booking_date is past current

        filteredData = !hideExpired ? filteredData : filteredData.filter(
            (di) => {
                // Ternary to decide which end date to use. Events: stay-date. Adjustments: booking-date
                let endDate = di.demand_type === "event" ? new Date(di.end_stay_date ) : new Date(di.end_booking_date)
                return endDate > today;
            }
        )

        // Fourth: search text
        searchValue.toLowerCase().split(" ").forEach(sv => {
            filteredData = filteredData.filter((di) => {
                return (
                    di.id.toString().includes(searchValue) ||
                    di.title.toLowerCase().includes(sv) ||
                    di.description.toLowerCase().includes(sv) ||
                    di.username.toLowerCase().includes(sv) ||
                    di.email.toLowerCase().includes(sv) ||
                    di.search_cohorts?.toLowerCase().includes(sv) ||
                    di.search_managers?.toLowerCase().includes(sv) ||
                    di.search_analysts?.toLowerCase().includes(sv)
                )
            })
        });

        setFilteredDI(filteredData)
    }, [demandInfluenceData, selectedCohortID, showRequiresApproval, hideExpired, hideInactive, searchValue])

    const builder = new DataSourceBuilder<DemandInfluence>();

    const getDetailActions = (row: DemandInfluence) => {
        return (
            <>
                <Tooltip message="Edit Demand Influence">
                    <div className="action-button action-button-icon" onClick={() => {
                        setModalType(DemandInfluenceFormOptions.EDIT);
                        setModalDemandInfluence(row);
                        setOpenDetailModal(false);
                        setOpenModal(true);
                    }}>
                        <Icon.Edit3 height={24} width={24}/>
                    </div>
                </Tooltip>
                <Tooltip message="Copy Demand Influence">
                    <div className="action-button action-button-icon" onClick={() => {
                        setModalType(DemandInfluenceFormOptions.COPY);
                        setModalDemandInfluence(row);
                        setOpenModal(true);
                    }}>
                        <Icon.Copy height={24} width={24}/>
                    </div>
                </Tooltip>
            </>
        )
    }

    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.5
        },
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Title',
        field: 'title',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1.5
        },
    });
    builder.addColumn({
        label: 'Cohort Similarities',
        field: 'child_cohort_root',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1.0
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (
                    <Tooltip message={`${row.search_cohorts}`}>
                        <div>
                            <Icon.AlertCircle height={14} width={14}/>
                            {row.child_cohort_root}
                        </div>
                    </Tooltip>
                )
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Nights',
        field: 'number_of_nights',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.6
        }
    });
    builder.addColumn({
        label: 'Cohorts',
        field: 'cohort_count',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.3
        }
    });
    builder.addColumn({
        label: 'Units',
        field: 'unit_count',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.3
        }
    });
    builder.addColumn({
        label: 'Level',
        field: 'priority',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.3
        }
    });
    builder.addColumn({
        label: 'Booking Date (Ramp)',
        field: 'booking_range',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1
        }
    });
    builder.addColumn({
        label: 'Stay Date (Hold)',
        field: 'stay_range',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1
        }
    });
    builder.addColumn({
        label: 'Active',
        field: 'active',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.3
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.active) return <Icon.XCircle className="icon-danger" height={24} width={24}/>;
                return <Icon.CheckSquare className="icon-success" height={24} width={24}/>;
            }
    }});
    builder.addColumn({
        label: 'Approved',
        field: 'approver',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.3
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                switch(row.approval) {
                    case 'approved':
                        return (
                            <Tooltip message={"Approved"}>
                                <div className="approval-status">
                                    <Icon.CheckSquare className="icon-success" height={24} width={24}/>
                                </div>
                            </Tooltip>
                        )
                    case 'rejected':
                        return (
                            <Tooltip message={"Rejected: click to display reason"}>
                                <div className="approval-status">
                                    <Icon.XCircle className="icon-danger" height={24} width={24} onClick={() => {
                                        setModalType(DemandInfluenceFormOptions.APPROVE);
                                        setModalDemandInfluence(row);
                                        setOpenModal(false);
                                        setOpenApprovalModal(true);
                                    }}/>
                                </div>
                            </Tooltip>
                        )
                    case 'pending':
                        return (
                            <Tooltip message={"Requires approval"}>
                                <div className="approval-status">
                                    <Icon.AlertTriangle className="icon-warning" height={24} width={24}/>
                                </div>
                            </Tooltip>
                        )
                    default:
                        return (
                            <Tooltip message={"Approval not required"}>
                                <div className="approval-status">
                                    <Icon.MinusCircle height={24} width={24}/>
                                </div>
                            </Tooltip>
                        )
                }
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Actions',
        field: 'actions',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: .85
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                let iconComponent: React.ReactNode;
                switch (row.approval) {
                    case 'approved':
                        iconComponent = <Icon.CheckSquare className="icon-success" height={24} width={24}/>
                        break;
                    case 'rejected':
                        iconComponent = <Icon.XCircle className="icon-danger" height={24} width={24}/>
                        break;
                    case 'pending':
                        iconComponent = <Icon.AlertTriangle className="icon-warning" height={24} width={24}/>
                        break;
                    default:
                        iconComponent = <Icon.MinusCircle height={24} width={24}/>
                        break;
                }
                return (
                    <div className="action-button-list">
                        {getDetailActions(row)}
                        { getIsApprover(row)
                            ?
                            <Tooltip message={
                                "Approve or reject this influence"
                            }>
                                <div className="action-button action-button-icon"
                                     onClick={() => {
                                         setModalType(DemandInfluenceFormOptions.APPROVE);
                                         setModalDemandInfluence(row);
                                         setOpenModal(false);
                                         setOpenApprovalModal(true);
                                     }}>
                                    {
                                        row.loading
                                            ?
                                            <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                            :
                                            iconComponent
                                    }
                                </div>
                            </Tooltip>
                            :
                            <></>
                        }
                    </div>
                )
            }
        }
    })

    builder.addPagination({remote: false});
    builder.setSortable({field: 'priority', order: 'desc'})

    return (
        <div>
            <div className="demand-influence-list">
                <div className="di-utility-row">
                    <div className="di-alert-container">
                        {uiAlert && (
                            <AlertMessage
                                customClass="alert-message"
                                text={uiAlert?.content}
                                type={uiAlert?.type}
                                height="small"
                            />
                        )}
                    </div>
                    <div className="di-search">
                        <Input
                            customClass="search-input"
                            type="text"
                            value={searchValue}
                            placeholder="Search..."
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                        />
                    </div>
                    <Tooltip message="Hide Demand Influences that do not need approval or are already approved">
                        <div className="table-icons">
                            <Checkbox
                                checked={showRequiresApproval}
                                onChange={() => setShowRequiresApproval(!showRequiresApproval)}
                                className="filter-cb"
                                id="approval-cb"
                            />
                            <label htmlFor="approval-cb">Needs Approval</label>
                        </div>
                    </Tooltip>
                    <Tooltip message="Inactive Demand Influences are hidden by default">
                        <div className="table-icons">
                            <Checkbox
                                checked={hideInactive}
                                onChange={() => setHideInactive(!hideInactive)}
                                className="filter-cb"
                                id="inactive-cb"
                            />
                            <label htmlFor="inactive-cb">Hide Inactive</label>
                        </div>
                    </Tooltip>
                    <Tooltip message="Demand Influences that have ended are hidden by default">
                        <div className="table-icons">
                            <Checkbox
                                checked={hideExpired}
                                onChange={() => setHideExpired(!hideExpired)}
                                className="filter-cb"
                                id="expired-cb"
                            />
                            <label htmlFor="expired-cb">Hide Expired</label>
                        </div>
                    </Tooltip>
                    <Tooltip message="Refresh Table">
                         <span className="demand-influence-icon" onClick={refetch}>
                            <Icon.RefreshCCW className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                    <Tooltip message="Add Demand Influence">
                         <span className="demand-influence-icon" onClick={() => {
                             setModalType(DemandInfluenceFormOptions.ADD);
                             setModalDemandInfluence(null);
                             setOpenModal(true);
                         }}>
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                </div>
                <div className="demand-influence-table">
                    {isFetchingDI
                        ? <Loading className="demand-influence-list-loading"/>
                        : <VirtualizedTable
                            className="demand-influence-list-table"
                            dataSource={builder.build(filteredDI)}
                            onRowChange={() => null}
                            pageSize={10}
                            // pageSize={selectedCohortID ? 5 : 10}
                            isAccordion={{
                                handleUiAlert: (message: Message) => setUiAlert(message)
                            }}
                        />
                    }
                </div>
            </div>
                {/*{selectedCohortID ?*/}
                {/*    <div className="demand-factor-table">*/}
                {/*        <DemandFactorContainer cohortID={selectedCohortID}></DemandFactorContainer>*/}
                {/*    </div>*/}
                {/*    : <></>*/}
                {/*}*/}
            <Modal
                canExit={true}
                showModal={openModal}
                setShowModal={setOpenModal}
                size='large'
            >
                <DemandInfluenceWizard
                    modalType={modalType}
                    cohorts={cohortData}
                    isFetchingCohorts={isFetchingCohorts}
                    selectedDI={modalDemandInfluence}
                    // currentUser={currentUser}
                    closeModal={(refresh?: boolean) => {
                        setOpenModal(false);
                        if (refresh === true) refetch();
                    }}
                 />
            </Modal>
            <Modal
                canExit={true}
                showModal={openApprovalModal}
                setShowModal={setOpenApprovalModal}
                size='medium'
            >
                <DemandInfluenceApprovalForm
                    closeModal={(refresh?: boolean) => {
                        setOpenApprovalModal(false);
                        if (refresh === true) refetch();
                    }}
                    currentDI={modalDemandInfluence}
                    handleApprove={handleApprove}
                    viewOnly={!isApprover}
                />
            </Modal>
        </div>
    );
};
