import React, {useEffect, useRef, useState} from 'react';
import {Button, Icon, Select, RadioButton, Tooltip} from '@vacasa/react-components-lib';
import './DemandDetailForm.scss';
import {UiUtils} from "../../../utils";
import {
    DemandDetail,
    DemandInfluence,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    MatrixInfo,
    SelectOption
} from "../../../types";
import {Loading} from "../../Common/Loading/Loading";
import {isEmpty, isNaN} from "lodash";
import {Checkbox, Switch} from '@material-ui/core';
import {useGetMatrixQuery} from "../../../store";
import {Influence} from "./Influence";


// Pages that load this component will control these properties
interface DemandDetailFormProps {
    type: DemandInfluenceFormType;
    currentDI: DemandInfluence;
    selectedCohortIDs: number[];
    syncBookingWindows: boolean;
    syncPriceBuckets: boolean;
    selectedMetric: string;
    rangesSwitch: boolean;
    previewSwitch: boolean;
    cohortOptions: SelectOption[];
    dateOptions: SelectOption[];
    selectedDate: string;
    selectedCohort: number;
    setSyncBookingWindows: (cb: boolean) => void;
    setSyncPriceBuckets: (cb: boolean) => void;
    setSelectedMetric: (s: string) => void;
    setSelectedCohort: (c: number) => void;
    setRangesSwitch: (b: boolean) => void;
    setPreviewSwitch: (b: boolean) => void;
    setCohortOptions: (sco: SelectOption[]) => void;
    setSelectedDate: (dt: string) => void;
    setDateOptions: (d: SelectOption[]) => void;
    closeModal: (boolean?) => void;
    handleNavigation: (di: DemandInfluence, dids: Partial<DemandDetail>[], n: number) => void;
}

export const DemandDetailForm: React.FC<DemandDetailFormProps> = (props) => {
    const {
        type,
        currentDI,
        selectedCohortIDs,
        syncBookingWindows,
        syncPriceBuckets,
        selectedMetric,
        rangesSwitch,
        previewSwitch,
        cohortOptions,
        dateOptions,
        selectedDate,
        selectedCohort,
        setSyncBookingWindows,
        setSyncPriceBuckets,
        setSelectedMetric,
        setRangesSwitch,
        setPreviewSwitch,
        setSelectedCohort,
        setCohortOptions,
        setSelectedDate,
        setDateOptions,
        closeModal,
        handleNavigation,
    } = props;

    const currentDetails = currentDI.details;
    const {data: matrixData, isFetching: isFetchingMatrix} = useGetMatrixQuery({
        start_date: currentDI.demand_type === "adjustment" ? currentDI.start_stay_date : currentDI.start_booking_date,
        end_date: currentDI.end_stay_date,
        strategic_cohort_ids: selectedCohortIDs,
    });
    const [filteredMatrixData, setFilteredMatrixData] = useState<MatrixInfo[]>();
    const [matrixByGroups, setMatrixByGroups] = useState<any>();
    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [bookingWindows, setBookingWindows] = useState([]);
    const [priceBuckets, setPriceBuckets] = useState([]);
    const [alertRequiresApproval, setAlertRequiresApproval] = useState<boolean>(null);
    const requiresApprovalMsg = "Adjustments over +/-30% require approval";
    const minInfluence = -100; // Allow demand to be zeroed out
    const maxInfluence = 500; // TODO: influence based on capacity, not forecast (because forecast could be 0)

    const defaultBWs = [
        {min: null, max: null, display: 'BW0'},
        {min: null, max: null, display: 'BW1'},
        {min: null, max: null, display: 'BW2'},
        {min: null, max: null, display: 'BW3'},
        {min: null, max: null, display: 'BW4'},
        {min: null, max: null, display: 'BW5'},
        {min: null, max: null, display: 'BW6'},
    ]
    const defaultPBs = [
        {min: null, max: null, display: 'PG0'},
        {min: null, max: null, display: 'PG1'},
        {min: null, max: null, display: 'PG2'},
        {min: null, max: null, display: 'PG3'},
        {min: null, max: null, display: 'PG4'},
        {min: null, max: null, display: 'PG5'},
        {min: null, max: null, display: 'PG6'},
    ]

    const editing = type === DemandInfluenceFormOptions.EDIT
    // const adding = type === DemandInfluenceFormOptions.ADD

    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    const getInfluenceClassName = (ipb: number, ibw: number) => {
        let classes = ['influence-input']

        // highlight cell if either starting or target is populated. Green for both starting/target. Red for just one.
        let starting = startingInfluences[ibw][ipb]
        let target = targetInfluences[ibw][ipb];

        let validStarting = starting !== null && !isNaN(starting)
        let validTarget = target !== null && !isNaN(target)

        let borderClass = "";
        if ((validStarting && !validTarget) || (!validStarting && validTarget)) borderClass = "error"
        else if (validTarget && validStarting) borderClass = "populated"

        classes.push(borderClass)

        return classes.join(" ")
    }

    const getInfluenceStyle = (ipb: number, ibw: number) => {
        const opacity = matrixByGroups[ibw][ipb]["selected_price"] / matrixByGroups[ibw][ipb]["total"];
        if (opacity > 0.1) return {
            border: `2px solid rgb(255, 0, 0, ${opacity})`
        }

        return {
            border: "1px solid rgb(0, 0, 0)"
        }
    }

    // BRUTE FORCE
    const [startingInfluence00, setStartingInfluence00] = useState(null);
    const [startingInfluence01, setStartingInfluence01] = useState(null);
    const [startingInfluence02, setStartingInfluence02] = useState(null);
    const [startingInfluence03, setStartingInfluence03] = useState(null);
    const [startingInfluence04, setStartingInfluence04] = useState(null);
    const [startingInfluence05, setStartingInfluence05] = useState(null);
    const [startingInfluence06, setStartingInfluence06] = useState(null);
    const [startingInfluence10, setStartingInfluence10] = useState(null);
    const [startingInfluence11, setStartingInfluence11] = useState(null);
    const [startingInfluence12, setStartingInfluence12] = useState(null);
    const [startingInfluence13, setStartingInfluence13] = useState(null);
    const [startingInfluence14, setStartingInfluence14] = useState(null);
    const [startingInfluence15, setStartingInfluence15] = useState(null);
    const [startingInfluence16, setStartingInfluence16] = useState(null);
    const [startingInfluence20, setStartingInfluence20] = useState(null);
    const [startingInfluence21, setStartingInfluence21] = useState(null);
    const [startingInfluence22, setStartingInfluence22] = useState(null);
    const [startingInfluence23, setStartingInfluence23] = useState(null);
    const [startingInfluence24, setStartingInfluence24] = useState(null);
    const [startingInfluence25, setStartingInfluence25] = useState(null);
    const [startingInfluence26, setStartingInfluence26] = useState(null);
    const [startingInfluence30, setStartingInfluence30] = useState(null);
    const [startingInfluence31, setStartingInfluence31] = useState(null);
    const [startingInfluence32, setStartingInfluence32] = useState(null);
    const [startingInfluence33, setStartingInfluence33] = useState(null);
    const [startingInfluence34, setStartingInfluence34] = useState(null);
    const [startingInfluence35, setStartingInfluence35] = useState(null);
    const [startingInfluence36, setStartingInfluence36] = useState(null);
    const [startingInfluence40, setStartingInfluence40] = useState(null);
    const [startingInfluence41, setStartingInfluence41] = useState(null);
    const [startingInfluence42, setStartingInfluence42] = useState(null);
    const [startingInfluence43, setStartingInfluence43] = useState(null);
    const [startingInfluence44, setStartingInfluence44] = useState(null);
    const [startingInfluence45, setStartingInfluence45] = useState(null);
    const [startingInfluence46, setStartingInfluence46] = useState(null);
    const [startingInfluence50, setStartingInfluence50] = useState(null);
    const [startingInfluence51, setStartingInfluence51] = useState(null);
    const [startingInfluence52, setStartingInfluence52] = useState(null);
    const [startingInfluence53, setStartingInfluence53] = useState(null);
    const [startingInfluence54, setStartingInfluence54] = useState(null);
    const [startingInfluence55, setStartingInfluence55] = useState(null);
    const [startingInfluence56, setStartingInfluence56] = useState(null);
    const [startingInfluence60, setStartingInfluence60] = useState(null);
    const [startingInfluence61, setStartingInfluence61] = useState(null);
    const [startingInfluence62, setStartingInfluence62] = useState(null);
    const [startingInfluence63, setStartingInfluence63] = useState(null);
    const [startingInfluence64, setStartingInfluence64] = useState(null);
    const [startingInfluence65, setStartingInfluence65] = useState(null);
    const [startingInfluence66, setStartingInfluence66] = useState(null);

    const startingInfluences = {
        0: {
            0: startingInfluence00,
            1: startingInfluence01,
            2: startingInfluence02,
            3: startingInfluence03,
            4: startingInfluence04,
            5: startingInfluence05,
            6: startingInfluence06,
        },
        1: {
            0: startingInfluence10,
            1: startingInfluence11,
            2: startingInfluence12,
            3: startingInfluence13,
            4: startingInfluence14,
            5: startingInfluence15,
            6: startingInfluence16,
        }, 2: {
            0: startingInfluence20,
            1: startingInfluence21,
            2: startingInfluence22,
            3: startingInfluence23,
            4: startingInfluence24,
            5: startingInfluence25,
            6: startingInfluence26,
        }, 3: {
            0: startingInfluence30,
            1: startingInfluence31,
            2: startingInfluence32,
            3: startingInfluence33,
            4: startingInfluence34,
            5: startingInfluence35,
            6: startingInfluence36,
        }, 4: {
            0: startingInfluence40,
            1: startingInfluence41,
            2: startingInfluence42,
            3: startingInfluence43,
            4: startingInfluence44,
            5: startingInfluence45,
            6: startingInfluence46,
        }, 5: {
            0: startingInfluence50,
            1: startingInfluence51,
            2: startingInfluence52,
            3: startingInfluence53,
            4: startingInfluence54,
            5: startingInfluence55,
            6: startingInfluence56,
        }, 6: {
            0: startingInfluence60,
            1: startingInfluence61,
            2: startingInfluence62,
            3: startingInfluence63,
            4: startingInfluence64,
            5: startingInfluence65,
            6: startingInfluence66,
        }
    }
    const setStartingInfluences = {
        0: {
            0: setStartingInfluence00,
            1: setStartingInfluence01,
            2: setStartingInfluence02,
            3: setStartingInfluence03,
            4: setStartingInfluence04,
            5: setStartingInfluence05,
            6: setStartingInfluence06,
        },
        1: {
            0: setStartingInfluence10,
            1: setStartingInfluence11,
            2: setStartingInfluence12,
            3: setStartingInfluence13,
            4: setStartingInfluence14,
            5: setStartingInfluence15,
            6: setStartingInfluence16,
        }, 2: {
            0: setStartingInfluence20,
            1: setStartingInfluence21,
            2: setStartingInfluence22,
            3: setStartingInfluence23,
            4: setStartingInfluence24,
            5: setStartingInfluence25,
            6: setStartingInfluence26,
        }, 3: {
            0: setStartingInfluence30,
            1: setStartingInfluence31,
            2: setStartingInfluence32,
            3: setStartingInfluence33,
            4: setStartingInfluence34,
            5: setStartingInfluence35,
            6: setStartingInfluence36,
        }, 4: {
            0: setStartingInfluence40,
            1: setStartingInfluence41,
            2: setStartingInfluence42,
            3: setStartingInfluence43,
            4: setStartingInfluence44,
            5: setStartingInfluence45,
            6: setStartingInfluence46,
        }, 5: {
            0: setStartingInfluence50,
            1: setStartingInfluence51,
            2: setStartingInfluence52,
            3: setStartingInfluence53,
            4: setStartingInfluence54,
            5: setStartingInfluence55,
            6: setStartingInfluence56,
        }, 6: {
            0: setStartingInfluence60,
            1: setStartingInfluence61,
            2: setStartingInfluence62,
            3: setStartingInfluence63,
            4: setStartingInfluence64,
            5: setStartingInfluence65,
            6: setStartingInfluence66,
        }
    }

    const [targetInfluence00, setTargetInfluence00] = useState(null);
    const [targetInfluence01, setTargetInfluence01] = useState(null);
    const [targetInfluence02, setTargetInfluence02] = useState(null);
    const [targetInfluence03, setTargetInfluence03] = useState(null);
    const [targetInfluence04, setTargetInfluence04] = useState(null);
    const [targetInfluence05, setTargetInfluence05] = useState(null);
    const [targetInfluence06, setTargetInfluence06] = useState(null);
    const [targetInfluence10, setTargetInfluence10] = useState(null);
    const [targetInfluence11, setTargetInfluence11] = useState(null);
    const [targetInfluence12, setTargetInfluence12] = useState(null);
    const [targetInfluence13, setTargetInfluence13] = useState(null);
    const [targetInfluence14, setTargetInfluence14] = useState(null);
    const [targetInfluence15, setTargetInfluence15] = useState(null);
    const [targetInfluence16, setTargetInfluence16] = useState(null);
    const [targetInfluence20, setTargetInfluence20] = useState(null);
    const [targetInfluence21, setTargetInfluence21] = useState(null);
    const [targetInfluence22, setTargetInfluence22] = useState(null);
    const [targetInfluence23, setTargetInfluence23] = useState(null);
    const [targetInfluence24, setTargetInfluence24] = useState(null);
    const [targetInfluence25, setTargetInfluence25] = useState(null);
    const [targetInfluence26, setTargetInfluence26] = useState(null);
    const [targetInfluence30, setTargetInfluence30] = useState(null);
    const [targetInfluence31, setTargetInfluence31] = useState(null);
    const [targetInfluence32, setTargetInfluence32] = useState(null);
    const [targetInfluence33, setTargetInfluence33] = useState(null);
    const [targetInfluence34, setTargetInfluence34] = useState(null);
    const [targetInfluence35, setTargetInfluence35] = useState(null);
    const [targetInfluence36, setTargetInfluence36] = useState(null);
    const [targetInfluence40, setTargetInfluence40] = useState(null);
    const [targetInfluence41, setTargetInfluence41] = useState(null);
    const [targetInfluence42, setTargetInfluence42] = useState(null);
    const [targetInfluence43, setTargetInfluence43] = useState(null);
    const [targetInfluence44, setTargetInfluence44] = useState(null);
    const [targetInfluence45, setTargetInfluence45] = useState(null);
    const [targetInfluence46, setTargetInfluence46] = useState(null);
    const [targetInfluence50, setTargetInfluence50] = useState(null);
    const [targetInfluence51, setTargetInfluence51] = useState(null);
    const [targetInfluence52, setTargetInfluence52] = useState(null);
    const [targetInfluence53, setTargetInfluence53] = useState(null);
    const [targetInfluence54, setTargetInfluence54] = useState(null);
    const [targetInfluence55, setTargetInfluence55] = useState(null);
    const [targetInfluence56, setTargetInfluence56] = useState(null);
    const [targetInfluence60, setTargetInfluence60] = useState(null);
    const [targetInfluence61, setTargetInfluence61] = useState(null);
    const [targetInfluence62, setTargetInfluence62] = useState(null);
    const [targetInfluence63, setTargetInfluence63] = useState(null);
    const [targetInfluence64, setTargetInfluence64] = useState(null);
    const [targetInfluence65, setTargetInfluence65] = useState(null);
    const [targetInfluence66, setTargetInfluence66] = useState(null);

    const targetInfluences = {
        0: {
            0: targetInfluence00,
            1: targetInfluence01,
            2: targetInfluence02,
            3: targetInfluence03,
            4: targetInfluence04,
            5: targetInfluence05,
            6: targetInfluence06,
        },
        1: {
            0: targetInfluence10,
            1: targetInfluence11,
            2: targetInfluence12,
            3: targetInfluence13,
            4: targetInfluence14,
            5: targetInfluence15,
            6: targetInfluence16,
        }, 2: {
            0: targetInfluence20,
            1: targetInfluence21,
            2: targetInfluence22,
            3: targetInfluence23,
            4: targetInfluence24,
            5: targetInfluence25,
            6: targetInfluence26,
        }, 3: {
            0: targetInfluence30,
            1: targetInfluence31,
            2: targetInfluence32,
            3: targetInfluence33,
            4: targetInfluence34,
            5: targetInfluence35,
            6: targetInfluence36,
        }, 4: {
            0: targetInfluence40,
            1: targetInfluence41,
            2: targetInfluence42,
            3: targetInfluence43,
            4: targetInfluence44,
            5: targetInfluence45,
            6: targetInfluence46,
        }, 5: {
            0: targetInfluence50,
            1: targetInfluence51,
            2: targetInfluence52,
            3: targetInfluence53,
            4: targetInfluence54,
            5: targetInfluence55,
            6: targetInfluence56,
        }, 6: {
            0: targetInfluence60,
            1: targetInfluence61,
            2: targetInfluence62,
            3: targetInfluence63,
            4: targetInfluence64,
            5: targetInfluence65,
            6: targetInfluence66,
        }
    }
    const setTargetInfluences = {
        0: {
            0: setTargetInfluence00,
            1: setTargetInfluence01,
            2: setTargetInfluence02,
            3: setTargetInfluence03,
            4: setTargetInfluence04,
            5: setTargetInfluence05,
            6: setTargetInfluence06,
        }, 1: {
            0: setTargetInfluence10,
            1: setTargetInfluence11,
            2: setTargetInfluence12,
            3: setTargetInfluence13,
            4: setTargetInfluence14,
            5: setTargetInfluence15,
            6: setTargetInfluence16,
        }, 2: {
            0: setTargetInfluence20,
            1: setTargetInfluence21,
            2: setTargetInfluence22,
            3: setTargetInfluence23,
            4: setTargetInfluence24,
            5: setTargetInfluence25,
            6: setTargetInfluence26,
        }, 3: {
            0: setTargetInfluence30,
            1: setTargetInfluence31,
            2: setTargetInfluence32,
            3: setTargetInfluence33,
            4: setTargetInfluence34,
            5: setTargetInfluence35,
            6: setTargetInfluence36,
        }, 4: {
            0: setTargetInfluence40,
            1: setTargetInfluence41,
            2: setTargetInfluence42,
            3: setTargetInfluence43,
            4: setTargetInfluence44,
            5: setTargetInfluence45,
            6: setTargetInfluence46,
        }, 5: {
            0: setTargetInfluence50,
            1: setTargetInfluence51,
            2: setTargetInfluence52,
            3: setTargetInfluence53,
            4: setTargetInfluence54,
            5: setTargetInfluence55,
            6: setTargetInfluence56,
        }, 6: {
            0: setTargetInfluence60,
            1: setTargetInfluence61,
            2: setTargetInfluence62,
            3: setTargetInfluence63,
            4: setTargetInfluence64,
            5: setTargetInfluence65,
            6: setTargetInfluence66,
        }
    }

    const updateInfluence = (ibw: number, ipb: number, value: number) => {
        if (["starting", "target"].indexOf(selectedMetric) < 0) return;

        if (value > maxInfluence) {
            value = maxInfluence;
        }
        if (value < minInfluence) {
            value = minInfluence;
        }
        const setInfluenceDict =  selectedMetric === "starting" ? setStartingInfluences : setTargetInfluences;
        setInfluenceDict[ibw][ipb](value)
        // If both checkboxes then sync everything and short-circuit out of this block
        if (syncPriceBuckets && syncBookingWindows) {
            for (let ibw in setInfluenceDict) {
                // if (validBWGroups.indexOf(parseInt(ibw)) < 0) continue;
                for (let ipb in setInfluenceDict[ibw]) {
                    setInfluenceDict[ibw][ipb](value);
                }
            }
        }
        else if (syncBookingWindows) {
            for (let ibw in setInfluenceDict) {
                // if (validBWGroups.indexOf(parseInt(ibw)) < 0) continue;
                setInfluenceDict[ibw][ipb](value);
            }
        }
        else if (syncPriceBuckets) {
            for (let ipb in setInfluenceDict[ibw]) {
                setInfluenceDict[ibw][ipb](value);
            }
        }
        else {
            setInfluenceDict[ibw][ipb](value)
        }
    }

    const getRequiresApproval = () => {
        for (let ipb in Object.keys(priceBuckets)){
            for (let ibw in Object.keys(bookingWindows)) {
                if (Math.abs(startingInfluences[ibw][ipb]) > 30 || Math.abs(targetInfluences[ibw][ipb]) > 30) {
                    return true;
                }
            }
        }
        return false;
    }

    const getValue = (ipb: number, ibw: number) => {
        let factor = 1;
        if (previewSwitch && !!startingInfluences[ibw][ipb]) {
            factor += startingInfluences[ibw][ipb] / 100
        }

        let value = Math.round(matrixByGroups[ibw][ipb][selectedMetric] * factor * 100) / 100;
        if (selectedMetric === "nested_revenue") {
            value = Math.round(value * 100 / matrixByGroups[ibw][ipb]["total"]) / 100
        }

        return value;
    }

    const handleSave = async (navigation: number) => {
        setIsSaving(true)

        let newDemandDetails: Partial<DemandDetail>[] = []
        priceBuckets.forEach((pb, ipb) => {
            bookingWindows.forEach((bw, ibw) => {
                newDemandDetails.push({
                    influence_type: "starting",
                    booking_window_group: ibw,
                    price_group: ipb,
                    influence: startingInfluences[ibw][ipb]
                });
                newDemandDetails.push({
                    influence_type: "target",
                    booking_window_group: ibw,
                    price_group: ipb,
                    influence: targetInfluences[ibw][ipb]
                })
            })
        })
        handleNavigation(currentDI, newDemandDetails, navigation)
    };

    // Watchers
    useEffect(() => {
        setAlertRequiresApproval(getRequiresApproval())
    }, [...Object.values(startingInfluences), ...Object.values(targetInfluences)]);

    useEffect(() => {
        if(!isEmpty(currentDetails)) {
            currentDetails.forEach((d) => {
                if(d.influence_type === "starting"){
                    setStartingInfluences[d.booking_window_group][d.price_group](d.influence);
                }
                else if (d.influence_type === "target"){
                    setTargetInfluences[d.booking_window_group][d.price_group](d.influence);
                }
            })
        }
    }, [currentDetails]);

    useEffect(() => {
        if (!isFetchingMatrix && matrixData.length > 0) {
            const existingCohorts = matrixData
                .map(m => m.strategic_cohort_id)
                .filter((v, idx, cohorts) => cohorts.indexOf(v) === idx);

            let cohortOptionsCopy = [...cohortOptions];
            cohortOptionsCopy = cohortOptionsCopy.filter(c => existingCohorts.indexOf(c.value as number) >= 0);
            setCohortOptions([{display: "", value: null}, ...cohortOptionsCopy]);

            const existingDates = matrixData
                .map(m => m.stay_date)
                .filter((v, idx, dates) => dates.indexOf(v) === idx);

            let dateOptionsCopy = [...dateOptions];
            dateOptionsCopy = dateOptionsCopy.filter(c => existingDates.indexOf(c.value as string) >= 0);
            setDateOptions([{display: "", value: null}, ...dateOptionsCopy]);

            setFilteredMatrixData(matrixData);
        }
    }, [matrixData, isFetchingMatrix])


    useEffect(() => {
        if (!!filteredMatrixData && filteredMatrixData.length > 0) {
            const structure = UiUtils.getMatrixFromStructure(filteredMatrixData, selectedCohort, selectedDate);
            setMatrixByGroups(structure);

            if (rangesSwitch) {
                const ranges = UiUtils.getRanges(structure);
                setPriceBuckets(ranges["priceGroups"]);
                setBookingWindows(ranges["bookingWindowGroups"]);
            } else {
                setPriceBuckets(defaultPBs);
                setBookingWindows(defaultBWs)
            }
        }
    }, [rangesSwitch, filteredMatrixData, selectedCohort, selectedDate])

    useEffect(() => {
        if(
            !!priceBuckets &&
            !!bookingWindows &&
            !isFetchingMatrix
        ) {
            setIsSaveDisabled(false);
            setIsInitializing(false)
        }
        else {
            setIsInitializing(true)
        }
    }, [bookingWindows, priceBuckets, isFetchingMatrix])

    // This is unused because the component would rerender when you change tabs. State needs to be tied to the parent
    // After exploring radio buttons, maybe try this, again?
    const influenceRefs = useRef<{ [key: string]: any }>({});
    const setReferenceEditableColumn = (ibw: number, ipb: number) => {
        return (input) => {
            influenceRefs.current[`${ibw},${ipb}`] = input;
        };
    };
    const renderInfluence = (ipb: number, ibw: number, influenceType: string, originalValue: number) => {
        return (
            <Influence
                disabled={false}
                influenceType={influenceType}
                ibw={ibw}
                ipb={ipb}
                originalValue={originalValue}
                ref={setReferenceEditableColumn(ibw, ipb)}
            >
            </Influence>
        );
    };

    return (
        <React.Fragment>
            <div className="form-header">
                <h5>{editing ? 'Edit' : 'Add'} Demand Details</h5>
            </div>
            <div className="details-form-body no-padding"> { isInitializing || isFetchingMatrix ?
                <Loading className="details-loading"/>
                :
                <>
                <div className="details-input-row">
                    <div className="details-input-group details-cb">
                        <label htmlFor="sync-cb">Sync
                            Columns</label>
                        <Checkbox
                            checked={syncPriceBuckets}
                            onChange={() => setSyncPriceBuckets(!syncPriceBuckets)}
                            className="dow-checkbox"
                            id="sync-cb"
                        />
                    </div>
                    <div className="details-input-group details-cb">
                        <label htmlFor="sync-cb">Sync Rows</label>
                        <Checkbox
                            checked={syncBookingWindows}
                            onChange={() => setSyncBookingWindows(!syncBookingWindows)}
                            className="dow-checkbox"
                            id="sync-cb"
                        />
                    </div>
                    <div className="details-input-group details-cohort">
                        <label>Cohort:</label>
                        <Select
                            value={selectedCohort}
                            onChange={(e) => setSelectedCohort(parseInt(e.target.value))}
                            options={cohortOptions}
                            customClass={"details-cohort-filter"}
                        />
                    </div>
                    <div className="details-input-group details-date">
                        <label>Date:</label>
                        <Select
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            options={dateOptions}
                            customClass={"details-date-filter"}
                        />
                    </div>
                    <div className="details-input-group details-btn">
                        <Button
                            variant="danger"
                            onClick={() => {
                                setSelectedCohort(null);
                                setSelectedDate("");
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
                <div className="details-input-row">
                    <div className="details-input-group details-cb">
                        <Tooltip
                            message="Uses selected cohort and date to display booking window and price group ranges (instead of 'BW0' and 'PG0', for example)">
                            <label>
                                Show Ranges
                                <Icon.AlertCircle className="pointer" height={15} width={15}/>
                            </label>
                        </Tooltip>
                        <Switch
                            key={'range-' + !!rangesSwitch ? 1 : 2}
                            checked={!!rangesSwitch}
                            onClick={() => setRangesSwitch(!rangesSwitch)}
                            disabled={!matrixByGroups}
                        />
                    </div>
                    <div className="details-input-group details-cb">
                        <Tooltip
                            message="'Preview' multiplies independent demand and starting influence to simulate impact of adjustment">
                            <label>
                                Preview
                                <Icon.AlertCircle className="pointer" height={15} width={15}/>
                            </label>
                        </Tooltip>
                        <Switch
                            key={'preview-' + !!previewSwitch ? 1 : 2}
                            checked={!!previewSwitch}
                            onClick={() => setPreviewSwitch(!previewSwitch)}
                            disabled={selectedMetric !== 'nested_revenue' && selectedMetric !== 'independent_demand'}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <div className="row pg-label">
                            Booking Window Groups (Days)
                        </div>
                        <div className="details-row">
                            <div className="details-col details-matrix-col">
                                <div className="influence-grid">
                                    <Tooltip
                                        message={`Booking Window ranges are in days. Lower boundaries are inclusive. Upper boundaries are non-inclusive. Influence percentages require approval beyond 30% (+/-). The lowest influence allowed is -${minInfluence}%. The largest is ${maxInfluence}%.`}>
                                        <div className="x1_1 hh">
                                            <Icon.AlertCircle className="pointer" height={20} width={20}/>
                                        </div>
                                    </Tooltip>
                                    <>
                                        {bookingWindows.map((bw, i) => <div
                                            className={`x1_${i + 2}`}>{bw.display}</div>)}
                                    </>
                                    <>
                                        {priceBuckets.map((pb, i) => <div
                                            className={`x${i + 2}_1`}>{pb.display}</div>)}
                                    </>
                                    <>
                                        {priceBuckets.map((pb, ipb) =>
                                            bookingWindows.map((bw, ibw) =>
                                                    <div className={`x${ipb + 2}_${ibw + 2}`}>
                                                        {
                                                            selectedMetric === "starting" &&
                                                            <div style={{fontSize: "12px"}}>
                                                                <Tooltip message={`This cell was selected ${matrixByGroups[ibw][ipb]["selected_price"]} / ${matrixByGroups[ibw][ipb]["total"]} times`}>
                                                                <input
                                                                    className={getInfluenceClassName(ipb, ibw)}
                                                                    type="number"
                                                                    value={startingInfluences[ibw][ipb]}
                                                                    onChange={(e) => updateInfluence(ibw, ipb, e.target.valueAsNumber)}
                                                                    style={getInfluenceStyle(ipb, ibw)}
                                                                />
                                                                </Tooltip>
                                                                %
                                                            </div>
                                                        }
                                                        {
                                                            selectedMetric === "target" &&
                                                            <div style={{fontSize: "12px"}}>
                                                                <Tooltip message={`This cell was selected ${matrixByGroups[ibw][ipb]["selected_price"]} / ${matrixByGroups[ibw][ipb]["total"]} times`}>
                                                                <input
                                                                    className={getInfluenceClassName(ipb, ibw)}
                                                                    type="number"
                                                                    value={targetInfluences[ibw][ipb]}
                                                                    onChange={(e) => updateInfluence(ibw, ipb, e.target.valueAsNumber)}
                                                                    style={getInfluenceStyle(ipb, ibw)}
                                                                />
                                                                </Tooltip>
                                                                %
                                                            </div>
                                                        }
                                                        {
                                                            ["starting", "target"].indexOf(selectedMetric) < 0 &&
                                                            <div>
                                                                <Tooltip message={`This cell was selected ${matrixByGroups[ibw][ipb]["selected_price"]} / ${matrixByGroups[ibw][ipb]["total"]} times`}>
                                                                <input
                                                                    type="number"
                                                                    className={getInfluenceClassName(ipb, ibw)}
                                                                    value={getValue(ipb, ibw)}
                                                                    disabled={true}
                                                                    style={getInfluenceStyle(ipb, ibw)}
                                                                />
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                            )
                                        )}
                                    </>
                                </div>
                            </div>
                            <div className="details-col details-radio-column">
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Starting Influence"}
                                    setter={setSelectedMetric}
                                    value={"starting"}
                                    disabled={false}
                                />
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Target Influence"}
                                    setter={setSelectedMetric}
                                    value={"target"}
                                    disabled={false}
                                />
                                <div><hr/></div>
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Independent Demand"}
                                    setter={setSelectedMetric}
                                    value={"independent_demand"}
                                    disabled={false}
                                />
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Nested Revenue (avg)"}
                                    setter={setSelectedMetric}
                                    value={"nested_revenue"}
                                    disabled={false}
                                />
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Bookings to Come"}
                                    setter={setSelectedMetric}
                                    value={"btc"}
                                    disabled={false}
                                />
                                <RadioButton
                                    checked={selectedMetric}
                                    label={"Selected"}
                                    setter={setSelectedMetric}
                                    value={"selected_price"}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </>}
            </div>
            <div className="form-footer">
                <span>
                    {alertRequiresApproval ? <p>{requiresApprovalMsg}</p> : <></>}
                </span>
                <div className={'form-footer-buttons'}>
                    {/*<ButtonGroup left={cancelButton} right={saveButton}/>*/}
                    <Button variant="info" onClick={closeModal} customClass='button-group'>
                        Cancel
                    </Button>
                    <Button variant="secondary" onClick={() => handleSave(-1)} disabled={isSaveDisabled}>
                        Previous
                    </Button>
                    <Button variant="primary" onClick={() => handleSave(1)} disabled={isSaveDisabled}>
                        Save
                        {isSaving &&
                            <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                        }
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};