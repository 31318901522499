import React, { KeyboardEvent, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { NumberInput } from '../../index';
import { Icon, Tooltip } from '@vacasa/react-components-lib';
import { Column, Row } from '../../../types/VirtualizedTable';
import { TableCellProps } from 'react-virtualized';

interface CellProps {
    column: Column;
    cellProps: TableCellProps;
    onRowChange: (dataKey: string, updatedRow: Row<any>) => void;
    onRepeat: (dataKey: string, value: any) => void;
    onKeyDown?: (event: KeyboardEvent, rowIndex: number, columnIndex: number) => void;
    onValidChange: (isValid: boolean) => void;
    disabled: boolean;
    originalValues: { [key: string]: any };
}

export const Cell = React.forwardRef<HTMLInputElement, CellProps>((props, ref) => {
    const { column, cellProps, onRowChange, onRepeat, onKeyDown, onValidChange, disabled, originalValues } = props;
    const { editable, fieldConfiguration, displayConfiguration } = column;
    const { dataKey, rowData, rowIndex } = cellProps;

    const [value, setValue] = useState(rowData[dataKey]);

    useEffect(() => {
        setValue(rowData[dataKey]);
    }, [rowData, dataKey]);

    if (!editable) {
        const customStyle = { justifyContent: displayConfiguration?.justifyContent, ...displayConfiguration?.customCssStyle };
        let e = React.createElement;
        const containerType = displayConfiguration?.containerType ?? 'span';
        const formattedValue = fieldConfiguration?.format?.(value) ?? value;

        return (
            <Tooltip message={containerType === 'div' ? formattedValue : ''}>
                <div>
                    {e(
                        containerType,
                        {
                            key: `${rowData.key}${dataKey}${rowIndex}`,
                            className: `${containerType === 'span' ? 'virtualized-table-cell' : 'virtualized-table-cell-div'} ${
                                formattedValue?.length > 20 ? formattedValue?.length > 50 ? 'very-decreased-font' : 'decreased-font' : ''
                            } ${displayConfiguration?.justifyContent === 'left' ? 'add-margin' : ''}`,
                            style: customStyle,
                        },
                        <>
                            {fieldConfiguration?.customLeftComponent?.(rowData)}
                            {formattedValue}
                            {fieldConfiguration?.customRightComponent?.(rowData)}
                        </>
                    )}
                </div>
            </Tooltip>
        );
    }

    const { inputConfiguration, copyable } = editable;
    const { minValue, maxValue, validation, decimals = true, placeholder = false } = inputConfiguration;
    const validationResult = { isValid: true, validationSummary: '' };

    if (!_.isUndefined(minValue) && value < minValue) {
        validationResult.isValid = false;
        validationResult.validationSummary = `${value} cannot be less than minValue ${minValue}`;
    }

    if (!_.isUndefined(maxValue) && value > maxValue) {
        validationResult.isValid = false;
        validationResult.validationSummary = `${value} cannot be greater than maxValue ${maxValue}`;
    }

    if (!_.isUndefined(validation)) {
        const result = validation(rowData);
        validationResult.isValid = result.isValid;
        validationResult.validationSummary = result.validationSummary;
    }
    onValidChange(validationResult.isValid);
    const errorClass = validationResult.isValid ? '' : 'error';

    const handleOnKeyDown = (event) => {
        onKeyDown(event, cellProps.rowIndex, cellProps.columnIndex);
    };

    return (
        <div className="virtualized-table-cell">
            <Tooltip message={validationResult.isValid ? '' : validationResult.validationSummary}>
                <div>
                    <NumberInput
                        key={`${rowData.key}${dataKey}${rowIndex}`}
                        className={errorClass}
                        placeholder={placeholder ? originalValues[dataKey] : undefined}
                        originalValue={originalValues[dataKey]}
                        value={value}
                        min={minValue ?? undefined}
                        max={maxValue ?? undefined}
                        onChange={(value) => {
                            const updatedRow = { ...rowData, [dataKey]: value };
                            onRowChange(dataKey, updatedRow);
                        }}
                        decimals={decimals}
                        onKeyDown={handleOnKeyDown}
                        ref={ref}
                        disable={disabled}
                    />
                </div>
            </Tooltip>
            {copyable && onRepeat && (
                <Icon.Repeat
                    className={(!value && placeholder) || disabled ? 'pointer-disabled' : 'pointer'}
                    height={18}
                    width={18}
                    onClick={() => onRepeat(dataKey, value)}
                />
            )}
        </div>
    );
});
