import { Expose } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';

export class FileDTO {
    @Expose()
    @IsString()
    filename: string;

    @Expose()
    @IsOptional()
    @IsString()
    content?: string;

    @Expose()
    @IsOptional()
    @IsString()
    hash?: string;

    @Expose()
    @IsOptional()
    @IsString()
    uploaded_file?: { bucket: string; key: string };
}
