import React from 'react';
import {Route, RouteProps, Switch} from 'react-router-dom';
import {Home} from './views';

export enum AppRoutes {
    HOME = '/',
}

const routes: RouteProps[] = [
    {path: AppRoutes.HOME, exact: true, component: Home}, // Tabs
];

export const Routes: React.FC = () => {
    return (
        <React.Fragment>
            <Switch>
                {routes.map((route, i) => (
                    <Route key={i} {...route} />
                ))}
            </Switch>
        </React.Fragment>
    );
};
