import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Cohort, Polygon, PolygonPoint} from "../../types";
import {Button, ButtonGroup, Icon} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {Checkbox} from '@material-ui/core';

import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import {Loading} from "../Common/Loading/Loading";
import {useUpdatePolygonMutation, useGetUnitsInPolygonMutation} from "../../store";


interface PolygonViewerProps {
    selectedCohort: Cohort;
    closeModal: (refresh?: boolean) => void;
    onUpdateCohort: (cohort: Cohort) => void;
    viewOnly: boolean;
}

type PolygonViewerComponent = (props: PolygonViewerProps) => ReactElement<any, any> | null;

export const PolygonViewer: PolygonViewerComponent = (props) => {
    const {
        selectedCohort,
        closeModal,
        onUpdateCohort,
        viewOnly,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const mapContainerRef = useRef();
    const mapRef = useRef();
    const [bounds, setBounds] = useState<[[number, number], [number, number]]>([[-120, 50], [-80, 25]]);
    const [vacasaChecked, setVacasaChecked] = useState<boolean>(true);
    const [industryChecked, setIndustryChecked] = useState<boolean>(false);
    const [satelliteChecked, setSatelliteChecked] = useState<boolean>(false);
    const [layer, setLayer] = useState<string>("mapbox://styles/mapbox/light-v11"); // mapbox://styles/mapbox/streets-v11");
    const [centerLatLng, setCenterLatLng] = useState<[number, number]>([-100.8038, 35.1749]);
    const [roundedArea, setRoundedArea] = useState<number>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");
    const [polygon, setPolygon] = useState<Polygon>();
    const [updatePolygon] = useUpdatePolygonMutation();
    const [unitsInPolygon] = useGetUnitsInPolygonMutation();
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isPasted, setIsPasted] = useState<boolean>(false);
    const [isLoadingPoints, setIsLoadingPoints] = useState<boolean>(false);
    const [vacasaPoints, setVacasaPoints] = useState<PolygonPoint[]>();
    const [industryPoints, setIndustryPoints] = useState<PolygonPoint[]>();

    // TODO: move to utils
    const average = (list: number[]) => list.reduce((prev, curr) => prev + curr) / list.length;

    const setPolygonFromClipboard = () => {
        navigator.clipboard.readText().then(s => {
            try {
                const j = JSON.parse(s);
                if (!!j && j.hasOwnProperty("coordinates")) {
                    setPolygon(JSON.parse(s));
                    setIsPasted(true);
                }
            }
            catch(e) {
                console.log(e)
            }
        });
    }

    useEffect(() => {
        if (satelliteChecked)
            setLayer("mapbox://styles/mapbox/satellite-v9");
        else
            setLayer("mapbox://styles/mapbox/light-v11")
            // setLayer("mapbox://styles/mapbox/streets-v11")

    }, [satelliteChecked]);

    const handleSave = async () => {
        // let coordinateString = polygon.coordinates.map(c => `[${c.join(",")}]`)
        const polygonString = JSON.stringify(polygon);

        setIsSaving(true);
        await updatePolygon({
            cohort_id: selectedCohort.id,
            polygon: polygonString,
            polygon_area: roundedArea
        }).then((response) => {
            if (response.hasOwnProperty("error")) {
                console.log(response["error"])
                const msg = response["error"]["data"].toString()
                alert(msg)
            } else {
                selectedCohort.polygon = polygon;
                selectedCohort.polygon_area = roundedArea;
                onUpdateCohort(selectedCohort)
                closeModal(true);
            }
        }).finally(() => {
            setIsSaving(false);
        })
    }

    const getUnitsInPolygon = async () => {
        setIsLoadingPoints(true);
        await unitsInPolygon(JSON.stringify(polygon)).then(response => {
            if (response.hasOwnProperty("error")) {
                console.log(response["error"])
                const msg = response["error"]["data"].toString()
                alert(msg)
            } else {
                setVacasaPoints(response["data"].filter(r => r.channel.toUpperCase() === 'VACASA'));
                setIndustryPoints(response["data"].filter(r => r.channel.toUpperCase() === 'VRBO'));
            }
        }).finally(() => {
            setIsLoadingPoints(false);
        })
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: true, // disableSave || isSaving || viewOnly,
        customClass: 'button-group'
    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "danger",
        customClass: 'button-group'
    }

    const copyPolygonButton: ButtonProps = {
        onClick: () => {
            navigator.clipboard.writeText(JSON.stringify(polygon));
            setIsCopied(true);
        },
        children: isCopied ? "Copied" : "Copy Polygon",
        variant: "info",
        customClass: 'button-group',
        disabled: isCopied,

    }

    const pastePolylgonButton: ButtonProps = {
        onClick: () => {setPolygonFromClipboard()},
        children: isPasted ? "Pasted" : "Paste Polygon",
        variant: "primary",
        customClass: 'button-group',
        disabled: isPasted,
    }

    useEffect(() => {
        if(!!selectedCohort && isLoading) {
            if (!!selectedCohort.polygon?.coordinates) {
                const coords = selectedCohort.polygon?.coordinates[0];
                if (coords) {
                    const lats: number[] = coords.map(c => c[0]);
                    const lngs: number[] = coords.map(c => c[1]);
                    setCenterLatLng([average(lats), average(lngs)]);
                    const newBounds: [[number, number], [number, number]] = [
                        [Math.min(...lats), Math.min(...lngs)],
                        [ Math.max(...lats), Math.max(...lngs)]
                    ];
                    setBounds(newBounds);
                }
                setPolygon(selectedCohort.polygon ?? {coordinates: [], type: "polygon"} as mapboxgl.Polygon)
            }
            setIsLoading(false);
        }
    }, [selectedCohort]);

    useEffect(() => {
        setIsCopied(false);
        mapboxgl.accessToken = "pk.eyJ1IjoiZXJpa2FrZXJ0IiwiYSI6ImNrYTc0NzlkaDBnZHcyd211bjlhbHpuZWMifQ.a99IpgBgpeNHDUzV7cTq4w";
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            },
            defaultMode: 'draw_polygon',
        });

        function updateArea(e) {
            const data = draw.getAll();
            let features = data.features;
            features = features.filter(f => f.geometry.type.toUpperCase() === "POLYGON")
            if (features.length > 1) {
                setValidationErrorMsg("Please remove duplicate polygons")
                setDisableSave(true);
            }
            else if (features.length === 1) {
                const area = turf.area(data);
                setRoundedArea(Math.round(area / 2_590_000));  // square meters to square miles
                setPolygon(data.features[0].geometry);
                setValidationErrorMsg("");
                setDisableSave(false);
            } else {
                setRoundedArea(null);
                if (e.type !== 'draw.delete') alert('Click the map to draw a polygon');
                setValidationErrorMsg("Please add a polygon");
                setDisableSave(true);
            }
        }

        let currentBounds = bounds;
        let currentCenter = centerLatLng;

        if (!!mapRef.current) {
            const currentMap: mapboxgl.Map = mapRef.current;
            currentBounds = currentMap.getBounds();
            setBounds(currentBounds);

            const center = currentMap.getCenter();
            currentCenter = [center["lng"], center["lat"]];
            setCenterLatLng(currentCenter);
        }

        if (!isLoading && currentCenter) {
            let newMap = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: layer,
                center: currentCenter,
                bounds: currentBounds,
                attributionControl: false,
                logo: false,
            });

            newMap.addControl(draw);

            newMap.on('draw.render', updateArea);
            newMap.on('draw.create', updateArea);
            newMap.on('draw.delete', updateArea);
            newMap.on('draw.update', updateArea);

            newMap.on('load', () => {
                draw.add({
                    id: "random-id-0",
                        type: "Feature",
                    properties: {},
                    geometry: polygon
                });

                if(!!vacasaPoints && vacasaChecked) {
                    vacasaPoints.forEach((p, idx) => {
                        draw.add({
                            id: `vacasa-id-${p.unit_id}`,
                            type: "Point",
                            coordinates: [p.lng, p.lat],
                        })
                    })
                }
                if (!!industryPoints &&industryChecked) {
                    industryPoints.forEach((p, idx) => {
                        draw.add({
                            id: `industry-id-${p.unit_id}`,
                            type: "Point",
                            coordinates: [p.lng, p.lat],
                        })
                    })
                }

                let features = [];

                if(!!vacasaPoints && vacasaChecked) {
                    vacasaPoints.forEach((p, idx) => {
                        features.push({
                            type: 'Feature',
                            properties: {
                                title: `${p.unit_code} (${p.unit_id})`,
                                icon: 'custom-marker',
                                size: 1.5,
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [p.lng, p.lat]
                            }
                        })
                    })
                }

                if (!!industryPoints && industryChecked) {
                    industryPoints.slice(0, 1000).forEach((p, idx) => {
                        features.push({
                            type: 'Feature',
                            properties: {
                                title: `VRBO (${p.unit_id})`,
                                icon: 'custom-marker',
                                size: 1,
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [p.lng, p.lat]
                            }
                        })
                    })
                }
                const points = {
                    type: 'FeatureCollection',
                    features: features
                };
                newMap.addSource('points', {
                    type: 'geojson',
                    data: points,
                });
                newMap.addLayer({
                    id: 'poi-labels',
                    type: 'symbol',
                    source: 'points',
                    layout: {
                        'text-field': ['get', 'title'],
                        'text-variable-anchor': ['top'],
                        'text-radial-offset': 0.5,
                        'text-justify': 'auto',
                        'text-size': 10,
                        'icon-image': ['get', 'icon'],
                        'icon-size': ['get', 'size']
                    },
                    paint: {
                        "text-color": satelliteChecked ? "#ffffff" : '#000000'
                    },
                });
            })

            mapRef.current = newMap;
        }
    }, [isLoading, mapRef, isPasted, layer, vacasaPoints, industryPoints, vacasaChecked, industryChecked]);

    return (
        <div style={{height: "550px", paddingTop: "50px"}}>
            <div className="form-header" style={{display: "flex", justifyContent: "space-between"}}>
                <h4>Polygon Viewer</h4>
                <Button
                    disabled={isLoadingPoints}
                    onClick={getUnitsInPolygon}
                    customClass={"pointer"}
                    variant="secondary"
                >
                    {isLoadingPoints ?
                        <Icon.Loader height={24} width={24}/> :
                        <Icon.MapPin height={24} width={24}/>
                    }
                    Get Points in Polygon
                </Button>
                <div>
                    <Checkbox
                        checked={vacasaChecked}
                        onChange={() => setVacasaChecked(!vacasaChecked)}
                        id="vac-cb"
                        disabled={!vacasaPoints}
                    />
                    <label htmlFor="vac-cb">Vacasa</label>
                </div>
                <div>
                    <Checkbox
                        checked={industryChecked}
                        onChange={() => setIndustryChecked(!industryChecked)}
                        id="ind-cb"
                        disabled={!industryPoints}
                    />
                    <label htmlFor="ind-cb">Industry</label>
                </div>
                <div>
                    <Checkbox
                        checked={satelliteChecked}
                        onChange={() => setSatelliteChecked(!satelliteChecked)}
                        id="sat-cb"
                    />
                    <label htmlFor="sat-cb">Satellite</label>
                </div>
            </div>
            <div ref={mapContainerRef} id="map" style={{height: '100%'}}></div>
            {isLoading ? <Loading/> :
                <div
                    className="calculation-box"
                    style={{
                        height: 75,
                        width: 250,
                        position: 'absolute',
                        bottom: 70,
                        left: 10,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: 15,
                        textAlign: 'center'
                    }}
                >
                    <p>Click the map to draw a polygon.</p>
                    <div id="calculated-area">
                        {roundedArea && (
                            <>
                                <p><strong>{roundedArea}</strong></p>
                                <p>square miles</p>
                            </>
                        )}
                    </div>
                </div>
            }
            <div className="form-footer" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div className="col">
                    {validationErrorMsg}
                </div>
                <div className="col">
                    {!!vacasaPoints && `${vacasaPoints.length} Vacasa Units`}
                    <br/>
                    {!!industryPoints && `${industryPoints.length} Industry Units`}
                </div>
                <div className="form-footer-buttons">
                    <ButtonGroup left={copyPolygonButton} right={pastePolylgonButton}/>
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>

            </div>
        </div>
    );
};
