import React, {useEffect, useState} from 'react';
import {Loading} from '../index';
import './CohortAssignmentModal.scss';
import {SelectOption} from "../../types";
import {useAddCohortMutation, useGetAnalystsQuery, useGetManagersQuery} from "../../store";
import {ButtonGroup, Icon, Input, Select} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {RAEmployee} from "../../types/RAEmployee";


interface NewCohortModalProps {
    closeModal: (refresh?: boolean) => void;
    cohortNames: string[];
    viewOnly: boolean;
}

export const NewCohortModal: React.FC<NewCohortModalProps> = (props) => {
    const {closeModal, cohortNames, viewOnly} = props

    const {data: analysts, isFetching: isFetchingAnalysts} = useGetAnalystsQuery();
    const {data: managers, isFetching: isFetchingManagers} = useGetManagersQuery();

    const [cohortString, setCohortString] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [amenity, setAmenity] = useState<string>("");
    const [size, setSize] = useState<string>("");
    const [validationMessage, setValidationMessage] = useState<string>("");

    const [analyst, setAnalyst] = useState<string>("");
    const [analystOptions, setAnalystOptions] = useState<SelectOption[]>([]);
    const [manager, setManager] = useState<string>("");
    const [managerOptions, setManagerOptions] = useState<SelectOption[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const [addCohort] = useAddCohortMutation();

    const handleSave = async () => {
        setIsSaving(true);
        await addCohort(
            {
                name: cohortString,
                state: state,
                region: region,
                specific_location: location,
                amenity_details: amenity,
                size_details: size,
                analyst: analyst,
                manager: manager
            }).then((response) => {
            if (response.hasOwnProperty("error")) {
                console.log(response["error"])
                const msg = response["error"]["data"].toString()
                alert(msg)
            } else {
                closeModal(true);
            }
        }).finally(() => {
            setIsSaving(false);
        });
    }

    useEffect(() => {
        if (!isFetchingAnalysts && !!analysts && !isFetchingManagers && !!managers) {
            let analystOptions: SelectOption[] = [];
            analysts.forEach((analyst: RAEmployee) => {
                analystOptions.push({
                    value: analyst.name,
                    display: analyst.name
                });
            })
            analystOptions.sort((a, b) => a.display.localeCompare(b.display));
            setAnalystOptions(analystOptions);

            let managerOptions: SelectOption[] = [];
            managers.forEach((manager: RAEmployee) => {
                managerOptions.push({
                    value: manager.name,
                    display: manager.name
                });
            })
            managerOptions.sort((a, b) => a.display.localeCompare(b.display));
            setManagerOptions(managerOptions);
            setIsLoading(false);
        }
    }, [analysts, isFetchingAnalysts, managers, isFetchingManagers])

    useEffect(() => {
        const cs = `${state}_${region}_${location}_${amenity}_${size}`;
        setCohortString(cs);

        let invalid = "";

        if(!state) invalid = "Please provide a state"
        else if(!region) invalid = "Please provide a region"
        else if(!location) invalid = "Please provide a location"
        else if(!amenity) invalid = "Please provide a amenity"
        else if(!size) invalid = "Please provide a size"
        else if(!manager) invalid = "Please provide a manager"
        else if(!analyst) invalid = "Please provide a analyst"
        else if (cohortNames.indexOf(cs.toUpperCase()) >= 0) invalid = "Cohort name is not unique"

        setValidationMessage(invalid);
        setIsSaveDisabled(!!invalid);
    }, [state, region, location, amenity, size, manager, analyst]);

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: isSaveDisabled || !!viewOnly,
        customClass: 'button-group'
    }

    return (
        <div>
            <div className="form-header">
                <h5>New Cohort</h5>
            </div>
            <div className="form-body">
                {isLoading
                    ? <Loading className=""/>
                    :
                    <div>
                        <br/>
                        <div className="ua-input-group">
                            <label htmlFor="cs">New CohortName:</label>
                            <p id="cs">{cohortString}</p>
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">State:</label>
                            <Input
                                type={"text"}
                                customClass={"number-input"}
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Region:</label>
                            <Input
                                type={"text"}
                                customClass={"number-input"}
                                value={region}
                                onChange={(e) => {
                                    setRegion(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Location:</label>
                            <Input
                                type={"text"}
                                customClass={"number-input"}
                                value={location}
                                onChange={(e) => {
                                    setLocation(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Amenity:</label>
                            <Input
                                type={"text"}
                                customClass={"number-input"}
                                value={amenity}
                                onChange={(e) => {
                                    setAmenity(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Bedrooms Min:</label>
                            <Input
                                type={"text"}
                                customClass={"number-input"}
                                value={size}
                                onChange={(e) => {
                                    setSize(e.target.value)
                                }}
                                placeholder={"0-1, 3+, ALL"}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Analyst:</label>
                            <Select
                                customClass="number-input"
                                value={analyst}
                                options={analystOptions}
                                onChange={(e) => {
                                    setAnalyst(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="manager">Manager:</label>
                            <Select
                                customClass="number-input"
                                value={manager}
                                options={managerOptions}
                                onChange={(e) => {
                                    setManager(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <br/>
                    </div>
                }
            </div>
            <div className="form-footer">
                {validationMessage}
                <div className={'form-footer-buttons'}>
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </div>
    );
};
