import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './DemandInfluenceAccordion.scss';
import {TableRowProps} from "react-virtualized";
import {DemandExtraData} from "../../../types";


interface DIAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}

export const DemandInfluenceAccordion: React.FC<DIAccordionProps> = (props) => {

    const {tableProps, expanded, handleChange} = props;
    const [extraData, setExtraData] = useState<DemandExtraData>();

    const styles = {
        ...tableProps.style
    };

    useEffect(() => {
        const extraDetailsFromData: DemandExtraData = tableProps.rowData.extra_data;
        setExtraData(extraDetailsFromData);
    }, [tableProps.rowData.extra_data]);

    return (
        <div key={tableProps.rowData.id} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.id} expanded={expanded === tableProps.rowData.id}
                          onChange={handleChange(tableProps.rowData.id, tableProps.index)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                    {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th className={"email-col"}>Analyst(s)</th>
                            <th className={"email-col"}>Manager(s)</th>
                            <th className={"type-col"}>Type</th>
                            <th className={"email-col"}>Email</th>
                            <th className={"email-col"}>Approver</th>
                            <th className={"desc-col"}>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!extraData ?
                            <tr className={"table-body"}>
                                <td>{extraData.analysts}</td>
                                <td>{extraData.managers}</td>
                                <td>{extraData.demand_type}</td>
                                <td>{extraData.email}</td>
                                <td>{extraData.approver}</td>
                                <td className={
                                    extraData.description.length > 40 ?
                                        extraData.description.length > 60 ?
                                        'very-decreased-font'
                                        :
                                        'decreased-font'
                                        :
                                        ''
                                }>{extraData.description}</td>
                            </tr>

                            :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
