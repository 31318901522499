import React, { useState } from 'react';
import { Button, Icon } from '@vacasa/react-components-lib';
import './BulkSidePanel.scss';
import * as _ from 'lodash';
import { BulkLoadDTO, BulkOptionType, BulkUploadOptions, FileDTO, Message } from '../../types';
import { useFilePicker } from 'use-file-picker';
import * as crypto from 'crypto';
import { UiUtils } from '../../utils';
import {useBulkCSVUploadMutation} from "../../store";

type BulkSidePanelProps = {
    onClose: () => void;
    uploadType: BulkOptionType
    onSave: (message: Message, data?: any) => void;
};

export const BulkSidePanel: React.FC<BulkSidePanelProps> = (props) => {
    const { onClose, onSave, uploadType } = props;
    const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);
    const title = uploadType === BulkUploadOptions.OVERRIDES ? "Bulk Rate Override" : "Upload Tethers";
    const templateName = uploadType === BulkUploadOptions.OVERRIDES ? "unit_date_override_template.csv" : "unit_tether_template.csv";
    const templatePath = process.env.PUBLIC_URL + '/' + templateName;

    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        multiple: false,
        readAs: 'Text',
        accept: ['.csv'],
        limitFilesConfig: { max: 1 },
        maxFileSize: 9.5,
    });

    const [bulkUpload] = useBulkCSVUploadMutation();

    const data_content: { lastModified: number; name: string; content: string } = _.head(filesContent);

    const handleSave = async (data_content: { lastModified: number; name: string; content: string }) => {
        const file: FileDTO = {
            filename: data_content.name,
            content: data_content.content,
            hash: crypto.createHash('md5', {}).update(data_content.content).digest('hex').toLowerCase(),
        };
        let alertMessage: Message;

        const data: BulkLoadDTO = {
            file: file,
        };

        if (_.isEmpty(file.content)) {
            alertMessage = UiUtils.getBulkEmptyFileMessage();
            onSave(alertMessage);
            return;
        }

        if (!UiUtils.isValidCSVLength(file.content.split('\n'))) {
            alertMessage = UiUtils.getBulkFileExceededMessage();
            onSave(alertMessage);
            return;
        }

        if (uploadType === BulkUploadOptions.TETHERS) {
            onSave(UiUtils.getBulkSuccessMessage(), file.content.split('\n'));
        }
        else if (uploadType === BulkUploadOptions.OVERRIDES) {
            await bulkUpload({data: data}).then((response) => {
                setIsAlreadyClicked(false);
                if (response.hasOwnProperty("error")) {
                    console.log(response["error"])
                    const msg = response["error"]["data"].toString()
                    alert(msg)
                } else {
                    onSave(UiUtils.getBulkSuccessMessage());
                }
            });
        }
    };

    return (
        <div className="bulk-panel">
            <div className="bulk-panel-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{title}</span>
            </div>
            <div className="bulk-panel-step">
                <label>Download Template:</label>
                <a
                    rel="noopener noreferrer"
                    href={templatePath}
                    download={templateName}
                >
                    <button
                        style={{padding: "4px", width: "180px", marginRight: "10px", height: "45px"}}
                        className="pointer"
                    >
                        <Icon.Download height={20} width={20}/>
                        <label>CSV Template</label>
                    </button>
                </a>
            </div>
            <div className="step-separator" />
            <div className="bulk-panel-step">
                <label>Upload File:</label>
                <div style={{height: "50px"}}>
                    {_.isEmpty(filesContent) ? (
                        <button
                            style={{padding: "4px", width: "180px", marginRight: "10px", height: "45px"}}
                            className="pointer"
                            onClick={() => {
                                openFileSelector();
                            }}
                        >
                            <Icon.Monitor height={20} width={20}/>
                            <label>Your Computer</label>
                        </button>
                        ) : (
                        <div style={{display: "flex", width: "315px"}}>
                        <input
                            style={{width: "275px", display: "flex"}}
                            type="string"
                            value={data_content.name}
                            disabled={true}
                        />
                        <div style={{paddingTop: "3px", display: "flex"}}>
                            <Icon.XCircleInverse height={16} width={16} onClick={clear} />
                        </div>
                        </div>
                    )
                    }
                </div>
            </div>
            <br/>
            <div style={{display: "flex", justifyContent: "flex-end", margin: "0 10px 10px 0"}}>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setIsAlreadyClicked(true);
                        handleSave(data_content).then((r) => r);
                    }}
                    disabled={isAlreadyClicked || _.isEmpty(filesContent)}>
                    {isAlreadyClicked &&
                        <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                    }
                    Save
                </Button>
            </div>
        </div>
    );
};
