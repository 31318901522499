export type SelectOption = { value: number | string; display: string };
export enum DemandInfluenceFormOptions {
    ADD = "ADD",
    COPY = "COPY",
    EDIT = "EDIT",
    APPROVE = "APPROVE",
}
export type DemandInfluenceFormType =
    DemandInfluenceFormOptions.ADD |
    DemandInfluenceFormOptions.EDIT |
    DemandInfluenceFormOptions.COPY |
    DemandInfluenceFormOptions.APPROVE;

export type Message = { type: 'success' | 'warning' | 'error' | 'info'; content: string };

export enum BulkUploadOptions {
    OVERRIDES = "OVERRIDES",
    TETHERS = "TETHERS",
}

export type BulkOptionType =
    BulkUploadOptions.OVERRIDES |
    BulkUploadOptions.TETHERS;
