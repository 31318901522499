import React, {useState} from "react";
import { Icon } from '@vacasa/react-components-lib';

import {useGetMissingRatesReasonMutation} from "../../store";
import {MissingRatesReason} from "../../types";


export const MissingRates:  React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryMissingRates] = useGetMissingRatesReasonMutation();
    const [unitInfo, setUnitInfo] = useState<MissingRatesReason>();

    const getMissingRatesReason = () => {
        setIsLoading(true);
        queryMissingRates(unitId).then((response) => {
            console.log(response)
            if (response.hasOwnProperty("error")) {
                const msg = `Status Code ${response["error"]["status"].toString()}`;
                alert(msg);
            } else {
                setUnitInfo(response["data"][0]);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const [unitId, setUnitId] = useState<number>();

    return (
        <div>
            <div style={{display: "flex", paddingBottom: "10px"}}>
                <label>Unit ID:&nbsp;&nbsp;&nbsp;</label>
                <input type="number" value={unitId} onChange={(e) => setUnitId(e.target.valueAsNumber)}/>
                <button
                    style={{marginLeft: "15px"}}
                    disabled={!unitId || isLoading}
                    onClick={getMissingRatesReason}
                >
                    {isLoading && <Icon.Loader className={"spinning-icon"} height={20} width={20}/>}
                    Check Unit
                </button>
            </div>
            <div className="row">
                {!!unitInfo &&
                    <table className={"missing-rates-table"}>
                    <thead>
                    <tr>
                        <th style={{width: "130px", textAlign: "left"}}></th>
                        <th style={{width: "250px", textAlign: "left"}} ></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Issue</td>
                        <td>{
                            unitInfo.unit_issue ?? <><Icon.CheckSquare height={20} width={20}/> None</>
                        }</td>
                    </tr>
                    <tr>
                        <td>Active</td>
                        <td>{
                            unitInfo.has_active_flag
                                ? <Icon.CheckSquare height={20} width={20}/>
                                : <Icon.MinusCircle height={20} width={20}/>
                        }</td>
                    </tr>
                    <tr>
                        <td>Live</td>
                        <td>{
                            unitInfo.has_display_flag
                                ? <Icon.CheckSquare height={20} width={20}/>
                                : <Icon.MinusCircle height={20} width={20}/>
                        }</td>
                    </tr>
                    <tr>
                        <td>Model</td>
                        <td>{unitInfo.model_name}</td>
                    </tr>
                    <tr>
                        <td>Rate Count</td>
                        <td>{
                            unitInfo.model_name?.toUpperCase() === "NORTHSTAR"
                                ? unitInfo.ns_valid_dates_count
                                : unitInfo.model_name?.toUpperCase() === "ALAN"
                                ? unitInfo.alan_valid_dates_count
                                : "No Rates"
                        }</td>
                    </tr>
                    <tr>
                        <td>Super Unit</td>
                        <td>{
                            unitInfo.super_unit
                                ? <Icon.CheckSquare height={20} width={20}/>
                                : <Icon.MinusCircle height={20} width={20}/>
                        }</td>
                    </tr>
                    <tr>
                        <td>Sub Unit</td>
                        <td>{
                            unitInfo.is_sub_unit
                                ? <Icon.CheckSquare height={20} width={20}/>
                                : <Icon.MinusCircle height={20} width={20}/>
                        }</td>
                    </tr>
                    <tr>
                        <td>Lat/Lng</td>
                        <td>{
                            !!unitInfo.unit_latitude && !!unitInfo.unit_longitude
                            ? `(${unitInfo.unit_longitude.toString()}, ${unitInfo.unit_latitude.toString()})`
                            : "Not set"
                        }</td>
                    </tr>
                    </tbody>
                </table>}
            </div>
        </div>
    )
}