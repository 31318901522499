import {Rate} from "../types";
import {apiRate} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


const rateAdapter = createEntityAdapter<Rate>({
    selectId: (rate) => rate.date,
    sortComparer: (a, b) => a.date - b.date
});

const initialState: EntityState<Rate> = rateAdapter.getInitialState();

export const rateApi = apiRate.injectEndpoints({
    endpoints: (builder) => ({
        getRates: builder.query<Rate[], { unit_id: string, cohort_ids: string }>({
            query: ({unit_id, cohort_ids}) => `rates?unit_id=${unit_id}&cohort_ids=${cohort_ids}`,
            providesTags: [{type: "Rates", id: "RateList"}]
        }),
    }),
    overrideExisting: false,
});

export const rateSlice = createSlice({
    name: "rates",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<Rate[]>) => {
            rateAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        // builder.addMatcher(
        //     rateApi.endpoints.getAllUnits.matchFulfilled, (state, {payload}) => {
        //     rateAdapter.upsertMany(state, payload);
        // })
    }
});

export const {
    useGetRatesQuery,
} = rateApi;

export const rateSelector = (state: AppState) => state.rates;

export default rateSlice.reducer;