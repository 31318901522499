import React, {useEffect, useState} from 'react';
import {Loading} from '../index';
import './Tables.scss';
import {DemandInfluenceFormOptions, DemandInfluenceFormType, Holiday} from "../../types";
import {ButtonGroup, Icon, Input, Select} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {useAddHolidayMutation, useUpdateHolidayMutation} from "../../store";
import {UiUtils} from "../../utils";


interface HolidaysModalProps {
    selectedHoliday: Holiday;
    formType: DemandInfluenceFormType;
    closeModal: (refresh?: boolean, newHoliday?: boolean) => void;
    allHolidays: Holiday[];
    isFetchingHolidays: boolean;
}

export const HolidayModal: React.FC<HolidaysModalProps> = (props) => {
    const {
        selectedHoliday,
        formType,
        closeModal,
        allHolidays,
        isFetchingHolidays
    } = props

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");

    // Form fields
    const [holidayName, setHolidayName] = useState<string>("");
    const [holidayCategory, setHolidayCategory] = useState<string>("Holiday");
    const [holidayDescription, setHolidayDescription] = useState<string>("");
    const categories = ["Holiday", "Event"].map(k => UiUtils.getSelectOption(k));

    const [addHoliday] = useAddHolidayMutation();
    const [updateHoliday] = useUpdateHolidayMutation();

    const handleSave = async () => {
        setIsSaving(true);
        let updatedHoliday: Partial<Holiday> = {
            name: holidayName,
            category: holidayCategory,
            description: holidayDescription,
        }

        if (formType === DemandInfluenceFormOptions.EDIT) {
            await updateHoliday({
                holiday_id: selectedHoliday.id,
                data: updatedHoliday
            }).then((response) => {
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                setIsSaving(false);
                closeModal(true, false);
            })
        }
        else { // ADD and COPY
            await addHoliday(updatedHoliday).then((response) => {
                setIsSaving(false);
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true, true);
                }
            })
        }
    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: disableSave || isSaving,
        customClass: 'button-group'
    }

    useEffect(() => {
        if (isFetchingHolidays) return;

        if (!!selectedHoliday) {
            setHolidayName(selectedHoliday.name);
            setHolidayCategory(selectedHoliday.category);
            setHolidayDescription(selectedHoliday.description);
        }
        setIsLoading(false);
    }, [allHolidays, isFetchingHolidays]);

    useEffect(() => {
        if (isFetchingHolidays) return;

        let errorMessages: string[] = [];
        let warningMessages: string[] = [];

        if (!holidayName) {
            errorMessages.push("Name is required");
        }
        else {
            const nameLowerCase = holidayName.toLowerCase()
            allHolidays.forEach(h => {
                if (formType === DemandInfluenceFormOptions.EDIT && h.id === selectedHoliday.id) {
                    return;
                }
                const hLower = h.name.toLowerCase();
                if (hLower === nameLowerCase) {
                    errorMessages.push("Name must be unique")
                }
                else if(hLower.includes(nameLowerCase) || nameLowerCase.includes(hLower)) {
                    warningMessages.push("Name seems similar to another holiday")
                }
            })
        }
        errorMessages = Array.from(new Set(errorMessages));
        warningMessages = Array.from(new Set(warningMessages));

        if (!holidayCategory) {
            errorMessages.push("Category is required");
        }

        if (errorMessages.length > 0) {
            setValidationErrorMsg(`${errorMessages.length} error(s); ${errorMessages[0]} `);
        }
        else if (warningMessages.length > 0) {
            setValidationErrorMsg(`${warningMessages.length} warning(s); ${warningMessages[0]} `);
        }
        else {setValidationErrorMsg("")}

        setDisableSave(errorMessages.length > 0);

    }, [holidayName, holidayCategory, isFetchingHolidays]);

    return (
        <div>
            <div className="ut-form-header">
                <h4>{`${formType === DemandInfluenceFormOptions.ADD ? "New" : "Edit"} Holiday`}</h4>
            </div>
            <div className="ut-form-body table-body">
                {isLoading || isFetchingHolidays
                    ? <Loading className="table-modal-loading"/>
                    : <div>
                        <div className="row">
                            <label>Name:</label>
                        </div>
                        <div className="row tether-table-body">
                            <Input
                                customClass="title-input"
                                type="text"
                                value={holidayName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHolidayName(e.target.value)}
                            />
                        </div>
                        <br/>
                        <div className="row">
                            <label>Category:</label>
                        </div>
                        <div className="row tether-table-body">
                            <Select
                                customClass="text-input"
                                value={holidayCategory}
                                options={categories}
                                onChange={(e) => {
                                    setHolidayCategory(e.target.value)
                                }}
                            />
                        </div>
                        <br/>
                        <div className="row">
                            <label>Description:</label>
                        </div>
                        <div className="row">
                            <textarea
                                rows={3}
                                maxLength={255}
                                value={holidayDescription}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setHolidayDescription(e.target.value)}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className="ut-form-footer" style={{height: "30px"}}>
                <span>
                    {validationErrorMsg}
                </span>
                <div className="form-footer-buttons">
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </div>
    );
};
