import React, {useState, useMemo, useEffect} from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  CircularProgress,
  IconButton,
  TablePagination,
  Checkbox
} from '@material-ui/core';
import {Icon} from '@vacasa/react-components-lib';

import { useGetPromotionOptionsQuery, useCreatePromotionOptionMutation, useUpdatePromotionOptionMutation } from '../../store';
import {PromotionOption} from "../../store";

type SortDirection = 'asc' | 'desc';
type OptionTable = 'Strategies' | 'Types' | 'Usage Types';

interface PromoOptionsProps {
  // Add props as needed
}

interface EditingState {
  name: string;
  active: boolean;
  vacasa: boolean;
  vrbo: boolean;
  airbnb: boolean;
  bdc: boolean;
  mybookingpal: boolean;
  expedia: boolean;
}

const PromoOptions: React.FC<PromoOptionsProps> = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<keyof PromotionOption>('id');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [selectedOptionTable, setSelectedOptionTable] = useState<OptionTable>('Strategies');
  const [promotions, setPromotions] = useState<PromotionOption[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingPromotions, setEditingPromotions] = useState<{ [key: number]: boolean }>({});
  const [editingStates, setEditingStates] = useState<{ [key: number]: EditingState }>({});
  const [savingRows, setSavingRows] = useState<{ [key: number]: boolean }>({});
  const [isRefetching, setIsRefetching] = useState(false);

  const { data: promotionOptions, isLoading, refetch } = useGetPromotionOptionsQuery();
  const [createPromotionOption] = useCreatePromotionOptionMutation();
  const [updatePromotionOption] = useUpdatePromotionOptionMutation();

  const handleSort = (column: keyof PromotionOption) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedData = useMemo(() => {
    return promotions?.filter(promo =>
        promo.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        promo.id.toString().includes(searchQuery)
      )
      .sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (sortDirection === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
      });
  }, [promotions, searchQuery, sortBy, sortDirection]);

  useEffect(() => {
    if (!isLoading && !!promotionOptions) {
      const ut = selectedOptionTable === "Usage Types" ? "usage_types" : selectedOptionTable;
      setPromotions(promotionOptions[ut.toLowerCase()])
    }
  }, [selectedOptionTable, promotionOptions, isLoading]);

  const handleRefresh = async () => {
    setIsRefetching(true);
    refetch();
    // Users like to see buttons do something
    setTimeout(() => {
      setIsRefetching(false);
    }, 750)

    console.log("done refreshing");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    if (!filteredAndSortedData) return [];
    const startIndex = page * rowsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredAndSortedData, page, rowsPerPage]);

  const handleEdit = (promotion: PromotionOption) => {
    setEditingPromotions(prev => ({
      ...prev,
      [promotion.id]:  prev.hasOwnProperty(promotion.id) ? !prev[promotion.id] : true
    }));
    
    // Initialize editing state with current values
    setEditingStates(prev => ({
      ...prev,
      [promotion.id]: {
        name: promotion.name,
        active: promotion.active,
        vacasa: promotion.vacasa,
        vrbo: promotion.vrbo,
        airbnb: promotion.airbnb,
        bdc: promotion.bdc,
        mybookingpal: promotion.mybookingpal,
        expedia: promotion.expedia,
      }
    }));
  };

  const handleSave = async (promotion: PromotionOption) => {
    try {
      setSavingRows(prev => ({
        ...prev,
        [promotion.id]: true
      }));

      const editingState = editingStates[promotion.id];
      const ot = selectedOptionTable === "Usage Types" ? "usage_types" : selectedOptionTable;
      const updatedPromotion = {
        ...promotion,
        ...editingState,
        option_type: ot,
      };
      await updatePromotionOption(updatedPromotion);
      
      // Clear editing states
      setEditingPromotions(prev => ({
        ...prev,
        [promotion.id]: false
      }));
      setEditingStates(prev => {
        const newState = { ...prev };
        delete newState[promotion.id];
        return newState;
      });

      // Refetch data to update the table
      refetch();
    } catch (error) {
      console.error('Failed to update promotion:', error);
    } finally {
      setSavingRows(prev => ({
        ...prev,
        [promotion.id]: false
      }));
      setIsRefetching(false);
    }
  };

  const handleCancel = (promotionId: number) => {
    setEditingPromotions(prev => ({
      ...prev,
      [promotionId]: false
    }));
    // Clear editing state
    setEditingStates(prev => {
      const newState = { ...prev };
      delete newState[promotionId];
      return newState;
    });
  };

  const handleToggle = (promotion: PromotionOption, field: keyof Omit<PromotionOption, 'id' | 'name'>) => {
    setEditingStates(prev => ({
      ...prev,
      [promotion.id]: {
        ...prev[promotion.id],
        [field]: !prev[promotion.id][field]
      }
    }));
  };

  const handleAddPromotion = async () => {
    const ot = selectedOptionTable === "Usage Types" ? "usage_types" : selectedOptionTable;
    let newPromotion: Omit<PromotionOption, 'id'> = {
      name: 'New ' + selectedOptionTable,
      option_type: ot,
    };
    if (selectedOptionTable !== "Usage Types") {
      channelColumns.map(cc => cc.key).forEach(cc => {
        newPromotion[cc] = false;
      })
    }

    try {
      setIsRefetching(true);
      const ot = selectedOptionTable === "Usage Types" ? "usage_types" : selectedOptionTable;
      const response = await createPromotionOption({
        ...newPromotion,
        option_type: ot,
      }).unwrap();

      refetch();

      // FIXME: This isn't working
      // Put the new row in edit mode
      handleEdit(response);
      
      // Scroll to the top of the table where the new row will appear
      const tableContainer = document.querySelector('.MuiTableContainer-root');
      if (tableContainer) {
        tableContainer.scrollTop = 0;
      }
      
      // Change sort/filter to show new row at the top
      setSortBy('id');
      setSortDirection('desc');
      setSearchQuery('');
      setPage(0);
    } catch (error) {
      console.error('Failed to create promotion:', error);
    } finally {
      setIsRefetching(false);
    }
  };

  const isUsageType = selectedOptionTable === 'Usage Types';

  const channelColumns = [
    { key: 'vacasa', label: 'Vacasa' },
    { key: 'vrbo', label: 'VRBO' },
    { key: 'airbnb', label: 'AirBNB' },
    { key: 'bdc', label: 'BDC', tooltip: 'Booking.com' },
    { key: 'mybookingpal', label: 'MBP', tooltip: 'MyBookingPal' },
    { key: 'expedia', label: 'Expedia' }
  ];

  return (
    <Paper>
      <Box style={{ display: 'flex', gap: '16px', marginBottom: '16px', alignItems: 'center' }}>
        <FormControl variant="outlined" size="small">
          <InputLabel>Options Table</InputLabel>
          <Select
            value={selectedOptionTable}
            onChange={(e) => setSelectedOptionTable(e.target.value as OptionTable)}
            label="Options Table"
            style={{ minWidth: '200px' }}
          >
            <MenuItem value="Strategies">Strategies</MenuItem>
            <MenuItem value="Types">Types</MenuItem>
            <MenuItem value="Usage Types">Usage Types</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Search Promotions"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Add Promotion">
            <IconButton
              onClick={handleAddPromotion}
              size="small"
            >
              <Icon.Plus height={20} width={20}/>
            </IconButton>
          </Tooltip>

          <Tooltip title={isLoading ? "Loading..." : "Refresh data"}>
            <IconButton 
              onClick={handleRefresh}
              disabled={isLoading}
              size="small"
            >
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon.RefreshCCW height={20} width={20}/>
              )}
            </IconButton>
          </Tooltip>
        </div>
      </Box>

      {isLoading && !!promotions ? (
        <Box style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer style={{ height: '500px', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'id'}
                      direction={sortBy === 'id' ? sortDirection : 'asc'}
                      onClick={() => handleSort('id')}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'name'}
                      direction={sortBy === 'name' ? sortDirection : 'asc'}
                      onClick={() => handleSort('name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'active'}
                      direction={sortBy === 'active' ? sortDirection : 'asc'}
                      onClick={() => handleSort('active')}
                    >
                      Active
                    </TableSortLabel>
                  </TableCell>
                  {!isUsageType && channelColumns.map(column => (
                    <TableCell key={column.key}>
                      {column.tooltip ? (
                        <Tooltip title={column.tooltip} placement="top">
                          <TableSortLabel
                            active={sortBy === column.key}
                            direction={sortBy === column.key ? sortDirection : 'asc'}
                            onClick={() => handleSort(column.key as keyof PromotionOption)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        <TableSortLabel
                          active={sortBy === column.key}
                          direction={sortBy === column.key ? sortDirection : 'asc'}
                          onClick={() => handleSort(column.key as keyof PromotionOption)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((promo) => (
                  <TableRow key={promo.id}>
                    <TableCell>{promo.id}</TableCell>
                    <TableCell>
                      {editingPromotions[promo.id] ? (
                        <TextField
                          value={editingStates[promo.id]?.name ?? promo.name}
                          onChange={(e) => {
                            setEditingStates(prev => ({
                              ...prev,
                              [promo.id]: {
                                ...prev[promo.id],
                                name: e.target.value
                              }
                            }));
                          }}
                          onBlur={(e) => {
                            setEditingStates(prev => ({
                              ...prev,
                              [promo.id]: {
                                ...prev[promo.id],
                                name: e.target.value
                              }
                            }));
                            handleSave(promo);
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      ) : (
                        promo.name
                      )}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={editingPromotions[promo.id] ? editingStates[promo.id]?.active : promo.active}
                        onChange={() => handleToggle(promo, 'active')}
                        disabled={!editingPromotions[promo.id]}
                      />
                    </TableCell>
                    {!isUsageType && channelColumns.map(column => (
                      <TableCell key={column.key}>
                        <Checkbox
                          checked={editingPromotions[promo.id] ? !!editingStates[promo.id]?.[column.key as keyof EditingState] : !!promo[column.key as keyof PromotionOption]}
                          onChange={() => handleToggle(promo, column.key as keyof Omit<PromotionOption, 'id' | 'name'>)}
                          disabled={!editingPromotions[promo.id]}
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      {editingPromotions[promo.id] ? (
                        <>
                          <Tooltip title="Save">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setIsRefetching(true);
                                handleSave(promo).then(() => setIsRefetching(false));
                              }}
                              disabled={savingRows[promo.id] || isRefetching}
                            >
                              {savingRows[promo.id] ? (
                                <CircularProgress size={16} />
                              ) : (
                                <Icon.Check height={16} width={16} />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton
                              size="small"
                              onClick={() => handleCancel(promo.id)}
                              disabled={savingRows[promo.id] || isRefetching}
                            >
                              <Icon.X height={16} width={16} />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            onClick={() => handleEdit(promo)}
                            disabled={isRefetching}
                          >
                            {isRefetching ? (
                              <CircularProgress size={16} />
                            ) : (
                              <Icon.Edit2 height={16} width={16} />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredAndSortedData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default PromoOptions; 