import React, {useEffect, useState} from 'react';
import {AlertMessage, ButtonGroup, Icon, Select,} from '@vacasa/react-components-lib';
import {UiUtils} from "../../../utils";
import './DemandInfluenceApprovalForm.scss';
import {DemandInfluence, Message} from "../../../types";
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';

// Pages that load this component will control these properties
interface DemandInfluenceApprovalFormProps {
    closeModal: (refresh?: boolean) => void;
    handleApprove: (demandInfluence: DemandInfluence, approvalStatus: string, approvalRejectedReason: string) => Promise<void>;
    currentDI?: DemandInfluence;
    viewOnly?: boolean;
}

export const DemandInfluenceApprovalForm: React.FC<DemandInfluenceApprovalFormProps> = (props) => {
    const {
        closeModal,
        handleApprove,
        currentDI,
        viewOnly
    } = props;

    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    const [dApprovalStatus, setApprovalStatus] = useState<string>(currentDI.approval);
    const [dApprovalRejectedReason, setApprovalRejectedReason] = useState<string>(currentDI.approval_rejected_reason || "");

    const handleSave = async () => {
        setIsSaveDisabled(true);
        setIsSaving(true)

        try {
            await handleApprove(currentDI, dApprovalStatus, dApprovalRejectedReason)
            if (dApprovalStatus === 'approved') {
                setApprovalRejectedReason('')
            }
            setTimeout(() => {
                closeModal(false);
            }, 1000)
        } catch (error) {
            setUiAlert(UiUtils.getErrorMessage(error.data, error.status));
            setIsSaveDisabled(true); // Save was enabled originally but we got an error. Re-enable is less infuriating than leaving disabled
        }
    };

    let instructions = "Please select the new status of the demand influence. In case of rejection, please add the reason why it was rejected."

    const cancelButton: ButtonProps = {
        onClick: () => {closeModal()},
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                // ? <><span>Saving</span><Icon.Loader className={"spinning-icon"} height={24} width={24}/></>
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: isSaveDisabled || !!viewOnly,
        customClass: 'button-group'
    }

    useEffect(() => {
        if (dApprovalStatus === "rejected" && dApprovalRejectedReason === "") {
            setUiAlert({type: 'warning', content: 'Please provide a reason for the rejection'})
            setIsSaveDisabled(true)
        } else {
            setIsSaveDisabled(false)
            setUiAlert(null)
        }
    }, [dApprovalStatus, dApprovalRejectedReason])


    return (
        <React.Fragment>
            <div className="form-header">
                <h5>Approval Status</h5>
            </div>
            <div className={'instructions'}>{instructions}</div>
            <div className='apr-form-body'>
                <form>
                    <div className="apr-form-row">
                        <div className="apr-input-group">
                            <label className="approval-status-label">Type:</label>
                            <Select
                                customClass="number-input"
                                value={dApprovalStatus}
                                options={[
                                    {display: "Approved", value: "approved"},
                                    {display: "Rejected", value: "rejected"},
                                ]}
                                onChange={(e) => setApprovalStatus(e.target.value)}
                                disabled={!!viewOnly}
                            />
                        </div>
                    </div>
                    <div className="apr-form-row">
                        <div className="apr-input-group">
                            <label className="approval-status-label">Reason:</label>
                            <textarea className="approval-rejected-reason"
                                rows={3}
                                maxLength={255}
                                value={dApprovalRejectedReason}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setApprovalRejectedReason(e.target.value)}
                                disabled={(dApprovalStatus !== 'rejected') || !!viewOnly}
                            />
                        </div>
                    </div>
                    <div className={'alert-container'}>
                        {uiAlert ? <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type}
                                                 height="small"/> : null}
                    </div>
                </form>
            </div>
            <div className="form-footer">
                <div className={'form-footer-buttons'}>
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </React.Fragment>
    );
};