import React, {useEffect, useState} from 'react';
import './DemandInfluenceWizard.scss';
import {
    Cohort,
    // CurrentUser,
    DemandDetail,
    DemandInfluence,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    SelectOption,
    StrategicCohort,
} from "../../../types";
import {DemandDetailForm} from "./DemandDetailForm";
import {DemandInfluenceForm} from "./DemandInfluenceForm";
import {SelectableCohortList} from "../../Common/SelectableCohortList/SelectableCohortList";
import {useAddDemandInfluenceMutation, useUpdateDemandInfluenceMutation} from "../../../store";
import {eachDayOfInterval} from "date-fns";

enum WizardSteps {
    SELECT_COHORTS = 1,
    SELECT_PROPERTIES = 2,
    SELECT_DETAILS = 3,
}

// Pages that load this component will control these properties
interface DemandInfluenceWizardProps {
    modalType: DemandInfluenceFormType;
    cohorts: Cohort[];
    isFetchingCohorts: boolean;
    selectedDI?: DemandInfluence;
    selectedCohortID?: string;
    // currentUser: CurrentUser;
    closeModal: (boolean?) => void;
}

export const DemandInfluenceWizard: React.FC<DemandInfluenceWizardProps> = (props) => {
    const {
        modalType,  // todo rename to something like Mode?
        cohorts,
        isFetchingCohorts,
        selectedDI,
        // currentUser,
        closeModal,
    } = props;
    const editing = modalType === DemandInfluenceFormOptions.EDIT
    // const adding = modalType === DemandInfluenceFormOptions.ADD
    // const copying = modalType === DemandInfluenceFormOptions.COPY

    const [selectedCohorts, setSelectedCohorts] = useState<StrategicCohort[]>(
        selectedDI?.child_cohorts ? selectedDI.child_cohorts : []
    )
    const [updatedDemandInfluence, setUpdatedDemandInfluence] = useState<DemandInfluence>()

    // Details Form
    const [syncPriceBuckets, setSyncPriceBuckets] = useState<boolean>(false);
    const [syncBookingWindows, setSyncBookingWindows] = useState<boolean>(false);
    const [selectedMetric, setSelectedMetric] = useState<string>("starting");
    const [selectedDetailCohort, setSelectedDetailCohort] = useState<number>(null);
    const [selectedDetailDate, setSelectedDetailDate] = useState<string>("");
    const [cohortDetailOptions, setCohortDetailOptions] = useState<SelectOption[]>([]);
    const [dateDetailOptions, setDateDetailOptions] = useState<SelectOption[]>([]);
    const [rangesSwitch, setRangesSwitch] = useState<boolean>(false);
    const [previewSwitch, setPreviewSwitch] = useState<boolean>(false);

    const [addDemandInfluence] = useAddDemandInfluenceMutation();
    const [updateDemandInfluence] = useUpdateDemandInfluenceMutation();

    const [step, setStep] = useState(1);

    const nextStep = () => setStep(prevStep => Math.min(prevStep + 1, WizardSteps.SELECT_DETAILS));
    const previousStep = () => setStep(prevStep => Math.max(prevStep - 1, WizardSteps.SELECT_COHORTS));

    const handleCohortsSelected = (selectedCohortIDs: number[]) => {
        let selectedCohorts: StrategicCohort[] = [];
        cohorts.forEach((cohort) => {
            if (selectedCohortIDs.indexOf(cohort.id) >= 0) selectedCohorts.push({
                strategic_cohort_id: cohort.id,
                strategic_cohort: cohort.name,
                manager: cohort.manager,
                analyst: cohort.analyst
            });
        })
        setSelectedCohorts(selectedCohorts)
        nextStep()
    }

    const handleDemandInfluenceInfo = async (demandInfluence: DemandInfluence, navigation: number) => {
        demandInfluence.child_cohorts = selectedCohorts

        if (!!selectedDI) {
            demandInfluence.details = selectedDI.details;

            if (editing) demandInfluence.id = selectedDI.id;
        }

        setUpdatedDemandInfluence(demandInfluence)

        const start = new Date(demandInfluence.demand_type === "adjustment" ? demandInfluence.start_stay_date : demandInfluence.start_booking_date);
        const end = new Date(demandInfluence.end_stay_date);

        const input = start > end ? {start: end, end: start}:  {start: start, end: end};
        const dates = eachDayOfInterval(input);
        const dateOptions = dates.map(o => {
            return {
                display: o.toISOString().split("T")[0],
                value: o.toISOString().split("T")[0]
            }
        })
        setDateDetailOptions(dateOptions);

        const cohortOptions: SelectOption[] = demandInfluence.child_cohorts.map(c => {
            return {display: c.strategic_cohort, value: c.strategic_cohort_id}
        })
        setCohortDetailOptions(cohortOptions);

        if (navigation === 1) nextStep();
        else previousStep();
    }

    const saveAll = async (demandInfluence: DemandInfluence, demandDetails: DemandDetail[], navigation: number) => {
        demandInfluence.details = demandDetails;
        setUpdatedDemandInfluence(demandInfluence)

        if (navigation === 1) {
            if (editing) {
                await updateDemandInfluence({
                    id: selectedDI.id,
                    data: demandInfluence
                }).unwrap().then(() => closeModal(true));
            } else {
                await addDemandInfluence(demandInfluence).unwrap().then(() => closeModal(true));
            }
        }
        else previousStep()
    }

    useEffect(() => {
        if (!isFetchingCohorts) {
            if(selectedDI) setUpdatedDemandInfluence(selectedDI)
        }
    }, [isFetchingCohorts]);

    return (
        <React.Fragment>
            {step === WizardSteps.SELECT_COHORTS &&
                <SelectableCohortList
                    cohortData={cohorts}
                    isFetchingCohorts={isFetchingCohorts}
                    diCohortIDs={selectedCohorts.map(c => c.strategic_cohort_id)}
                    closeModal={closeModal}
                    handleNext={handleCohortsSelected}
                />
            }
            {step === WizardSteps.SELECT_PROPERTIES &&
                <DemandInfluenceForm
                type={modalType}
                currentDI={updatedDemandInfluence}
                closeModal={closeModal}
                handleNavigation={handleDemandInfluenceInfo}
            />
            }
            {step === WizardSteps.SELECT_DETAILS &&
                <DemandDetailForm
                    type={modalType}
                    currentDI={updatedDemandInfluence}
                    selectedCohortIDs={selectedCohorts.map(c => c.strategic_cohort_id)}
                    syncBookingWindows={syncBookingWindows}
                    syncPriceBuckets={syncPriceBuckets}
                    selectedMetric={selectedMetric}
                    rangesSwitch={rangesSwitch}
                    previewSwitch={previewSwitch}
                    cohortOptions={cohortDetailOptions}
                    dateOptions={dateDetailOptions}
                    selectedDate={selectedDetailDate}
                    selectedCohort={selectedDetailCohort}
                    setSyncBookingWindows={setSyncBookingWindows}
                    setSyncPriceBuckets={setSyncPriceBuckets}
                    setSelectedMetric={setSelectedMetric}
                    setRangesSwitch={setRangesSwitch}
                    setPreviewSwitch={setPreviewSwitch}
                    setSelectedCohort={setSelectedDetailCohort}
                    setCohortOptions={setCohortDetailOptions}
                    setSelectedDate={setSelectedDetailDate}
                    setDateOptions={setDateDetailOptions}
                    closeModal={closeModal}
                    handleNavigation={saveAll}
                />
            }
        </React.Fragment>
    );
};