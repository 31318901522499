import React, {useEffect, useRef, useState} from 'react';
import {isEmpty, isNil} from 'lodash';
import {AlertMessage, Button, DatePicker, Input, Select,} from '@vacasa/react-components-lib';
import {DATE_FORMAT, UiUtils} from "../../../utils";
import './DemandInfluenceForm.scss';
import {addDays, format} from "date-fns";
import {
    DemandInfluence,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    Message,
} from "../../../types";
import {Loading} from "../../Common/Loading/Loading";
import {Checkbox, Switch} from '@material-ui/core';

// Pages that load this component will control these properties
interface DemandInfluenceFormProps {
    type: DemandInfluenceFormType;
    currentDI?: DemandInfluence;
    closeModal: (boolean?) => void;
    handleNavigation: (di: Partial<DemandInfluence>, nav: number) => void;
}

export const DemandInfluenceForm: React.FC<DemandInfluenceFormProps> = (props) => {
    const {
        type,
        currentDI,
        closeModal,
        handleNavigation,
    } = props;
    const [initializing, setInitializing] = useState<boolean>(true);
    const editing = type === DemandInfluenceFormOptions.EDIT
    const copying = type === DemandInfluenceFormOptions.COPY


    const today = new Date(new Date().toISOString().split("T")[0])

    const [dTitle, setTitle] = useState<string>();
    const [dDemandType, setDemandType] = useState<string>(!!currentDI ? "" : "adjustment");
    const [dStartBookingDate, setStartBookingDate] = useState<string>(
        !isNil(currentDI?.start_booking_date) ? currentDI?.start_booking_date : format(today, DATE_FORMAT)
    );
    const [dEndBookingDate, setEndBookingDate] = useState<string>(
        !isNil(currentDI?.end_booking_date) ? currentDI?.end_booking_date : format(addDays(today, 1), DATE_FORMAT)
    );
    const [dStartStayDate, setStartStayDate] = useState<string>(
        !isNil(currentDI?.start_stay_date) ? currentDI?.start_stay_date : format(addDays(today, 1), DATE_FORMAT)
    );
    const [dEndStayDate, setEndStayDate] = useState<string>(
        !isNil(currentDI?.end_stay_date) ? currentDI?.end_stay_date : format(addDays(today, 1), DATE_FORMAT)
    );
    const [dActive, setActive] = useState<boolean>(true);
    const [dPriority, setPriority] = useState<number>(1000);
    const [dDecay, setDecay] = useState<string>("linear");
    const [dDescription, setDescription] = useState<string>("");
    const [levelCategory, setLevelCategory] = useState<string>("")

    // levelCategory and priority can change independently but should update each other
    const previousValues = useRef({dPriority, levelCategory});

    // DOW: Track the state of All to know if we checked/unchecked dAllDays
    const [dAllDaysState, setAllDaysState] = useState<boolean>(true);
    const [dAllDays, setAllDays] = useState<boolean>(true);
    const [dSunday, setSunday] = useState<boolean>(true);
    const [dMonday, setMonday] = useState<boolean>(true);
    const [dTuesday, setTuesday] = useState<boolean>(true);
    const [dWednesday, setWednesday] = useState<boolean>(true);
    const [dThursday, setThursday] = useState<boolean>(true);
    const [dFriday, setFriday] = useState<boolean>(true);
    const [dSaturday, setSaturday] = useState<boolean>(true);

    const dowCheckBoxes = [dSunday, dMonday, dTuesday, dWednesday, dThursday, dFriday, dSaturday]
    const setDowCheckBoxes = [setSunday, setMonday, setTuesday, setWednesday, setThursday, setFriday, setSaturday]
    const dayCheckBoxLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    const handleStartBookingDateChange = (date: Date | null, value?: string | null) => {
        setStartBookingDate(value ?? '')
    };
    const handleEndBookingDateChange = (date: Date | null, value?: string | null) => {
        setEndBookingDate(value ?? '')
    };
    const handleStartStayDateChange = (date: Date | null, value?: string | null) => {
        setStartStayDate(value ?? '')
    };
    const handleEndDateChange = (date: Date | null, value?: string | null) => {
        setEndStayDate(value ?? '')
    };

    const handleStartBookingDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setStartBookingDate(dStartBookingDate)
    };
    const handleEndBookingDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setEndBookingDate(dEndBookingDate)
    };
    const handleStartStayDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setStartStayDate(dStartStayDate)
    };
    const handleEndStayDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setEndStayDate(dEndStayDate)
    };

    const handleNavigation_ = async (nav: number) => {
        setIsNextDisabled(true);

        let daysOfWeek: number[] = [];
        dowCheckBoxes.forEach((value, idx) => {
            if (!!value) daysOfWeek.push(idx)
        })

        let newDemandInfluence: Partial<DemandInfluence> = {...currentDI}

        newDemandInfluence.title = dTitle;
        newDemandInfluence.demand_type = dDemandType;
        newDemandInfluence.start_booking_date = dStartBookingDate;
        newDemandInfluence.end_booking_date = dEndBookingDate;
        newDemandInfluence.start_stay_date = dStartStayDate;
        newDemandInfluence.end_stay_date = dEndStayDate;
        newDemandInfluence.priority = dPriority;
        newDemandInfluence.decay = dDecay;
        newDemandInfluence.description = dDescription;
        newDemandInfluence.active = dActive;
        newDemandInfluence.days_of_week = daysOfWeek;

        handleNavigation(newDemandInfluence, nav);
    };

    let instructions = "Please fill out the form -- "
    if (editing) instructions += "Saving is disabled until a change is made"
    else instructions += "Dates must be sequential"

    // Watchers
    useEffect(() => {
        if (!!currentDI) {
            setAllDaysState(false);
            setAllDays(false);
            setDowCheckBoxes.forEach((v, i) => setDowCheckBoxes[i](false))

            setTitle(currentDI.title);
            setDemandType(currentDI.demand_type);
            setPriority(currentDI.priority);
            setDecay(currentDI.decay);
            setDescription(currentDI.description);
            setStartBookingDate(currentDI.start_booking_date);
            setEndBookingDate(currentDI.end_booking_date);
            setStartStayDate(currentDI.start_stay_date);
            setEndStayDate(currentDI.end_stay_date);
            setActive(currentDI.active);

            currentDI.days_of_week.forEach((val, idx) => setDowCheckBoxes[val](true))
            setInitializing(false);
        } else {
            setInitializing(false);
        }
    }, [currentDI])

    useEffect(() => {
        // WEEKDAY CHECK BOXES //
        // "All" days checked or unchecked
        if (dAllDays !== dAllDaysState) {
            setDowCheckBoxes.forEach((v, i) => setDowCheckBoxes[i](dAllDays));
            setAllDaysState(dAllDays);
        }
        // Check "All" if all days are true
        else if (dowCheckBoxes.every(v => v === true)) {
            setAllDaysState(true);
            setAllDays(true); // set after state to avoid infinite loop
        }
        // Uncheck "All" since it was not clicked and not all days are true
        else {
            setAllDaysState(false);
            setAllDays(false);
        }

        // GENERIC VALIDATIONS //
        // Disable save if all days are false
        if (dowCheckBoxes.every(v => v === false)) {
            setUiAlert({type: 'warning', content: 'Please ensure at least one day of the week is checked'});
            return setIsNextDisabled(true);
        }

        // Disable if invalid title
        if (isNil(dTitle) || isEmpty(dTitle)) {
            setUiAlert({type: 'warning', content: 'Set a valid title'});
            return setIsNextDisabled(true);
        }
        // Disable for invalid priority
        if (isNil(dPriority) || dPriority > 25000) {
            setUiAlert({type: 'warning', content: 'Set a level between 1 and 25000'});
            return setIsNextDisabled(true);
        }
        // Disable for out-of-order dates
        if (!UiUtils.isValidDateRange(dStartBookingDate, dEndBookingDate) ||
            !UiUtils.isValidDateRange(dStartStayDate, dEndStayDate)
        ) {
            setUiAlert({type: 'warning', content: 'Dates must be sequential'});
            return setIsNextDisabled(true);
        }

        setIsNextDisabled(false)

        // warn if dates are over N=45 days
        let affectedDateCount = dDemandType === "adjustment"
            ?
            UiUtils.dateDiffDays(dEndStayDate, dStartStayDate)
            :
            UiUtils.dateDiffDays(dEndBookingDate, dStartBookingDate)

        // Divide by days selected (Caution! not an actual count)
        affectedDateCount = Math.floor(affectedDateCount * (Number(dSunday) + Number(dMonday) +Number(dTuesday) + Number(dWednesday) + Number(dThursday) + Number(dFriday) + Number(dSaturday)) / 7);

        if (affectedDateCount > 45) {
            return setUiAlert({type: 'warning', content: `Influences over 45 days will require approval (${affectedDateCount} dates influenced)`})
        }

        return setUiAlert(UiUtils.getPendingChangesMessage());
    }, [
        type,
        dTitle,
        dStartBookingDate,
        dEndBookingDate,
        dStartStayDate,
        dEndStayDate,
        dPriority,
        dDecay,
        currentDI,
        dActive,
        dDescription,
        dAllDays,
        dSunday,
        dMonday,
        dTuesday,
        dWednesday,
        dThursday,
        dFriday,
        dSaturday,
    ]);

    useEffect(() => {
        if (previousValues.current.levelCategory !== levelCategory) {
            for (const category of UiUtils.levelCategories) {
                if (levelCategory === category.category) {
                    setPriority(category.threshold)
                    previousValues.current.dPriority = category.threshold;
                    previousValues.current.levelCategory = category.category;
                    return;
                }
            }
        } else if (previousValues.current.dPriority !== dPriority) { // priority changed
            for (const category of UiUtils.levelCategories) {
                if (dPriority >= category.threshold) {
                    setLevelCategory(category.category);
                    previousValues.current.levelCategory = category.category;
                    previousValues.current.dPriority = category.threshold;
                    return;
                }
            }
        }
    }, [dPriority, levelCategory]);

    // Update dates to help maintain sequential order
    useEffect(() => {
        if (!!dStartBookingDate) {
            let startBooking = new Date(dStartBookingDate)
            let endBooking = new Date(dEndBookingDate)
            if (startBooking > endBooking) { // Not sure why, but 2 days is actually 1
                let newEndBooking = format(addDays(startBooking, 2), DATE_FORMAT)
                setEndBookingDate(newEndBooking)
            }
        }
    }, [dStartBookingDate])

    // DemandType === "event" overloads dates. Tie endBooking to startStay
    useEffect(() => {
        if (dDemandType === "event" && !!dEndBookingDate) {
            setStartStayDate(dEndBookingDate)
        }
    }, [dEndBookingDate])

    useEffect(() => {
        if (!!dStartStayDate) {
            let startStay = new Date(dStartStayDate)
            let endStay = new Date(dEndStayDate)
            if (startStay > endStay) { // Not sure why, but 1 day is actually same day
                let newEndStay = format(addDays(startStay, 1), DATE_FORMAT)
                setEndStayDate(newEndStay)
            }
        }
    }, [dStartStayDate])

    // If users toggle InfluenceType and then change dates it could lead to unexpected dates.
    // Vice-versa: Setting dates then changing influence types could make for confusion on which date is target or end-booking/start-stay
    useEffect(() => {
        if (!!dDemandType) {
            if (dDemandType === "event") {
                setStartStayDate(dEndBookingDate)
            } else { // "adjustment
                // let endBooking = new Date(dEndBookingDate)
                // let startStay = new Date(dStartStayDate)
            }
        }
    }, [dDemandType])

    return (
        <React.Fragment>
            {/* todo move to top-level? */}
            <div className="form-header">
                <h5>{editing ? 'Edit' : copying ? 'Copy' : 'Add'} Demand Influence</h5>
            </div>
            <div className={'alert-container'}>
                {uiAlert ? <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type}
                                         height="small"/> :
                    <div className={'instructions'}>{instructions}</div>}
            </div>
            <div className='wiz-form-body'>
                {initializing ? <Loading className="demand-influence-form-loading"/> :
                    <form>
                        <div className="form-row">
                            <div className='form-col col-1'>
                                <div className="input-group">
                                    <label>Title:</label>
                                    <Input
                                        customClass="inputs"
                                        type="text"
                                        value={dTitle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                                    />
                                </div>
                                <div className="input-group">
                                    <div className="inline-checkboxes">
                                        <div className="weekday">
                                            <label>All</label>
                                            <Checkbox
                                                checked={dAllDays}
                                                onChange={() => setAllDays(!dAllDays)}
                                                className="dow-checkbox"
                                                disabled={editing}
                                            />
                                        </div>
                                        {dowCheckBoxes.map((v, i) => {
                                            return <div className="weekday">
                                                <label>{dayCheckBoxLabels[i]}</label>
                                                <Checkbox
                                                    checked={dowCheckBoxes[i]}
                                                    onChange={() => setDowCheckBoxes[i](!dowCheckBoxes[i])}
                                                    className="dow-checkbox"
                                                    disabled={editing}
                                                />
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="form-row">

                                    <div className="form-col col-2">
                                        <div className="input-group">
                                            <label>Category:</label>
                                            <Select
                                                customClass="number-input"
                                                value={levelCategory}
                                                options={UiUtils.getCategoryOptions()}
                                                onChange={(e) => setLevelCategory(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-col col-2">
                                        <div className="input-group">
                                            <label>Level:</label>
                                            <Input
                                                customClass="number-input"
                                                type="number"
                                                value={dPriority}
                                                max={25000}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPriority(e.target.valueAsNumber)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-col col-2'>
                                <div className="input-group">
                                    <label>Type:</label>
                                    <Select
                                        customClass="number-input"
                                        value={dDemandType}
                                        options={[{display: "Event", value: "event"}, {
                                            display: "Adjustment",
                                            value: "adjustment"
                                        }]}
                                        onChange={(e) => setDemandType(e.target.value)}
                                        disabled={editing}
                                    />
                                </div>

                                <div className="input-group">
                                    <label>Slope:</label>
                                    <Select
                                        customClass="number-input"
                                        value={dDecay}
                                        options={UiUtils.getDecayOptions()}
                                        onChange={(e) => setDecay(e.target.value)}
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Active</label>
                                    <Switch
                                        key={'active-' + !!dActive ? 1 : 2}
                                        checked={!!dActive}
                                        onClick={() => setActive(!dActive)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className="date-selector">
                                <p>{`Start ${dDemandType === "event" ? "" : "Booking "}Date:`}</p>
                                <DatePicker
                                    variant="inline"
                                    value={dStartBookingDate}
                                    onChange={handleStartBookingDateChange}
                                    disableToolbar={true}
                                    inputValue={dStartBookingDate}
                                    onBlur={handleStartBookingDateLostFocus}
                                    customClass="date-picker"
                                    disabled={editing}
                                />
                            </div>
                            <div className="date-selector">
                                <p>{`${dDemandType === "event" ? "Target" : "End Booking"} Date:`}</p>
                                <DatePicker
                                    variant="inline"
                                    value={dEndBookingDate}
                                    onChange={handleEndBookingDateChange}
                                    disableToolbar={true}
                                    inputValue={dEndBookingDate}
                                    onBlur={handleEndBookingDateLostFocus}
                                    customClass="date-picker"
                                    disabled={editing}
                                />
                            </div>
                            <div className='date-selector' hidden={dDemandType === "event"}>
                                <p>Start Stay Date:</p>
                                <DatePicker
                                    variant="inline"
                                    value={dStartStayDate}
                                    onChange={handleStartStayDateChange}
                                    disableToolbar={true}
                                    inputValue={dStartStayDate}
                                    onBlur={handleStartStayDateLostFocus}
                                    customClass="date-picker"
                                    disabled={editing}
                                />
                            </div>
                            <div className='date-selector'>
                                <p>{`End ${dDemandType === "event" ? "" : "Stay "} Date:`}</p>
                                <DatePicker
                                    variant="inline"
                                    value={dEndStayDate}
                                    onChange={handleEndDateChange}
                                    disableToolbar={true}
                                    inputValue={dEndStayDate}
                                    onBlur={handleEndStayDateLostFocus}
                                    customClass="date-picker"
                                    disabled={editing}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <label>Description:</label>
                        </div>
                        <div className="row">
                            <textarea
                                rows={3}
                                maxLength={255}
                                value={dDescription}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
                            />
                        </div>
                    </form>
                }
            </div>

            <div className="form-footer">
                <div className={'form-footer-buttons'}>
                    <Button variant="info" onClick={closeModal} customClass='button-group'>
                        Cancel
                    </Button>
                    <Button variant="secondary" onClick={() => handleNavigation_(-1)}>Previous</Button>
                    <Button variant="secondary" onClick={() => handleNavigation_(1)} disabled={isNextDisabled}>
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};