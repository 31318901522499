import {DemandDetail, MatrixInfo, MatrixRequest} from "../types";
import {apiDemandDetail} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";


const demandDetailAdapter = createEntityAdapter<DemandDetail>({
    selectId: (demandDetail) => demandDetail.index,
    sortComparer: (a, b) => a.index - b.index
});

const initialState: EntityState<DemandDetail> = demandDetailAdapter.getInitialState();

export const demandDetailApi = apiDemandDetail.injectEndpoints({
    endpoints: (builder) => ({
        getDemandForecast: builder.query({
            query: (id) => `demand_forecast/${id}`,
            providesTags: [{type: "DemandDetails", id: "demandDetailsList"}]
        }),
        getMatrix: builder.query<MatrixInfo[], MatrixRequest>({
            query: (body) => ({
                url: "matrix",
                method: "POST",
                body: JSON.stringify(body)
            }),
        }),
    }),
    overrideExisting: false,
});

export const demandDetailSlice = createSlice({
    name: "demandDetails",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<DemandDetail[]>) => {
            demandDetailAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            demandDetailApi.endpoints.getDemandForecast.matchFulfilled, (state, {payload}) => {
            demandDetailAdapter.upsertMany(state, payload);
        })
    }
});

export const {
    useGetMatrixQuery,
} = demandDetailApi;

export default demandDetailSlice.reducer;