import './SelectableCohortList.scss';

import React, {ReactElement, useEffect, useState} from "react";

import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {Cohort, Message} from "../../../types";
import {ButtonGroup, Icon} from '@vacasa/react-components-lib';
import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {Loading} from "../Loading/Loading";
import {VirtualizedTable} from "../VirtualizedTable/VirtualizedTable";
import {UiUtils} from "../../../utils";

interface SelectableCohortListProps {
    cohortData: Cohort[];
    isFetchingCohorts: boolean;
    diCohortIDs: number[];
    closeModal: (boolean?) => void;
    handleNext: (selectedCohortIDs: number[]) => void;
}

type SelectableCohortListComponent = (props: SelectableCohortListProps) => ReactElement<any, any> | null;
export const SelectableCohortList: SelectableCohortListComponent = (props) => {
    const {
        cohortData,
        isFetchingCohorts,
        diCohortIDs,
        closeModal,
        handleNext,
    } = props;

    const [selectedCohorts, setSelectedCohorts] = useState<number[]>(diCohortIDs);
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);

    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    useEffect(() => {
        if (uiAlert) {
            setTimeout(() => {
                setUiAlert(null);
            }, 10000)
        }
    }, [uiAlert])

    const onSelectedChange = (selectedCohortIDs: number[]) => {
        setSelectedCohorts(selectedCohortIDs)
    }

    const cancelButton: ButtonProps = {
        onClick: closeModal,
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const nextButton: ButtonProps = {
        onClick: () => {
            handleNext(selectedCohorts)
        },
        children: "Next",
        variant: "secondary",
        disabled: isNextDisabled,
        customClass: 'button-group'
    }

    useEffect(() => {
        if (selectedCohorts.length > 0) {
            setIsNextDisabled(false)
        } else {
            setIsNextDisabled(true)
        }
    }, [
        selectedCohorts
    ]);

    // todo why is this necessary? I only need to set it up once
    // I guess selectedCohorts are somehow set before the cohortID is passed
    useEffect(() => {
        setSelectedCohorts(diCohortIDs)
    }, [
        diCohortIDs
    ]);

    const builder = new DataSourceBuilder<Cohort>();

    builder.addColumn({
        label: 'Name',
        field: 'name',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 2
        },
    });
    builder.addColumn({
        label: 'Units',
        field: 'unit_count',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.4
        }
    });

    builder.addColumn({
        label: 'State',
        field: 'state',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.5
        }
    });
    builder.addColumn({label: 'Region', field: 'region', displayConfiguration: {justifyContent: 'right'},});
    builder.addColumn({label: 'Manager', field: 'manager', displayConfiguration: {justifyContent: 'right'}});
    builder.addColumn({label: 'Analyst', field: 'analyst', displayConfiguration: {justifyContent: 'right'}});

    builder.setSortable({field: 'name', order: 'asc'});
    builder.setFilterHeader({
        options: [
            { field: "active", values: [true], type: "select"},
            { field: "unit_count", type: "range", options: "int"},
            { field: "state", values:  UiUtils.getStateOptions(cohortData), type: "select"},
            { field: "region", values:  UiUtils.getRegionOptions(cohortData), type: "select"},
            { field: "manager", values:  UiUtils.getManagerOptions(cohortData), type: "select"},
            { field: "analyst", values:  UiUtils.getAnalystOptions(cohortData), type: "select"},
        ],
        initialFilters: [
            { field: "active", value: "true", type: "boolean"}
        ]
    });

    return (
        <React.Fragment>
            <div className="form-header">
                <h5>Select Cohorts</h5>
            </div>
            <div className="di-form-body">
                <div>
                    {isFetchingCohorts
                        ? <Loading className="cohort-list-loading"/>
                        : <VirtualizedTable
                            className="selectable-cohort-list-table"
                            dataSource={builder.build(cohortData)}
                            onRowChange={() => null}
                            onSelectedChange={onSelectedChange}
                            initialSelectedRows={selectedCohorts}
                            sortSelected={true}
                            headerOptions={{height: 60}}
                        />
                    }
                </div>
            </div>
            <div>
                Selected {selectedCohorts.length} cohorts
            </div>
            <div className="form-footer">
                <div className={'form-footer-buttons'}>
                    <ButtonGroup left={cancelButton} right={nextButton}/>
                </div>
            </div>
        </React.Fragment>
    );
};