import {apiTables} from "./api";
import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "./store";
import {ComparisonDate, Holiday, HolidayEvent, UnitTetherAnchor} from "../types";


const tablesAdapter = createEntityAdapter<UnitTetherAnchor>({
    selectId: (rule) => rule.id,
    sortComparer: (a, b) => a.id - b.id
});

const initialState: EntityState<UnitTetherAnchor> = tablesAdapter.getInitialState();

export const tablesApi = apiTables.injectEndpoints({
    endpoints: (builder) => ({
        getDefaultComparisonDates: builder.query<Partial<ComparisonDate>[], void>({
            query: () => "default_comparison_dates"
        }),
        getUnitTetherTable: builder.query<UnitTetherAnchor[], void>({
            query: () => "unit_tethers"
        }),
        geHolidaysTable: builder.query<Holiday[], void>({
            query: () => "holidays"
        }),
        addUnitTether: builder.mutation<void, Partial<UnitTetherAnchor>>({
            query: (body) => ({
                url: "unit_tethers",
                method: "POST",
                body: JSON.stringify(body)
            })
        }),
        updateUnitTether: builder.mutation<void, { id: number; data: Partial<UnitTetherAnchor> }>({
            query: ({id, data}) => ({
                url: `unit_tethers/${id}`,
                method: "PUT",
                body: JSON.stringify(data),
            })
        }),
        addHoliday: builder.mutation<void, Partial<Holiday>>({
            query: (body) => ({
                url: "holidays",
                method: "POST",
                body: JSON.stringify(body)
            })
        }),
        updateHoliday: builder.mutation<void, { holiday_id: number; data: Partial<Holiday> }>({
            query: ({holiday_id, data}) => ({
                url: `holidays/${holiday_id}`,
                method: "PUT",
                body: JSON.stringify(data),
            })
        }),
        addHolidayEvent: builder.mutation<void, {holiday_id: number, data: Partial<HolidayEvent> }>({
            query: ({holiday_id, data}) => ({
                url: `holiday_events/${holiday_id}`,
                method: "POST",
                body: JSON.stringify(data)
            })
        }),
        updateHolidayEvent: builder.mutation<void, { event_id: number; data: Partial<HolidayEvent> }>({
            query: ({event_id, data}) => ({
                url: `holiday_events/${event_id}`,
                method: "PUT",
                body: JSON.stringify(data),
            })
        }),
        convertAddressToCoordinates: builder.mutation<void, {address: string }>({
            query: (data) => ({
                url: `address_to_lat_lng`,
                method: "POST",
                body: JSON.stringify(data)
            })
        }),
    }),
    overrideExisting: false,
});

export const tablesSlice = createSlice({
    name: "Tables",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<UnitTetherAnchor[]>) => {
            tablesAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        // builder.addMatcher(
        //     tablesApi.endpoints.getTable.matchFulfilled, (state, {payload}) => {
        //         tablesAdapter.upsertMany(state, payload);
        //     })
    }
});

export const {
    useGetUnitTetherTableQuery,
    useGeHolidaysTableQuery,
    useAddUnitTetherMutation,
    useUpdateUnitTetherMutation,
    useGetDefaultComparisonDatesQuery,
    useAddHolidayMutation,
    useUpdateHolidayMutation,
    useAddHolidayEventMutation,
    useUpdateHolidayEventMutation,
    useConvertAddressToCoordinatesMutation,
} = tablesApi;
export const tablesSelector = (state: AppState) => state.tables;

export default tablesSlice.reducer;