import React, {useEffect, useState} from 'react';
import {Loading} from '../index';
import './CohortAssignmentModal.scss';
import {Cohort, SelectOption} from "../../types";
import {useUpdateRAAssignmentMutation, useGetAnalystsQuery, useGetManagersQuery} from "../../store";
import {ButtonGroup, Icon, Select} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {RAEmployee} from "../../types/RAEmployee";


interface CohortAssignmentModalProps {
    closeModal: (refresh?: boolean) => void;
    selectedCohort: Cohort;
    onUpdateCohort: (cohort: Cohort) => void;
    viewOnly?: boolean;
}

export const CohortAssignmentModal: React.FC<CohortAssignmentModalProps> = (props) => {
    const {selectedCohort, closeModal, onUpdateCohort, viewOnly} = props
    const {data: analysts, isFetching: isFetchingAnalysts} = useGetAnalystsQuery();
    const {data: managers, isFetching: isFetchingManagers} = useGetManagersQuery();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    const [modelId, setModelId] = useState<string>(selectedCohort.model_id?.toString());
    const modelIdOptions: SelectOption[] = [
        {value: "", display: "(unset)"},
        {value: "4000", display: "Northstar"},
        {value: "5000", display: "Alan"}
    ];
    const [selectedAnalyst, setSelectedAnalyst] = useState<string>(selectedCohort.analyst || "");
    const [analystOptions, setAnalystOptions] = useState<SelectOption[]>([]);
    const [selectedManager, setSelectedManager] = useState<string>(selectedCohort.manager || "");
    const [managerOptions, setManagerOptions] = useState<SelectOption[]>([]);
    const [updateAssignment] = useUpdateRAAssignmentMutation();

    const handleSave = async () => {
        setIsSaving(true);
        await updateAssignment(
            {
                cohort_id: selectedCohort.id,
                analyst_name: selectedAnalyst,
                manager_name: selectedManager,
                // model_id: modelId,
            }
        ).then(() => {
            selectedCohort.analyst = selectedAnalyst
            selectedCohort.manager = selectedManager
            selectedCohort.model_id = !!modelId ? parseInt(modelId) : null
            onUpdateCohort(selectedCohort)
            closeModal(true);
        }).finally(() => {
            setIsSaving(false);
        })

    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: isSaveDisabled || !!viewOnly,
        customClass: 'button-group'
    }

    useEffect(() => {
        // set analysts and managers in the selectors when loading finished
        if (!isFetchingAnalysts && !!analysts && !isFetchingManagers && !!managers) {
            let analystOptions: SelectOption[] = [];
            analysts.forEach((analyst: RAEmployee) => {
                analystOptions.push({
                    value: analyst.name,
                    display: analyst.name
                });
            })
            analystOptions.sort((a, b) => a.display.localeCompare(b.display));
            setAnalystOptions(analystOptions);

            let managerOptions: SelectOption[] = [];
            managers.forEach((manager: RAEmployee) => {
                managerOptions.push({
                    value: manager.name,
                    display: manager.name
                });
            })
            managerOptions.sort((a, b) => a.display.localeCompare(b.display));
            setManagerOptions(managerOptions);

            setIsLoading(false);
        }
    }, [analysts, isFetchingAnalysts, managers, isFetchingManagers])

    useEffect(() => {
        // allow save on change
        if (
            selectedAnalyst !== selectedCohort.analyst ||
            selectedManager !== selectedCohort.manager ||
            modelId !== selectedCohort.model_id?.toString()
        ) {
            setIsSaveDisabled(false)
        } else {
            setIsSaveDisabled(true);
        }
    }, [selectedAnalyst, selectedManager, selectedCohort, modelId]);

    return (
        <div>
            <div className="form-header">
                <h5>Cohort Assignments</h5>
            </div>
            <div className="admin-list form-body">
                {isLoading
                    ? <Loading className="admin-list-loading"/>
                    :
                    <div>
                        <br/>
                        <div className="ua-input-group">
                            <label htmlFor="analyst">Analyst:</label>
                            <Select
                                customClass="number-input"
                                value={selectedAnalyst}
                                options={analystOptions}
                                onChange={(e) => {
                                    setSelectedAnalyst(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="manager">Manager:</label>
                            <Select
                                customClass="number-input"
                                value={selectedManager}
                                options={managerOptions}
                                onChange={(e) => {
                                    setSelectedManager(e.target.value)
                                }}
                                disabled={isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="ua-input-group">
                            <label htmlFor="model">Model (WiP):</label>
                            <Select
                                customClass="number-input"
                                value={modelId}
                                options={modelIdOptions}
                                onChange={(e) => {
                                    setModelId(e.target.value)
                                }}
                                disabled={true} // isSaving || !!viewOnly}
                            />
                        </div>
                        <div className="row cohort-info">
                            <tr>
                                <td className="unit-key">ID:</td>
                                <td className="unit-value">{selectedCohort.id}</td>
                            </tr>
                            <tr>
                                <td className="unit-key">Units:</td>
                                <td className="unit-value">{selectedCohort.unit_count}</td>
                            </tr>
                            <tr>
                                <td className="unit-key">Active:</td>
                                <td className="unit-value">{selectedCohort.active ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td className="unit-key">State:</td>
                                <td className="unit-value">{selectedCohort.state}</td>
                            </tr>
                            <tr>
                                <td className="unit-key">Region:</td>
                                <td className="unit-value">{selectedCohort.region}</td>
                            </tr>
                        </div>
                        <br/>
                    </div>
                }
            </div>
            <div className="form-footer">
                <div className={'form-footer-buttons'}>
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </div>
    );
};
