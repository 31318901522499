import React, { useState } from 'react';

export interface InfluenceProps {
    ibw: number;
    ipb: number;
    influenceType: string;
    disabled: boolean;
    originalValue: number;
    children?: React.ReactNode;
}

export const Influence = React.forwardRef<HTMLInputElement, InfluenceProps>((props, ref) => {
    const { ibw, ipb, influenceType, originalValue, disabled} = props;
    const [value, setValue] = useState(originalValue);

    let key = `${influenceType}_bw_${ibw + 2}_${ipb + 1}`;
    return (
        <div className={"x" + (ibw + 2).toString() + "_" + (ipb + 2).toString()}>
            <input
                className="influence-input"
                type="number"
                id={key}
                key={key}
                value={value}
                onChange={(event) => setValue(event.target.valueAsNumber)}
                ref={ref}
                disabled={disabled}
            />%
        </div>
    );
});
