import {apiPromotions} from "./api";
import {AppState} from "./store";
import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";

export interface PromotionOptions {
  strategies: PromotionOption[],
  types: PromotionOption[],
  usage_types: PromotionOption[],
}

export interface PromotionOption {
  id: number;
  name: string;

  active?: boolean;

  vacasa?: boolean;
  vrbo?: boolean;
  airbnb?: boolean;
  bdc?: boolean;
  mybookingpal?: boolean;
  expedia?: boolean;

  option_type?: string;
}

export interface CreatePromotionOptionRequest {
  name: string;
  active?: boolean;

  vacasa?: boolean;
  vrbo?: boolean;
  airbnb?: boolean;
  bdc?: boolean;
  mybookingpal?: boolean;
  expedia?: boolean;

  option_type?: string;
}

export interface UpdatePromotionOptionRequest extends CreatePromotionOptionRequest {
  id: number;
}

const promotionAdapter = createEntityAdapter<any>({
  selectId: (promotion) => promotion.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState: EntityState<any> = promotionAdapter.getInitialState();

export const promotionsApi = apiPromotions.injectEndpoints({
  endpoints: (builder) => ({
    // FIXME: "any"
    getAllPromotions: builder.query<any, void>({
      query: () => ({
        url: 'promotions',
        method: 'GET',
      }),
      providesTags: ['Promotions'],
    }),
    getPromotionOptions: builder.query<PromotionOptions, void>({
      query: () => ({
        url: 'promotion_options',
        method: 'GET',
      }),
      // providesTags: ['PromotionOptions'],
    }),

    createPromotionOption: builder.mutation<PromotionOption, CreatePromotionOptionRequest>({
      query: (body) => ({
        url: 'promotion_options',
        method: 'POST',
        body: JSON.stringify(body),
      }),
      // invalidatesTags: ['PromotionOptions'],
    }),

    updatePromotionOption: builder.mutation<PromotionOption, UpdatePromotionOptionRequest>({
      query: (body) => ({
        url: `promotion_options/${body.id}`,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      // invalidatesTags: ['PromotionOptions'],
    }),
  }),
});

export const promotionSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // FIXME: "any"
    merge: (state, action: PayloadAction<any[]>) => {
      promotionAdapter.upsertMany(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    // we'll match on the async action or the manual increment being that both have a payload of type `number`
    builder.addMatcher(
      promotionsApi.endpoints.getAllPromotions.matchFulfilled, (state, {payload}) => {
        promotionAdapter.upsertMany(state, payload);
      }
    )
  }
});

export const {
  useGetAllPromotionsQuery,
  useGetPromotionOptionsQuery,
  useCreatePromotionOptionMutation,
  useUpdatePromotionOptionMutation,
} = promotionsApi;

export const promotionSelector = (state: AppState) => state.promotions;

export default promotionSlice.reducer;