import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './Tables.scss';
import {TableRowProps} from "react-virtualized";
import {UnitTetherChild} from "../../types";


interface UnitTetherAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}

export const UnitTetherAccordion: React.FC<UnitTetherAccordionProps> = (props) => {

    const {tableProps, expanded, handleChange} = props;
    const [unitTethers, setUnitTethers] = useState<UnitTetherChild[]>();

    const styles = {
        ...tableProps.style
    };

    useEffect(() => {
        const extraDetailsFromData: UnitTetherChild[] = tableProps.rowData.tethers;
        setUnitTethers(extraDetailsFromData);
    }, [tableProps.rowData.tethers]);

    return (
        <div key={tableProps.rowData.id} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.id} expanded={expanded === tableProps.rowData.id}
                          onChange={handleChange(tableProps.rowData.id, tableProps.index)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                    {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th className={"id-col"}>Unit ID</th>
                            <th className={"value-col"}>Offset</th>
                            <th className={"note-col"}>Note</th>
                            <th className={"email-col"}>Created</th>
                            <th className={"email-col"}>Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!unitTethers ? unitTethers.map(ut => (
                                <tr className={"table-body"}>
                                    <td>{ut.unit_id}</td>
                                    <td>
                                        {ut.tether_value >= 0 ? "+" : "-"}
                                        {ut.tether_value_type?.toUpperCase() === "DOLLAR" && "$"}
                                        {Math.abs(ut.tether_value)}
                                        {ut.tether_value_type?.toUpperCase() === "PERCENT" && "%"}
                                    </td>
                                    <td>{ut.note}</td>
                                    <td>{ut.created_at.split(" ")[0]}</td>
                                    <td>{ut.creator_email}</td>
                                </tr>
                            ))

                            :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
