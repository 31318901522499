import { useState, useEffect, useCallback } from 'react';

type Task = { method: (...args) => Promise<any>; params: any[] };

export const useTaskQueue = (props: {
    shouldProcess: boolean;
}): {
    tasks: ReadonlyArray<Task>;
    isProcessing: boolean;
    addTask: (task: Task) => void;
} => {
    const [queue, setQueue] = useState<{ isProcessing: boolean; tasks: Array<Task> }>({ isProcessing: false, tasks: [] });
    const { shouldProcess } = props;

    useEffect(() => {
        if (!shouldProcess || queue.tasks.length === 0 || queue.isProcessing) {
            return;
        }

        const [task] = queue.tasks;

        setQueue((prev) => ({
            isProcessing: true,
            tasks: prev.tasks.slice(1),
        }));

        Promise.resolve(task.method(...task.params)).finally(() => {
            setQueue((prev) => ({
                isProcessing: false,
                tasks: prev.tasks,
            }));
        });
    }, [queue, shouldProcess]);

    return {
        tasks: queue.tasks,
        isProcessing: queue.isProcessing,
        addTask: useCallback((task) => {
            setQueue((prev) => ({
                isProcessing: prev.isProcessing,
                tasks: [...prev.tasks, task],
            }));
        }, []),
    };
};
