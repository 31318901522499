import React from 'react';
import { Icon, withTooltip } from '@vacasa/react-components-lib';
import './UnitNavigation.scss';


type UnitNavigationProps = {
    unitID: number;
    onClose: () => void;
};

export const UnitNavigation: React.FC<UnitNavigationProps> = (props) => {
    const {unitID, onClose} = props;
    return (
        <div>
            <div style={{float: "right", padding: "3px"}}>
                <Icon.X className="pointer" height={18} width={18} onClick={onClose} />
            </div>
            <div className="row" style={{padding: "10px"}}>
                <strong>Unit:</strong>&nbsp;{unitID}
            </div>
            <div className="row">
                {withTooltip(
                    "See unit in Admin",
                    <div>
                        <a href={`https://admin.vacasa.com/admin/dashboard/units/listing?UnitID=${unitID.toString()}`} target="_blank">
                            <Icon.ExternalLink height={20} width={20}/>
                            Admin
                        </a>
                    </div>,
                    {placement: "top"}
                )}
            </div>
            <div className="row">
                {withTooltip(
                    "See unit listing on vacasa.com",
                    <div>
                        <a href={`https://vacasa.com/unit/${unitID.toString()}`}
                            target="_blank">
                            <Icon.ExternalLink height={20} width={20}/>
                            Vacasa
                        </a>
                    </div>,
                    {placement: "top"}
                )}
            </div>
            <div className="row">
                {withTooltip(
                    "See unit in Oz",
                    <div>
                        <a href={`https://rates-app.yield.vacasa.services/units/${unitID.toString()}#dash`}
                            target="_blank">
                            <Icon.ExternalLink height={20} width={20}/>
                            Oz
                        </a>
                    </div>,
                    {placement: "top"}
                )}
            </div>
        </div>
    );
};
