export class Configuration {
    public static isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';
    public static isLocal = process.env.REACT_APP_CUSTOM_NODE_ENV === 'local';
    public static DEFAULT_DAYS_RANGE = 540;
    static getIDPConfiguration = () => {
        const {
            REACT_APP_IDP_CLIENT_ID,
            REACT_APP_NORTHSTAR_AUDIENCE,
            REACT_APP_IDP_REDIRECT_URI,
            REACT_APP_CUSTOM_NODE_ENV
        } = process.env;

        const envs = {
            production: 'prod',
            staging: 'stage',
            development: 'stage',
            local: 'stage',
        };

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            scopes: 'northstar:read northstar:write',
            audience: REACT_APP_NORTHSTAR_AUDIENCE,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            directoryHint: Configuration.isProduction ? 'onelogin' : 'email',
            env: envs[REACT_APP_CUSTOM_NODE_ENV],
            useAuthorizationContext: false,
        };
    };
}
