import React, { useEffect, useState } from 'react';
import { Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, fromUnixTime } from 'date-fns';
import * as _ from 'lodash';
import './RatesEvolutionChart.scss';
import { Checkbox } from '@material-ui/core';
import { Rate } from '../../types';
import {Icon} from '@vacasa/react-components-lib';

interface RatesEvolutionProps {
    rates: Rate[];
}

interface modelLineProps {
    [model: string]: {
        color: string;
        hidden: boolean;
        strokeWidth: number;
        opacity: number;
    };
}

export const RatesEvolutionChart: React.FC<RatesEvolutionProps> = (props) => {
    const { rates} = props;
    // const firstElement = _.head(rates);

    // HighStock colors. Sort keys alphabetically; We'll have a semi-consistent colors without maintaining models/keys
    const colors = ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"];

    const strokeWidth = {};
    // const strokeWidth = firstElement?.strokeWidth ?? {};

    const opacity = {};
    // const opacity = firstElement?.opacity ?? {};

    const [modelNames, setModelNames] = useState<string[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [modelLineState, setModelLineState] = useState<modelLineProps>({});

    const generateRandomHexColor = () => `#${(0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)}`;

    useEffect(() => {
        if (rates.length > 0) {
            setModelNames(Object.keys(rates[0]).filter(r => r !== "date"))
        }
    }, [rates]);

    useEffect(() => {
        if (!!modelLineState) {
            const e = Object.keys(modelLineState).every(key => modelLineState[key].hidden === false);
            setCheckAll(e);
        }
    }, [modelLineState]);




    const NoDataAlert: React.FC = () => {
        return (
            <div className="no-data-alert">
                <Icon.AlertTriangle width={24} height={24} className="no-data-alert-icon"/>
                <span>No Data to display</span>
            </div>
        );
    }

    useEffect(() => {
        // if (!_.isEmpty(options?.models.models)) {
        //     setInitialOptions();
        // }
        const modelLineState = _.reduce(
            modelNames,
            (acc, curr) => {
                return {
                    ...acc,
                    [curr]: {
                        color: colors[modelNames.indexOf(curr)] || generateRandomHexColor(),
                        strokeWidth: strokeWidth[curr] || 2,
                        hidden: isHidden(curr),
                        opacity: opacity[curr] ?? 0.7,
                    },
                };
            },
            {} as { modelLineProps }
        );
        setModelLineState(modelLineState);
    }, [JSON.stringify(modelNames)]);

    const setInitialOptions = () => {
        // const newModels: string[] = [];
        // const modelIds = _.keys(options?.models.models);
        // modelIds?.forEach((modelId) => {
        //     newModels.push(options?.models.models[modelId]);
        // });
        // options?.models.selected.map((model) => (!newModels.includes(model) ? newModels.unshift(model) : null));
        // setModelNames(_.filter(newModels, (item) => item !== null));
        setModelNames(["Northstar", "Alan", "REBL"]);
    };

    const isHidden = (modelName: string) => {
        // if (!_.isEmpty(options)) {
        //     return !options.models.selected.includes(modelName);
        // }
        return false;
    };

    const isDisabled = (modelName: string) => {
        // if (!_.isEmpty(options)) {
        //     return options.models.disabled?.includes(modelName);
        // }
        return false;
    };

    const findIdByModelName = (modelName: string): number => {
        // if (!_.isEmpty(options)) {
        //     const keys = _.keys(options.models.models);
        //     for (const key of keys) {
        //         if (options.models.models[key] === modelName) return parseInt(key);
        //     }
        // }
        return null;
    };

    if (_.isEmpty(rates)) {
        return <NoDataAlert />;
    }

    const formatDate = (unixTime: number): string => format(fromUnixTime(unixTime), 'yyyy-MM-dd');

    const syncToggle = (model: string) => {
        // if (options.preferences) {
        //     if (!modelLineState[model].hidden) {
        //         options.preferences.onApply(_.filter(options.models.selected, (m) => m !== model));
        //     } else {
        //         options.preferences.onApply([...options.models.selected, model]);
        //     }
        // }
    };

    const toggleHideModelLine = (model: string) => {
        if (isDisabled(model)) return;
        // if (!_.isEmpty(options)) {
        //     syncToggle(model);
        //     if (!options.models.synced.includes(model)) {
        //         options.models.syncData(findIdByModelName(model));
        //         return;
        //     }
        // }
        const newState = {
            ...modelLineState,
            [model]: { ...modelLineState[model], hidden: !modelLineState[model].hidden },
        };
        setModelLineState(newState);
    };

    if (_.isEmpty(modelLineState)) {
        return null;
    }

    const setCheckboxes = () => {
        let newState = {...modelLineState}
        Object.keys(modelLineState).forEach(model => {
            newState[model].hidden = checkAll
        })
        setModelLineState(newState);
    }

    return (
        <div className="rates-evolution-chart">
            <div className="rates-evolution-chart-header">
                {modelNames.map((model, i) => {
                    return (
                        <div
                            key={`chart-label-${model}-${i}`}
                            className={'model-checkbox ' + (isDisabled(model) ? 'disabled' : 'pointer')}
                            onClick={() => toggleHideModelLine(model)}>
                            <Checkbox
                                size="small"
                                className={'model-checkbox-box'}
                                checked={!modelLineState[model]?.hidden}
                                value={model}
                            />
                            <span style={{ color: modelLineState[model]?.color, textDecoration: 'underline' }}> {model}</span>
                        </div>
                    );
                })}
                <div style={{float: "right", paddingLeft: "100px"}}>
                    <Checkbox
                        size="small"
                        className={'model-checkbox-box'}
                        checked={checkAll}
                        value={checkAll}
                        onChange={setCheckboxes}
                    />
                    <span>{!checkAll ? "Check" : "Uncheck"} All</span>

                </div>
            </div>

            <div className="rates-evolution-line-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={rates}>
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                        <YAxis type="number" />
                        <XAxis dataKey="date" type="number" tickFormatter={formatDate} domain={['dataMin', 'dataMax']} />

                        <Tooltip labelFormatter={formatDate} />

                        {modelNames.map(
                            (model, i) =>
                                modelLineState[model] && (
                                    <Line
                                        key={`chart-line-${model}-${i}`}
                                        className="rates-evolution-line"
                                        type="monotone"
                                        dataKey={model}
                                        hide={modelLineState[model].hidden}
                                        stroke={modelLineState[model].color}
                                        strokeWidth={modelLineState[model].strokeWidth}
                                        opacity={modelLineState[model].opacity}
                                        dot={false}
                                    />
                                )
                        )}

                        <Brush className="rates-evolution-chart-brush" tickFormatter={(i) => formatDate(rates[i].date)} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
